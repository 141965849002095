import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { RootState } from "store";
import { useSelector } from "react-redux";
import LogoIcon from "../../assets/logo.svg";
import Button from "../ui/Button";

export default function LandingChat() {
  const locale = useSelector((state: RootState) => state.intl.locale);
  const [emailSubject, handleEmailSubject] = useState("");

  const isZH = useMemo(() => locale.includes("zh"), [locale]);

  return (
    <div className="bg-gray-bg-dark px-8 md:px-12 lg:px-24 pb-20 max-w-7xl mx-auto w-full">
      <img src={LogoIcon} className="w-12 h-12 md:w-24 md:h-24 mb-7" alt="logo-icon" />
      <div className="flex">
        <div className="hidden md:block">
          <p className="font-nexa text-5xl mb-2 leading-none font-bold">
            <FormattedMessage id="Chat With Us" />
          </p>
          <p className="font-nexa font-normal text-lg opacity-80 mb-7">
            <FormattedMessage id="Email us or join our digital community." />
          </p>
          <div className="flex w-100 h-12 bg-white rounded-full">
            <input
              className="bg-transparent h-full pr-5 font-nexa font-normal text-lg py-4 pl-6 focus:outline-none text-gray-200 w-64"
              type="text"
              value={emailSubject}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleEmailSubject(e.target.value);
              }}
              placeholder={isZH ? "这是关于什么的" : "What it's about"}
            />
            <Button
              text={<FormattedMessage id="Reach Out" />}
              borderColor="none"
              className="flex items-center text-center font-nexa font-bold text-gray-bg-dark w-40 bg-yellow-300 border-yellow-300 hover:bg-yellow-200 hover:border-yellow-200 m-auto h-full rounded-full justify-center"
              onClick={function (e?: any): void {
                if (e) {
                  e.preventDefault();
                }
                window.open(`mailto:mercury@mad.xyz?subject=${emailSubject}`);
              }}
            />
          </div>
        </div>
        <div className="flex-shrink w-0 md:w-28 lg:w-56 xl:w-144" />
        <div className="flex w-full md:flex-shrink md:justify-between">
          <div className="flex-shrink-0">
            <p className="font-nexa font-extrabold text-base md:text-2xl mb-4">
              <FormattedMessage id="Learn" />
            </p>
            <div className="mb-6">
              <a
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                href={require("../../assets/MAD_Whitepaper.pdf").default}
                target="_blank"
                rel="noreferrer"
                className="font-nexa font-normal text-sm md:text-lg"
              >
                <FormattedMessage id="White Paper" />
              </a>
            </div>
            <div className="">
              <a
                href={`https://drive.google.com/drive/folders/1aJveRbrAhnGVdI8mABEj7SZPJ134EJVi?usp=sharing`}
                target="_blank"
                rel="noreferrer"
                className="font-nexa font-normal text-sm md:text-lg"
              >
                <FormattedMessage id="Brand Assets" />
              </a>
            </div>
          </div>
          <div className="flex-shrink-0 md:ml-2 ml-11">
            <p className="font-nexa font-extrabold text-base md:text-2xl mb-4">
              <FormattedMessage id="Product" />
            </p>
            <div className="mb-6">
              <a
                href={`https://showcase.ethglobal.com/buildquest/the-radical-market-mkmdo`}
                target="_blank"
                rel="noreferrer"
                className="font-nexa font-normal text-sm md:text-lg"
              >
                <FormattedMessage id="Demo Video" />
              </a>
            </div>
            <div className="">
              <a
                href={`https://medium.com/metaverse-advertisement-dao`}
                target="_blank"
                rel="noreferrer"
                className="font-nexa font-normal text-sm md:text-lg"
              >
                <FormattedMessage id="Medium Articles" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
