import { Middleware, Action, Dispatch } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { addNotification, IAlert } from "./alertSlice";

let globalDispatch: Dispatch;

interface Notify {
  id?: string;
  duration?: number;
  type?: "error" | "success" | "warning" | "default";
  title?: string;
  message?: string;
  content?: string;
  other?: any;
}

export const addNotify = ({
  id = "new",
  duration = 6000,
  type = "success",
  title = "",
  message = "New Notification",
  content,
  other = {}
}: Notify): void => {
  if (globalDispatch) {
    globalDispatch(
      addNotification({
        id,
        duration,
        content,
        contentProps: {
          title,
          message,
          type,
          ...other
        }
      } as IAlert)
    );
  }
};

export const notificationMiddleware: Middleware<never, RootState> = (store) => (next) => (action: Action) => {
  globalDispatch = store.dispatch;

  return next(action);
};
