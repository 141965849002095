import { useWeb3React } from "@web3-react/core";
import { Contract, ethers } from "ethers";
import { getCurrentEthPriceInUsd } from "helpers/chainlink";
import { countDecimals } from "helpers/misc";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAccountBalance } from "store/userSlice";
import { addNotify } from "store/notificationMiddleware";
import { ReactComponent as DollarIcon } from "../../assets/Dollar.svg";
import _ from "lodash";

interface ProfileClaimRefundProps {
  data: { timestamp: Date; amount: string; id?: string; metaverse?: number }[] | undefined;
  handleData: (data: { timestamp: Date; amount: string; id?: string; metaverse?: number }[]) => void;
  madAuctionContract: Contract | null;
  madAuctionInstance: Contract | null;
  loading: boolean;
}

function RefundItem(props: { Item: { timestamp: Date; amount: string }; ethToUsd: number; index: number }) {
  const { timestamp, amount } = props.Item;

  return (
    <div key={`refund-${props.index}`} className="my-4">
      <div className="bg-gray-500 rounded-md sm:grid sm:grid-cols-2 px-3 py-2">
        <div className="w-full">
          <p className="font-nexa text-base leading-6 font-bold text-pink-30 mb-2">Bid Time</p>
          <p className="font-nexa text-base leading-6 font-light text-white">
            {`${timestamp.toLocaleDateString()} ${timestamp.getHours() % 12 ? timestamp.getHours() % 12 : 12}:${
              timestamp.getMinutes() < 10 ? `0${timestamp.getMinutes()}` : timestamp.getMinutes()
            } ${timestamp.getHours() >= 12 ? "PM" : "AM"}`}
          </p>
        </div>
        <div className="flex sm:justify-end w-full pr-8 mt-4 sm:mt-0">
          <div className="w-fit">
            <p className="font-nexa text-base leading-6 font-bold text-pink-30 mb-2">Available Refund</p>
            <p className="font-nexa text-base leading-6 text-white font-semibold">
              {countDecimals(parseFloat(ethers.utils.formatEther(amount))) > 3
                ? parseFloat(ethers.utils.formatEther(amount)).toFixed(3)
                : parseFloat(ethers.utils.formatEther(amount))}
              <span className="text-xs font-light">{` ($${
                props.ethToUsd ? (props.ethToUsd * parseFloat(ethers.utils.formatEther(amount))).toFixed(2) : "--"
              })`}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileClaimRefund(props: ProfileClaimRefundProps) {
  const { data, handleData, madAuctionInstance, madAuctionContract, loading } = props;

  const [ethToUsd, handleEthToUsd] = useState(0);
  const [processingTx, handleProcessingTx] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { account } = useWeb3React();

  useEffect(() => {
    (async () => {
      handleEthToUsd(await getCurrentEthPriceInUsd());
    })();
  }, []);

  const claimRefund = async () => {
    let ret = { success: false, message: "" };

    if (!data) {
      ret.message = "Unexpected error while claiming refund. Please refresh your page and try again.";
      return ret;
    }

    if (!madAuctionInstance) {
      ret.message = "There was an issue connecting to your wallet. Please refresh your page and try again.";
      return ret;
    }

    const aucIdArray: string[] = [];
    for (const auc of data) {
      if (auc.id && !_.includes(aucIdArray, auc.id)) {
        aucIdArray.push(auc.id);
      }
    }

    if (aucIdArray.length > 0) {
      await madAuctionInstance
        .batchClaim(aucIdArray)
        .then(async (tx: any) => {
          await tx.wait(1).then(() => {
            ret = { success: true, message: "Funds claimed successfully." };
            // handleData([]); // TODO: Make this work
          });
        })
        .catch((e: any) => {
          if (e.message) {
            ret = { success: false, message: e.message };
          } else if (e) {
            const idx = e.toString().indexOf('"message":');
            if (idx < 0) {
              ret = { success: false, message: e };
            } else {
              ret = { success: false, message: e.toString().substring(idx + 11, e.toString().indexOf('"', idx + 11)) };
            }
          } else {
            ret = { success: false, message: "Unexpected error occurred. Please refresh your page and try again." };
          }
        });
    } else {
      ret.message = "No outstanding funds to claim.";
    }

    return ret;
  };

  const claim = async () => {
    if (processingTx || !madAuctionContract) {
      return;
    }

    handleProcessingTx(true);
    const { success, message } = await claimRefund();
    if (!success) {
      addNotify({ message, type: "error" });
    } else {
      handleData([]);
      dispatch(setAccountBalance((await madAuctionContract.balance(account)) / 1e18));
    }
    handleProcessingTx(false);
  };

  const renderSkeleton = () => {
    return (
      <div className="flex-shrink-0 rounded-lg w-full lg:w-[480px] bg-gray-600 overflow-hidden">
        <div className="m-auto text-gray-200 flex flex-col lg:min-h-72 h-full">
          <div className="w-full h-[58px] bg-gray-500 border-b border-gray-600 px-4 flex items-center flex-none">
            <div className="flex text-gray-100 text-base font-bold items-center">
              <DollarIcon className="h-6 w-auto mr-2 fill-gray-100" />
              Claim Refund
            </div>
          </div>
          <div className="p-8 h-full flex flex-col">
            <div className="max-h-72 lg:max-h-40 overflow-y-auto dark-overflow">
              <div className="-my-4 animate-pulse">
                <div className="my-4">
                  <div className="bg-gray-500 rounded-md sm:grid sm:grid-cols-2 px-3 py-2">
                    <div className="w-full">
                      <div className="bg-white rounded mt-1 mb-4 h-4 w-24" />
                      <div className="bg-white rounded mt-4 mb-1 h-4 w-44" />
                    </div>
                    <div className="w-full">
                      <div className="bg-white rounded mt-1 mb-4 h-4 w-40" />
                      <div className="bg-white rounded mt-4 mb-1 h-4 w-28" />
                    </div>
                  </div>
                  <div className="bg-gray-500 rounded-md sm:grid sm:grid-cols-2 px-3 py-2 mt-4">
                    <div className="w-full">
                      <div className="bg-white rounded mt-1 mb-4 h-4 w-24" />
                      <div className="bg-white rounded mt-4 mb-1 h-4 w-44" />
                    </div>
                    <div className="w-full">
                      <div className="bg-white rounded mt-1 mb-4 h-4 w-40" />
                      <div className="bg-white rounded mt-4 mb-1 h-4 w-28" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col text-gray-100 mt-auto flex-shrink-0 animate-pulse">
              <div
                className={`w-full py-5 rounded bg-yellow-300 mt-6 flex justify-center items-center font-bold text-gray-300 select-none`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!account || !data || loading) {
    return renderSkeleton();
  }

  if (data && data.length === 0) {
    return (
      <div className="flex-shrink-0 rounded-lg w-full lg:w-[480px] bg-gray-600 overflow-hidden lg:h-full">
        <div className="m-auto text-gray-200 flex flex-col lg:min-h-72 h-full">
          <div className="w-full h-[58px] bg-gray-500 border-b border-gray-600 px-4 flex items-center flex-none">
            <div className="flex text-gray-100 text-base font-bold items-center">
              <DollarIcon className="h-6 w-auto mr-2 fill-gray-100" />
              Claim Refund
            </div>
          </div>
          <div className="m-auto flex flex-col font-nexa text-gray-100 text-center p-8">
            <p>
              No refund available.{" "}
              <span
                className="underline cursor-pointer text-yellow-300 hover:text-yellow-200"
                onClick={() => {
                  navigate("../auction", { replace: true });
                }}
              >
                Join an auction
              </span>{" "}
              now.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="rounded-lg w-full h-full lg:w-[480px] bg-gray-600">
        <div className="m-auto text-gray-200 flex flex-col lg:min-h-72 h-full">
          <div className="w-full h-[58px] bg-gray-500 border-b border-gray-600 px-4 flex items-center flex-none">
            <div className="flex text-gray-100 text-base font-bold items-center">
              <DollarIcon className="h-6 w-auto mr-2 fill-gray-100" />
              Claim Refund
            </div>
          </div>
          <div id="pcr2" className="p-8 h-[calc(100%-58px)] flex flex-col">
            <div className="overflow-y-auto dark-overflow flex-grow">
              <div className="-my-4">
                {data &&
                  data.map((bidItem, index) => (
                    <RefundItem key={`refund-item-${index}`} index={index} Item={bidItem} ethToUsd={ethToUsd} />
                  ))}
              </div>
            </div>
            <div className="flex flex-col text-gray-100 mt-auto flex-shrink-0">
              <div
                className={`w-full py-2 rounded ${
                  processingTx ? "bg-yellow-200" : "bg-yellow-300"
                } hover:bg-yellow-200 cursor-pointer mt-6 flex justify-center items-center font-bold text-gray-300 select-none`}
                onClick={claim}
              >
                {processingTx ? "Claiming..." : "Claim to Balance"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileClaimRefund;
