import { useEffect, useState } from "react";
import { ReactComponent as CloudIcon } from "../../../../src/assets/cloud.svg";
import { ReactComponent as BinIcon } from "../../../../src/assets/bin.svg";
import { FormattedMessage } from "react-intl";
import errorConfig from "config/errorConfig.json";
import { FileRejection, useDropzone } from "react-dropzone";
import { useSpring, config, animated } from "react-spring";
import { uploadFileAndNotes } from "helpers/files";
import { useWeb3React } from "@web3-react/core";
import ProgressBar from "components/ui/ProgressBar";

interface IFileUploadContent {
  open: boolean;
  auction: Auction;
  idx?: number;
  selectedFile?: File;
  notes: string;
  uploading: boolean;
  isLoading: boolean;
  errMsg: string;
  handleAllowClosing: (allow: boolean) => void;
  handlePropChange: (name: string, value: any) => void;
  updateWonBids?: (idx: number, data: { success: boolean; link?: string; error?: any }) => void;
  onCancel: () => void;
}

export function FileUploadContent(props: IFileUploadContent) {
  const {
    auction,
    idx,
    updateWonBids,
    onCancel,
    selectedFile,
    notes,
    handlePropChange,
    handleAllowClosing,
    uploading,
    isLoading,
    errMsg
  } = props;
  const [fileName, setFileName] = useState(selectedFile ? selectedFile.name : "");
  const { account, library } = useWeb3React();

  const slideInStyles = useSpring({
    config: { ...config.default },
    from: { height: "auto" },
    to: {
      height: uploading ? (isLoading ? 260 : 318) : "auto"
    }
  });

  useEffect(() => {
    if (selectedFile) {
      setFileName(selectedFile.name);
    } else {
      setFileName("");
    }
  }, [selectedFile]);

  const getZipFile = (files: File[]) => {
    handlePropChange("selectedFile", files[0]);
  };

  const handleZipRejected = (files: FileRejection[]) => {
    if (files[0].errors[0].code === "file-invalid-type") {
      handlePropChange("errMsg", errorConfig.FORMAT_ERROR_MESSAGE);
    } else if (files[0].errors[0].code === "file-too-large") {
      handlePropChange("errMsg", errorConfig.SIZE_ERROR_MESSAGE);
    } else {
      handlePropChange("errMsg", files[0].errors[0].message);
    }
  };

  const removeUploadedFile = () => {
    setFileName("");
    handlePropChange("selectedFile", undefined);
  };

  const submit = async () => {
    if (selectedFile && account) {
      handlePropChange("uploading", true);
      handleAllowClosing(false);
      handlePropChange("isLoading", true);
      let ret: any = undefined;
      try {
        ret = await uploadFileAndNotes(account, await library.getSigner(), auction, selectedFile, notes);
      } catch (err) {
        ret = { success: false };
      }
      handlePropChange("isLoading", false);
      handleAllowClosing(true);
      if (!ret.success) {
        handlePropChange("uploading", false);
        handlePropChange("errMsg", "Error uploading. Please try again.");
      } else {
        if (updateWonBids) {
          updateWonBids(idx ? idx : 0, ret);
        }
        handlePropChange("selectedFile", undefined);
        handlePropChange("notes", "");
        onCancel();
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "application/zip": [".zip"],
      "application/x-zip-compressed": [".zip"],
      "application/x-zip": [".zip"]
    },
    maxSize: 6291456, // 6MB
    maxFiles: 1,
    onDropAccepted: getZipFile,
    onDropRejected: handleZipRejected
  });

  return (
    <>
      <div className="transparent">
        <animated.div style={{ ...slideInStyles, overflow: "hidden" }}>
          {isLoading ? (
            selectedFile ? (
              <div className="px-14 py-10 font-nexa bg-gray-600 border rounded-2xl">
                <p className="text-gray-100 text-center text-2xl mb-8 leading-8 font-bold">Uploading File</p>
                <p className="text-gray-100 text-center text-base mb-8 leading-6 font-bold">
                  We are processing your file. Please wait for it be uploaded.
                </p>
                <ProgressBar value={100} />
              </div>
            ) : (
              <div className="px-14 py-10 font-nexa bg-gray-600 border rounded-2xl">
                <p className="text-gray-100 text-center text-2xl mb-8 leading-8 font-bold">Upload Success</p>
                <p className="text-gray-100 text-center text-base mb-8 leading-6 font-bold md:max-w-3xl">
                  Thank you for uploading your advertisement posters. MAD team will review your poster request and
                  contact you shortly through email if success.
                </p>
                <div
                  className="w-full h-10 rounded flex-shrink-0 bg-yellow-300 hover:bg-yellow-200 cursor-pointer mt-6 flex justify-center items-center font-bold text-gray-300 select-none"
                  onClick={() => {
                    handlePropChange("selectedFile", undefined);
                    handlePropChange("notes", "");
                    // handleSelectedFile(undefined);
                    // handleNotes("");
                  }}
                >
                  <FormattedMessage id="Complete" />
                </div>
              </div>
            )
          ) : (
            <div className="relative border bg-gray-bg-dark border-gray-300 rounded-2xl">
              <div className="flex flex-col justify-center w-screen md:min-h-full min-h-screen p-10 bg-gray-600 md:w-144 md:rounded-2xl md:overflow-hidden">
                <div className="flex justify-between">
                  <p className="mb-6 text-2xl font-bold leading-8 text-gray-100 font-nexa">
                    Upload Advertising Posters
                  </p>
                  <span
                    className="text-5xl leading-6 text-gray-100 cursor-pointer"
                    onClick={() => {
                      handlePropChange("selectedFile", undefined);
                      handlePropChange("notes", "");
                      // handleSelectedFile(undefined);
                      // handleNotes("");
                      if (onCancel) {
                        onCancel();
                      }
                    }}
                  >
                    &times;
                  </span>
                </div>
                <p className="mb-4 text-base font-light leading-6 text-gray-100 font-nexa">
                  {" "}
                  Please upload poster files.
                </p>
                <div
                  {...getRootProps()}
                  className={`w-full h-56 border-gray-20 border-dashed border rounded-md flex flex-col items-center p-8 mb-6${
                    isDragActive ? " bg-gray-10" : ""
                  }`}
                >
                  <input {...getInputProps()} />
                  <CloudIcon className="mb-4" />
                  <p className="mb-4 text-base leading-6 text-gray-100 font-nexa w-fit">
                    Drag &amp; drop zipped files or <span className="text-gray-20">Browse</span>
                  </p>
                  <p className="text-sm leading-4 text-yellow-400 font-nexa w-fit">
                    1000px * 800px, up to 3 images or 1 video file in 1 zip file
                  </p>
                  {errMsg && <p className="text-sm leading-4 text-red-200 font-nexa w-fit mt-2">{errMsg}</p>}
                </div>
                {fileName && !isLoading && (
                  <>
                    <p className="mb-2 text-base font-bold text-gray-500 font-commuter">Selected File</p>
                    <div>
                      <div className="bg-gray-600 rounded-md border-[0.5px] border-green-200 flex justify-between items-center pr-4 pl-2 h-9  mb-4">
                        <p className="font-normal leading-4 text-gray-100 text-12">{fileName}</p>
                        <div
                          className="flex items-center justify-center w-4 h-4 bg-gray-100 rounded-full cursor-pointer"
                          onClick={removeUploadedFile}
                        >
                          <BinIcon className="w-2 h-2" />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <p className="mb-4 text-base leading-4 text-gray-100 font-nexa font-extralight">Notes:</p>
                <p className="mb-4 text-sm leading-4 text-gray-100 font-nexa font-extralight">
                  Please specify the requirement for the poster, such as the links for the poster. Leave it blank if
                  there&apos;s none:
                </p>
                <textarea
                  className="w-full h-16 flex-shrink-0 p-1 bg-gray-600 border rounded-md text-gray-20 border-gray-20"
                  value={notes}
                  onChange={(e: any) => handlePropChange("notes", e.target.value)} // handleNotes(e.target.value)}
                />
                <div
                  className="w-full h-10 rounded flex-shrink-0 bg-yellow-300 hover:bg-yellow-200 cursor-pointer mt-6 flex justify-center items-center font-bold text-gray-300 select-none"
                  onClick={() => {
                    submit();
                  }}
                >
                  <FormattedMessage id="Submit and Upload" />
                </div>
              </div>
            </div>
          )}
        </animated.div>
      </div>
    </>
  );
}
