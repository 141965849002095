import { useWeb3React } from "@web3-react/core";
import { MAD_CONTRACT_ADMIN_ADDRESS } from "components/constants/constants";
import { stringEqualsIgnoreCase } from "helpers/misc";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOpenLoginModal } from "store/userSlice";
import { Contract } from "ethers";
import AdminCreateAuction from "./AdminCreateAuction";
import AdminAuctionDetails from "./AdminAuctionDetails";
import { useEffect, memo } from "react";
import { setAuctionData } from "store/auctionSlice";
import { getActiveAuctions, getPreviousAuctions } from "helpers/graphql";
import AdminClaim from "./AdminClaim";
import AdminAuctionDetailSearch from "./AdminAuctionDetailSearch";
import AdminCancel from "./AdminCancel";

interface AdminPageProps {
  madAuctionInstance: Contract | null;
}

function AdminPage(props: AdminPageProps) {
  const { madAuctionInstance } = props;

  const { account } = useWeb3React();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      // Load current auction data
      let aucData: Map<number, Auction> | undefined = await getActiveAuctions(parseInt((Date.now() / 1000).toFixed()));
      if (!aucData?.get(0) || !aucData?.get(1)) {
        const tmpAucData = aucData ? aucData : new Map<number, Auction>();
        const prevAucData = await getPreviousAuctions(parseInt((Date.now() / 1000).toFixed()));
        if (!tmpAucData?.get(0) && prevAucData?.get(0)) {
          // @ts-ignore
          tmpAucData.set(0, prevAucData.get(0));
        }
        if (!tmpAucData?.get(1) && prevAucData?.get(1)) {
          // @ts-ignore
          tmpAucData.set(1, prevAucData.get(1));
        }
        aucData = tmpAucData;
      }
      dispatch(setAuctionData(aucData ? aucData : null));
    })();
  }, [dispatch]);

  if (!stringEqualsIgnoreCase(account, MAD_CONTRACT_ADMIN_ADDRESS)) {
    return (
      <div className="m-auto text-gray-100">
        {account ? (
          <p>
            You don&apos;t have access to this page.{" "}
            <span
              className="text-yellow-300 hover:text-yellow-200 cursor-pointer"
              onClick={() => {
                navigate("/", { replace: true });
              }}
            >
              Return home.
            </span>
          </p>
        ) : (
          <p>
            Please{" "}
            <span
              className="text-yellow-300 hover:text-yellow-200 cursor-pointer"
              onClick={() => {
                dispatch(setOpenLoginModal(true));
              }}
            >
              connect your wallet
            </span>{" "}
            to continue.
          </p>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-col bg-gray-bg-dark pt-10 pb-10">
        <div className="w-full flex flex-col bg-gray-bg-dark pt-4 px-3 md:pt-8 md:px-10">
          <div className="w-full mx-auto flex flex-col max-w-7xl">
            <div className="w-full flex-col gap-4 md:gap-6 md:flex-row mt-8 flex">
              <AdminAuctionDetails metaverse={0} />
              <AdminAuctionDetails metaverse={1} />
            </div>
            <div className="w-full flex-col gap-4 md:gap-6 md:flex-row mt-8 flex">
              <AdminAuctionDetailSearch />
              <AdminCreateAuction madAuctionInstance={madAuctionInstance} />
            </div>
            <div className="w-full flex-col gap-4 md:gap-6 md:flex-row mt-8 flex">
              <AdminClaim madAuctionInstance={madAuctionInstance} />
              <AdminCancel madAuctionInstance={madAuctionInstance} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(AdminPage);
