import { FormattedMessage } from "react-intl";
import ComingSoonBg from "assets/coming-soon.png";

const ComingSoon = () => {
  return (
    <>
      <div className="flex-grow flex flex-col relative text-gray-600">
        <div className="flex-grow w-full max-w-7xl mx-auto flex relative text-white">
          <div className="flex flex-col w-full">
            <div className="flex w-full h-full">
              <img className="fixed overflow-auto bottom-0 left-0 contain" src={ComingSoonBg} alt="Coming Soon..." />
              <div className="my-auto mx-auto">
                <div className="text-center font-tabs text-white text-5xl lg:text-6xl">
                  <span className="text-pink-50">
                    <FormattedMessage id="Coming soon" />
                  </span>{" "}
                  <FormattedMessage id="Stay tuned." />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
