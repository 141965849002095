import { downloadZipFromUrl } from "helpers/files";
import { ReactComponent as CloseIcon } from "../../../assets/clear-x.svg";

interface AdDetailsContentProps {
  onCancel: () => void;
  data: BidEvent;
}

function AdDetailsContent(props: AdDetailsContentProps) {
  const { onCancel, data } = props;
  const renderMainContent = () => {
    const auction = data.auction;
    return (
      <div className="px-6 py-6 sm:py-10 sm:px-14 bg-gray-600 rounded-2xl md:w-144">
        <div className="flex flex-col text-gray-100 font-nexa">
          <div className="flex flex-row w-full justify-between mb-6">
            <div className="font-semibold text-xl my-auto">Ads Details</div>
            <CloseIcon
              className="my-auto stroke-gray-100 hover:stroke-black cursor-pointer"
              onClick={() => {
                if (onCancel) {
                  onCancel();
                }
              }}
            />
          </div>
          <div className="flex flex-col xs:flex-row w-full justify-between mb-2">
            <div className="font-semibold">Metaverse World:</div>
            <div>{auction ? (auction.metaverse ? "CryptoVoxels" : "Decentraland") : "--"}</div>
          </div>
          <div className="flex flex-col xs:flex-row w-full justify-between mb-2">
            <div className="font-semibold">Lands:</div>
            <div>{auction?.uri?.landCount ? auction.uri.landCount : "--"}</div>
          </div>
          <div className="flex flex-col xs:flex-row w-full justify-between mb-2">
            <div className="font-semibold my-auto">Status:</div>
            <div
              className={`${
                auction
                  ? Date.now() < auction.deadline * 1000 + 604800000
                    ? "bg-green-100"
                    : Date.now() < auction.deadline * 1000 + 1814400000
                    ? "bg-yellow-400"
                    : "bg-red-100"
                  : "bg-gray-200"
              } rounded-md text-gray-600 px-2 py-1 mr-auto xs:mr-0`}
            >
              {auction
                ? Date.now() < auction.deadline * 1000 + 604800000
                  ? "Upcoming"
                  : Date.now() < auction.deadline * 1000 + 1814400000
                  ? "Ongoing"
                  : "Expired"
                : "--"}
            </div>
          </div>
          <div className="flex flex-col xs:flex-row w-full justify-between mb-2">
            <div className="font-semibold">Bid Date</div>
            <div>{new Date(data.timestamp * 1000).toLocaleDateString()}</div>
          </div>
          <div className="flex flex-col xs:flex-row w-full justify-between mb-2">
            <div className="font-semibold">Ads Timeframe</div>
            <div>
              {auction ? new Date(auction.deadline * 1000 + 604800000).toLocaleDateString() : "--"} -{" "}
              <br className="hidden xs:block" />
              {auction ? new Date(auction.deadline * 1000 + 1814400000).toLocaleDateString() : "--"}
            </div>
          </div>
          <div className="flex flex-col w-full mb-2">
            <div className="font-semibold xs:mb-1">Uploaded Posters:</div>
            <div
              className={`mr-auto font-semibold ${
                auction?.uploaded ? "text-yellow-400 cursor-pointer" : "text-gray-100"
              }`}
              onClick={() => {
                if (!auction?.uploaded || !auction?.mediaLink) {
                  return;
                }
                downloadZipFromUrl(auction.mediaLink);
              }}
            >
              {auction?.uploaded ? "1 file attached" : "--"}
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="font-semibold xs:mb-1">Notes:</div>
            <div>{auction?.notes ? auction.notes : "--"}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="transparent">
        <div className="relative border bg-gray-bg-dark border-gray-300 rounded-2xl">{renderMainContent()}</div>
      </div>
    </>
  );
}

export default AdDetailsContent;
