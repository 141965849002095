import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ReactComponent as DescriptionIcon } from "../../assets/description.svg";
import MADColorLogo from "../../assets/logo_sketched.gif";
import AdminDetailInfo from "./AdminDetailInfo";

interface AdminAuctionDetailsProps {
  metaverse: number;
}

function AdminAuctionDetails(props: AdminAuctionDetailsProps) {
  const { metaverse } = props;

  const { auctionData } = useSelector((state: RootState) => state.auction);

  const renderError = () => {
    return (
      <div className="w-full flex flex-col bg-gray-600 border border-gray-500 mt-4 md:mt-0 rounded-lg overflow-hidden">
        <div className="w-full bg-gray-500 border-b border-gray-600 flex items-center h-[58px] px-4 flex-none">
          <div className="h-full flex w-full items-center text-base font-bold text-white">
            <DescriptionIcon className="h-6 mr-2" />
            {"Latest Auction - " + (metaverse ? "CryptoVoxels" : "Decentraland")}
          </div>
        </div>
        <div className="w-full h-full flex">
          <div className="w-full p-4 my-auto">
            <div className="flex flex-col m-auto">
              <img src={MADColorLogo} className="h-20 w-20 mx-auto" />
              <div className="text-white font-nexa text-sm text-center mt-2 px-10">
                Error loading the active auction&apos;s details. Refresh your page or create an auction.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSkeleton = () => {
    return (
      <div className="w-full flex flex-col bg-gray-600 border border-gray-500 mt-4 md:mt-0 rounded-lg overflow-hidden">
        <div className="w-full bg-gray-500 border-b border-gray-600 flex items-center h-[58px] px-4 flex-none">
          <div className="h-full flex w-full items-center text-base font-bold text-white">
            <DescriptionIcon className="h-6 mr-2" />
            {"Latest Auction - " + (metaverse ? "CryptoVoxels" : "Decentraland")}
          </div>
        </div>
        <div className="animate-pulse w-full flex-1">
          <div className="w-full h-96 bg-gray-200"></div>
        </div>
      </div>
    );
  };

  const aucData = useMemo(() => {
    if (!auctionData) {
      return null;
    }
    return auctionData.get(metaverse);
  }, [auctionData]);

  if (auctionData === null || aucData === undefined) {
    return renderError();
  }

  if (!auctionData) {
    return renderSkeleton();
  }

  return (
    <div className="flex-1 max-h-[300px] md:max-h-[420px] md:min-w-[calc(50%-.75rem)] flex flex-col rounded-lg overflow-hidden border border-gray-500">
      <div className="w-full h-[58px] bg-gray-500 border-b border-gray-600 px-4 flex items-center flex-none">
        <div className="flex text-white text-base font-bold items-center">
          <DescriptionIcon className="h-6 mr-2" />
          {"Latest Auction - " + (metaverse ? "CryptoVoxels" : "Decentraland")}
        </div>
      </div>
      <div className="flex-1 bg-gray-600 p-4 font-nexa text-white w-full flex flex-col styled-overflow overflow-auto">
        <AdminDetailInfo label="ID:" value={aucData?.id} margin="" hasBorder />
        <AdminDetailInfo
          label="Birth:"
          value={aucData ? new Date(aucData?.birth * 1000).toLocaleString() : "--"}
          margin="mt-1"
          hasBorder
        />
        <AdminDetailInfo
          label="Deadline:"
          value={aucData ? new Date(aucData?.deadline * 1000).toLocaleString() : "--"}
          margin="mt-1"
          hasBorder
        />
        <AdminDetailInfo label="Highest Bidder:" value={aucData?.highestBidder} margin="mt-1" hasBorder />
        <AdminDetailInfo
          label="Highest Bid:"
          value={aucData ? (aucData.highestBid / 1e18).toFixed(3) + " ETH" : "-- ETH"}
          margin="mt-1"
          hasBorder
        />
        <AdminDetailInfo
          label="Increment:"
          value={aucData ? (aucData.increment / 1e18).toFixed(3) + " ETH" : "-- ETH"}
          margin="mt-1"
          hasBorder
        />
        <AdminDetailInfo label="URI:" value={aucData ? aucData.uri : "--"} margin="mt-1" />
      </div>
    </div>
  );
}

export default memo(AdminAuctionDetails);
