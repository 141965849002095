import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PageNotFound from "../assets/page_not_found.gif";

export default function PageNotFoundPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [shouldDisplay, handleShouldDisplay] = useState(false);

  useEffect(() => {
    const listOfValidPathnames =
      "/home /create /contracts /history /verify /sign /signin /login /upload /manage-profile /analytics"; // /datavis /datavis/login
    if (!listOfValidPathnames.includes(location.pathname)) {
      handleShouldDisplay(true);
    } else {
      handleShouldDisplay(false);
    }
  }, [location.pathname]);

  return (
    <>
      {shouldDisplay && (
        <div className="fixed overflow-hidden inset-0 z-1100 bg-gray-bg-dark">
          <div className="select-none h-screen flex  flex-col justify-center items-center">
            <img src={PageNotFound} alt="" onDragStart={(e) => e.preventDefault()} />
            <div className="flex flex-col items-center justify-center text-center">
              <span className="sm:text-30 font-bold text-white">
                <FormattedMessage id="notFoundPage" />
              </span>
              <span className="sm:text-25 text-white">
                <FormattedMessage id="urlInvalid" />{" "}
                <button className="outline-none underline text-white" onClick={() => navigate(-1)}>
                  <FormattedMessage id="returnToPrevPage" />
                </button>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
