import React, { useMemo } from "react";
import { motion } from "framer-motion/dist/framer-motion";

type LandingDaoItemProps = {
  logo: any;
  title: string;
  text: any;
  link?: string;
  className?: string;
};

const LandingDaoItem = (props: LandingDaoItemProps) => {
  const { logo, title, text, link, className } = props;

  const DaoItemClasses = useMemo(
    () =>
      `transform ${className} bg-white rounded-2xl p-6 shadow-lg hover:shadow-daobanner hover:border-yellow-400 border-2 max-w-100 mx-auto w-full lg:w-100`,
    [className]
  );

  return (
    <a href={link} className={DaoItemClasses}>
      <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
        <img src={logo} alt="dao-item" className="h-9 h-28 mx-auto" />
        <div className="text-gray-600">
          <p className=" text-center text-xl md:text-4xl font-bold font-nexa w-full">{title}</p>
          <p className="text-center text-12 md:text-base font-lato font-normal">{text}</p>
        </div>
      </motion.div>
    </a>
  );
};

export default LandingDaoItem;
