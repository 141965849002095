import { useEffect, useState } from "react";
import { ReactComponent as OfferIcon } from "../../assets/offer.svg";
import { useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ChartOptions
} from "chart.js";
import "chartjs-adapter-luxon";
import { Line } from "react-chartjs-2";
import MADColorLogo from "../../assets/logo_sketched.gif";
import { FormattedMessage } from "react-intl";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(244, 230, 245, 0.37)";
    tooltipEl.style.borderRadius = "18px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.width = "fit-content";
    tooltipEl.style.height = "fit-content";
    tooltipEl.style.padding = "6px";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context: { chart: any; tooltip: any }) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b: { lines: any }) => b.lines);
    const tableHead = document.createElement("thead");

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body: string) => {
      const span = document.createElement("span");
      span.style.background = "#6E416C";
      span.style.borderWidth = "0px";
      span.style.borderRadius = "99px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";

      const td = document.createElement("td");
      td.style.fontSize = "14px";

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left =
    tooltip.caretX + tooltipEl.offsetWidth / 2 > tooltip.chart.width
      ? positionX + tooltip.caretX - tooltipEl.offsetWidth / 2 + "px"
      : positionX + tooltip.caretX + tooltipEl.offsetWidth / 2 + "px";

  tooltipEl.style.top = positionY + tooltip.caretY - tooltipEl.offsetHeight - 10 + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

const options: ChartOptions<"line"> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top"
    },
    title: {
      display: true
    },
    tooltip: {
      enabled: false,
      external: externalTooltipHandler
    }
  },
  scales: {
    x: {
      type: "time",
      time: {
        unit: "day"
      },
      title: {
        display: true,
        text: "Date"
      },
      grid: {
        display: false
      }
    }
  }
};

const renderHistorySkeleton = () => {
  return (
    <div className="w-full flex flex-col bg-gray-600 border border-gray-500 mt-4 md:mt-8 rounded-lg overflow-hidden">
      <div className="w-full bg-gray-500 border-b border-gray-600 flex items-center h-[58px] px-4 flex-none">
        <div className="h-full flex w-full items-center text-base font-bold text-white">
          <OfferIcon className="h-6 mr-2" />
          <FormattedMessage id="Price History" />
        </div>
      </div>
      <div className="animate-pulse w-full flex-1">
        <div className="w-full h-96 bg-gray-200">{/* Responsive History Chat */}</div>
      </div>
    </div>
  );
};

const AuctionPriceHistory = () => {
  const [mvChartMap, handleMvChartMap] = useState<Map<number, { x: string; y: number }[]>>();
  const [chartData, handleChartData] = useState({} as any);

  const { auctionPriceHistory, metaverse } = useSelector((state: RootState) => state.auction);
  useEffect(() => {
    if (!auctionPriceHistory) {
      return;
    }

    const map = new Map<number, { x: string; y: number }[]>();

    for (const mv of auctionPriceHistory.keys()) {
      const arr = [];
      // @ts-ignore
      for (const bid of auctionPriceHistory.get(mv)) {
        arr.unshift({
          x: moment(new Date(bid.deadline * 1000)).format("YYYY-MM-DD"),
          y: bid.highestBid / 1e18
        });
      }
      map.set(mv, arr);
    }

    handleMvChartMap(map);
  }, [auctionPriceHistory]);

  useEffect(() => {
    if (mvChartMap) {
      handleChartData({
        datasets: [
          {
            label: "ETH",
            data: mvChartMap.get(metaverse),
            borderColor: "#F4E6F5",
            backgroundColor: "#F4E6F5",
            pointBackgroundImage: "linear-gradient(#FFF2FE, #FFBFF6)"
          }
        ]
      });
    }
  }, [mvChartMap, metaverse]);

  if (Object.keys(chartData).length === 0) {
    return renderHistorySkeleton();
  }

  return (
    <div className="w-full flex flex-col bg-gray-600 border border-gray-500 mt-4 md:mt-8 rounded-lg overflow-hidden">
      <div className="w-full bg-gray-500 border-b border-gray-600 flex items-center h-[58px] px-4 flex-none">
        <div className="h-full flex w-full items-center text-base font-bold text-white">
          <OfferIcon className="h-6 mr-2" />
          <FormattedMessage id="Price History" />
        </div>
      </div>
      <div className="w-full flex-1">
        <div className="w-full h-full flex">
          {/* Responsive History Chat */}
          {/* @ts-ignore */}
          {(chartData?.datasets || []).length > 0 ? (
            <div className="w-full px-4 pb-4 h-96">
              <Line options={options} data={chartData} />
            </div>
          ) : (
            <div className="m-auto text-gray-200 py-10 flex flex-col">
              <img src={MADColorLogo} className="h-20 w-20 mx-auto" />
              <div className="text-white font-nexa text-sm text-center mt-2 px-10 lg:px-20">
                It looks like we had trouble loading auction price history. Please check your internet connection and
                try again.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuctionPriceHistory;
