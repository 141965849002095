import { useState, useContext } from "react";
import { Contract } from "ethers";
import { FormattedMessage } from "react-intl";
import { stakeAndBid } from "helpers/bid";
import Button from "components/ui/Button";
import EthIcon from "assets/eth_black.svg";
import { ModalContext } from "context/ModalContext";
import { MODAL_TYPES } from "helpers/constants";
import { addNotify } from "store/notificationMiddleware";

interface ExtendLeaseContentProps {
  bid: number;
  days: number;
  landData: Land | undefined;
  bidDayError: boolean;
  bidDayThreshold: number;
  item: Bid;
  metaverseInfo: any;
  radicalMarketInstance: Contract | null;
  metaverseId: number;
  onCancel: () => void;
  handleBid: (e: any) => void;
  handleBidPriceError: (e: any) => void;
  handleTotalBidCollateralError: (e: any) => void;
  handleDays: (e: any) => void;
  handleBidDayError: (e: any) => void;
  handleLandData: (e: any) => void;
  handleOpenExtendLeaseModal: (b: boolean) => void;
  [key: string]: any;
}

export function ExtendLeaseContent(props: ExtendLeaseContentProps) {
  const {
    bid: initialBid,
    days: initialDays,
    landData,
    item,
    metaverseInfo,
    radicalMarketInstance,
    onCancel,
    bidDayError: dayError,
    bidDayThreshold,
    handleBid,
    handleBidPriceError,
    handleTotalBidCollateralError,
    handleDays,
    handleBidDayError,
    handleLandData,
    itemDetails,
    itemOpenSeaDetails,
    bidPriceThreshold,
    metaverseId
  } = props;

  const { showModal } = useContext(ModalContext);

  const [bidDayError, setDayError] = useState(dayError);
  const [bid, setBid] = useState(initialBid || 0);
  const [days, setDays] = useState(initialDays || 0);

  const handleOpenSetBuildModal = () => {
    showModal(MODAL_TYPES.set_builder, {
      radicalMarketInstance,
      metaverseId,
      assetId: item.assetId,
      handleLandData
    });
  };

  const updateBidPerDay = (e: any) => {
    try {
      if (!e.target.value) {
        handleBid(itemDetails?.madPrice ? itemDetails.madPrice : 0);
        setBid(itemDetails?.madPrice ? itemDetails.madPrice : 0);
      } else {
        const input = parseFloat(e.target.value);
        handleBid(input);
        setBid(input);
        handleBidPriceError(input < bidPriceThreshold);
        handleTotalBidCollateralError(false);
      }
    } catch (e) {
      console.log(e);
      handleBid(itemDetails?.madPrice ? itemDetails.madPrice : 0);
      setBid(itemDetails?.madPrice ? itemDetails.madPrice : 0);
    }
  };

  const updateDays = (e: any) => {
    try {
      if (!e.target.value) {
        handleDays(0);
        setDays(0);
      } else {
        const input = parseInt(e.target.value);
        handleDays(input);
        setDays(input);
        handleBidDayError(input < bidDayThreshold);
        setDayError(input < bidDayThreshold);
        handleTotalBidCollateralError(false);
        if (landData?.currentPricePerDay) {
          handleBid(parseFloat(landData.currentPricePerDay) / 1e18);
        }
      }
    } catch (e) {
      console.log(e);
      handleDays(0);
      setDays(0);
    }
  };

  const extendBid = async () => {
    if (!radicalMarketInstance) {
      return;
    }

    const { success, message } = await stakeAndBid(
      metaverseInfo,
      metaverseId.toString(),
      item.assetId,
      radicalMarketInstance,
      bid,
      days
    );
    if (success) {
      onCancel();
      setTimeout(() => {
        showModal(MODAL_TYPES.bid_success, {
          landData,
          parcelName:
            itemOpenSeaDetails && itemOpenSeaDetails.name
              ? itemOpenSeaDetails.name
              : itemDetails
              ? itemDetails.name
                ? itemDetails.name
                : `Parcel #${itemDetails.tokenId.substring(0, 12)}`
              : "Loading...",
          handleOpenSetBuildModal
        });
      }, 600);
    } else {
      addNotify({
        title: "Transaction Failed",
        message,
        type: "error"
      });
    }
  };

  const renderMainContent = () => {
    return (
      <div className="flex flex-col pt-8 pb-6 md:pb-8 px-6 bg-gray-bg-dark lg:auto modal-container">
        <p className="text-white font-commuter lg:text-3xl text-xl font-semibold mb-8 text-center">
          <FormattedMessage id="Extend Your Lease" />
        </p>
        <div className="md:flex items-center lg:text-xl text-12 font-semibold leading-8 pb-6 md:border-b-0 border-white md:mx-0 border-b">
          <div className="flex items-center md:mb-0 mb-6">
            <span className="mr-3">
              <FormattedMessage id="Bid per Day" />:
            </span>
            <img src={EthIcon} alt="eth-icon" className="mr-4 lg:w-7 w-4" />
            <input
              className="text-center py-2 bg-gray-300 border border-white border-opacity-30 rounded-md lg:text-2xl text-12 px-1 outline-none mr-3"
              type="numeric"
              defaultValue={
                landData?.currentPricePerDay ? (parseFloat(landData.currentPricePerDay) / 1e18).toFixed(3) : "--"
              }
              min={0}
              disabled
              onChange={updateBidPerDay}
            />
          </div>
          <div className="flex items-center">
            <span className="text-12 md:mr-3 mr-11">&#10005;</span>
            <span className="mr-3">
              <FormattedMessage id="Rent for" />:
            </span>
            <input
              className="py-2 bg-transparent text-center border border-white border-opacity-30 rounded-md lg:text-2xl text-12 px-1 outline-none mr-3"
              type="numeric"
              value={days}
              min={0}
              onChange={updateDays}
            />
            <span className="lg:text-xl md:text-12 text-8 md:leading-none md:mt-0 leading-4 mt-auto">
              <FormattedMessage id="Extra Days" />
            </span>
          </div>
        </div>
        {bidDayError ? (
          <div className="text-red-200 flex">
            {`${
              bidDayError
                ? `Total days must be larger than ${bidDayThreshold.toFixed(3)} (80% of the current rent time). `
                : ""
            }`}
          </div>
        ) : (
          <></>
        )}
        <div className="flex md:mb-14 mb-8 mt-3 md:mr-0 mr-9">
          <span className="lg:text-xl text-12 font-bold mr-8 ml-auto md:block hidden">=</span>
          <span className="md:mr-16 mr-2 my-auto lg:text-xl text-12 md:ml-0 ml-auto">
            {bid * days > 0 ? (bid * days).toFixed(3) : "--"}
          </span>
          <span className="lg:text-xl text-12 font-semibold">
            <FormattedMessage id="Extra Stake" />
          </span>
        </div>
        <div className="flex justify-center flex-col-reverse md:flex-row">
          <Button
            text="Cancel"
            light={true}
            className="bg-gray-100 hover:bg-white text-black-20 w-full md:w-1/3 text-center font-semibold font-commuter lg:text-lg lg:px-6 lg:py-3 mb-4 md:mb-0 md:mr-2"
            onClick={onCancel}
          />
          <Button
            text={<FormattedMessage id="Extend" />}
            onClick={extendBid}
            className="bg-yellow-300 hover:bg-yellow-200 text-black-20 text-center font-semibold w-full md:w-1/3 font-commuter lg:text-lg lg:px-6 lg:py-3 mb-4 md:mb-0 md:ml-2"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transparent">
      <div className="relative border bg-gray-bg-dark border-gray-300 rounded-2xl">{renderMainContent()}</div>
    </div>
  );
}
