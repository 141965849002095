import { PROFILE_ENDPOINT } from "components/constants/constants";
import { Signer } from "ethers";
import { Dispatch } from "redux";
import { SiweMessage } from "siwe";
import { setNonceObj } from "store/userSlice";
import { addNotify } from "store/notificationMiddleware";

export function createSiweMessage(address: string, statement: string, nonce: string): string {
  const message = new SiweMessage({
    domain: PROFILE_ENDPOINT.substring(12),
    address,
    statement,
    uri: PROFILE_ENDPOINT,
    version: "1",
    chainId: 1,
    nonce: nonce
  });
  return message.prepareMessage();
}

export async function getNonce(address: string): Promise<any> {
  let ret;
  await fetch(`${PROFILE_ENDPOINT}/nonce/${address}`, {
    method: "GET"
  })
    .then(async function (response) {
      ret = JSON.parse(await response.text());
    })
    .catch(() => {
      ret = null;
    });

  return ret;
}

async function checkForUpdatedNonce(
  dispatch: Dispatch,
  nonceObj: { [key: string]: EmailData | undefined },
  address: string
) {
  let tmpNonce = nonceObj?.[address];

  if (!tmpNonce || !tmpNonce.expiry || tmpNonce.expiry * 1000 < Date.now()) {
    tmpNonce = await getNonce(address);
    if (tmpNonce) {
      const obj = { ...nonceObj };
      obj[address] = tmpNonce;
      dispatch(setNonceObj(obj));
    }
  }

  return tmpNonce;
}

export async function signInWithEthereum(
  dispatch: Dispatch,
  nonceObj: { [key: string]: EmailData | undefined },
  signer: Signer
): Promise<{ [key: string]: EmailData | undefined }> {
  const nonce = await checkForUpdatedNonce(dispatch, nonceObj, await signer.getAddress());
  if (!nonce) {
    return nonceObj;
  }

  const message = createSiweMessage(
    await signer.getAddress(),
    "Sign in with Ethereum to Metaverse Advertisement DAO.",
    nonce.nonce
  );
  try {
    const address = await signer.getAddress();
    const signature = await signer.signMessage(message);
    const obj = { ...nonceObj };
    // @ts-ignore
    obj[address] = { ...obj[address], ...nonce, signature: signature, siweMessage: message };
    dispatch(setNonceObj(obj));
    return obj;
  } catch (err: any) {
    console.log(err);
    return nonceObj;
  }
}

export async function getIfUserEmailExists(
  dispatch: Dispatch,
  nonceObj: { [key: string]: EmailData | undefined },
  account: string
): Promise<{ [key: string]: EmailData | undefined }> {
  let ret = false;
  await fetch(`${PROFILE_ENDPOINT}/users/${account}`, {
    method: "GET"
  })
    .then(async function (response) {
      const tmpEmailResponse = JSON.parse(await response.text());
      if (tmpEmailResponse) {
        ret = tmpEmailResponse.email;
      }
    })
    .catch((err: any) => {
      console.log(err);
    });

  const obj = { ...nonceObj };
  // @ts-ignore
  obj[account] = { ...obj[account], emailSet: ret };
  dispatch(setNonceObj(obj));

  return obj;
}

export async function getUserEmail(
  dispatch: Dispatch,
  nonceObj: { [key: string]: EmailData | undefined },
  account: string
): Promise<{ [key: string]: EmailData | undefined }> {
  if (!nonceObj[account]) {
    return nonceObj;
  }

  // @ts-ignore
  const sig = nonceObj[account].signature;
  // @ts-ignore
  const siweMsg = nonceObj[account].siweMessage;

  if (!sig || !siweMsg) {
    return nonceObj;
  }

  let ret = false;
  const data = { signature: sig, siweMessage: siweMsg };
  await fetch(`${PROFILE_ENDPOINT}/users/${account}?data=${encodeURIComponent(JSON.stringify(data))}`, {
    method: "GET"
  })
    .then(async function (response) {
      const tmpEmailObj = JSON.parse(await response.text());
      if (tmpEmailObj) {
        ret = tmpEmailObj.email;
      }
    })
    .catch((err: any) => {
      console.log(err);
    });

  const obj = { ...nonceObj };
  // @ts-ignore
  obj[account] = { ...obj[account], email: ret };
  dispatch(setNonceObj(obj));

  return obj;
}

export async function setUserEmail(
  dispatch: Dispatch,
  nonceObj: { [key: string]: EmailData | undefined },
  account: string,
  email: string
): Promise<{ [key: string]: EmailData | undefined }> {
  if (!nonceObj[account]) {
    return nonceObj;
  }

  // @ts-ignore
  const sig = nonceObj[account].signature;
  // @ts-ignore
  const siweMsg = nonceObj[account].siweMessage;

  if (!sig || !siweMsg) {
    return nonceObj;
  }

  let ret = false;
  await fetch(`${PROFILE_ENDPOINT}/users`, {
    method: "PUT",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: email,
      siweMessage: siweMsg,
      signature: sig
    })
  })
    .then(async function (response) {
      const tmpEmailSet = JSON.parse(await response.text());
      if (tmpEmailSet?.success) {
        ret = tmpEmailSet.success;
      }
    })
    .catch((err: any) => {
      console.log(err);
      return nonceObj;
    });

  if (!ret) {
    addNotify({
      title: "Error Setting Email",
      message: "To set your email, please retry from your account's profile page.",
      type: "error"
    });
    return nonceObj;
  }

  const obj = { ...nonceObj };
  // @ts-ignore
  obj[account] = { ...obj[account], email: email, emailSet: true };
  dispatch(setNonceObj(obj));

  return obj;
}
