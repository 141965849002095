import { useWeb3React } from "@web3-react/core";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import ReactPaginate from "react-paginate";
import { useElementSize } from "usehooks-ts";
import { ReactComponent as GavelIcon } from "../../assets/gavel_filled.svg";
import MoreInfoDropdown from "components/ui/MoreInfoDropdown";
import { ModalContext } from "context/ModalContext";
import { MODAL_ANIMATIONS, MODAL_TYPES } from "helpers/constants";
import { stringEqualsIgnoreCase } from "helpers/misc";
import _ from "lodash";
import { getBidEventUploadStatusBatch, downloadZipFromUrl } from "helpers/files";

interface ProfileMyAdsProps {
  loading: boolean;
}

function ProfileMyAds(props: ProfileMyAdsProps) {
  const { loading } = props;

  const [currentPage, handleCurrentPage] = useState(0);
  const [wonBids, handleWonBids] = useState<BidEvent[]>();

  const { bidAndFundsClaimed } = useSelector((state: RootState) => state.auction);
  const { showModal } = useContext(ModalContext);

  const { account } = useWeb3React();
  const [widthRef, { width }] = useElementSize();
  const navigate = useNavigate();

  const itemsPerPage = 5;
  const dropdownItems = [/*"Update Posters", */ "View Details"];

  useEffect(() => {
    if (bidAndFundsClaimed.bidEvents) {
      const aucs: string[] = [];
      const tmpBidEvents: BidEvent[] = [];
      for (const idx in bidAndFundsClaimed.bidEvents) {
        const item = { ...bidAndFundsClaimed.bidEvents[idx] };
        if (
          stringEqualsIgnoreCase(account, item.auction?.highestBidder) &&
          item.auction &&
          item.auction.deadline * 1000 <= Date.now() &&
          !_.has(aucs, item.auction.id)
        ) {
          aucs.push(item.auction.id);
          item.idx = parseInt(idx);
          tmpBidEvents.push(item);
        }
      }
      loadEventStatus(tmpBidEvents);
    } else {
      handleWonBids(undefined);
    }
  }, [bidAndFundsClaimed]);

  const loadEventStatus = async (bids: BidEvent[]) => {
    if (bids) {
      const bidpromises = await getBidEventUploadStatusBatch(bids);
      handleWonBids(bidpromises);
    } else {
      handleWonBids(bids);
    }
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    handleCurrentPage(event.selected);
  };

  const skeletonRow = () => {
    return (
      <tr className="border-t border-gray-400 animate-pulse">
        <td scope="col" className="pr-2 pt-4 whitespace-nowrap">
          <div className="flex">
            <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-24" />
          </div>
        </td>
        <td scope="col" className="px-2 pt-4 hidden md:table-cell whitespace-nowrap">
          <div className="flex">
            <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-12" />
          </div>
        </td>
        <td scope="col" className="px-2 pt-4 hidden md:table-cell whitespace-nowrap">
          <div className="flex">
            <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-20" />
          </div>
        </td>
        <td scope="col" className="px-2 pt-4 whitespace-nowrap">
          <div className="flex flex-col">
            <div className="bg-white rounded mr-2 mt-1 mb-1 h-4 w-24" />
            <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-24" />
          </div>
        </td>
        <td scope="col" className="pl-2 pt-4 whitespace-nowrap">
          <div className="flex">
            <div className="bg-white rounded mr-2 mt-1 mb-4 h-8 w-28" />
          </div>
        </td>
        <td scope="col" className="whitespace-nowrap">
          <div className="flex">
            <div className="h-6 w-2 bg-white rounded" />
          </div>
        </td>
      </tr>
    );
  };

  const renderSkeleton = () => {
    return (
      <div className="w-full flex flex-col bg-gray-600 border border-gray-500 mt-4 md:mt-8 rounded-lg overflow-hidden">
        <div className="w-full bg-gray-500 border-b border-gray-600 flex items-center h-[58px] px-4 flex-none">
          <div className="h-full flex w-full items-center text-base font-bold text-gray-100">
            <GavelIcon className="h-6 w-auto mr-2 fill-gray-100" />
            Past Bids
          </div>
        </div>
        <div className="text-gray-200 flex flex-col min-h-72 pt-8 pb-4 px-6 overflow-x-auto dark-overflow">
          <table className="w-full min-w-full text-left text-gray-100">
            <thead>
              <tr className="animate-pulse">
                <th scope="col" className="pr-2 pb-0.5 whitespace-nowrap">
                  <div className="flex">
                    <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-24" />
                  </div>
                </th>
                <th scope="col" className="px-2 pb-0.5 hidden md:table-cell whitespace-nowrap">
                  <div className="flex">
                    <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-12" />
                  </div>
                </th>
                <th scope="col" className="px-2 pb-0.5 hidden md:table-cell whitespace-nowrap">
                  <div className="flex">
                    <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-16" />
                  </div>
                </th>
                <th scope="col" className="px-2 pb-0.5 whitespace-nowrap">
                  <div className="flex">
                    <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-32" />
                  </div>
                </th>
                <th scope="col" className="pl-2 pb-0.5 whitespace-nowrap">
                  <div className="flex">
                    <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-20" />
                  </div>
                </th>
                <th scope="col" className="whitespace-nowrap">
                  <div className="flex">
                    <div className="h-0 w-0" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {skeletonRow()}
              {skeletonRow()}
              {skeletonRow()}
              {skeletonRow()}
              {skeletonRow()}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const tableRows = wonBids
    ? wonBids.map((entry, idx) => {
        if (idx < currentPage * itemsPerPage || (currentPage + 1) * itemsPerPage <= idx) {
          return null;
        }
        const auction = entry.auction;
        return (
          <tr key={`past-bid-${idx}`} className="border-t border-gray-400">
            <td className="pr-2 py-4 whitespace-nowrap text-15 text-gray-100">
              {auction ? (auction.metaverse ? "CryptoVoxels" : "Decentraland") : "--"}
            </td>
            <td className="px-2 hidden md:table-cell py-4 whitespace-nowrap text-15 text-gray-100">
              {auction?.uri?.landCount ? auction.uri.landCount : "--"}
            </td>
            <td className="px-2 hidden md:table-cell py-4 whitespace-nowrap text-15 text-gray-100">
              {new Date(entry.timestamp * 1000).toLocaleDateString()}
            </td>
            <td className="px-2 py-4 whitespace-nowrap text-15 text-gray-100">
              {auction ? new Date(auction.deadline * 1000 + 604800000).toLocaleDateString() : "--"} - <br />
              {auction ? new Date(auction.deadline * 1000 + 1814400000).toLocaleDateString() : "--"}
            </td>
            <td className="pl-2 py-4 whitespace-nowrap text-15 align-center">
              {auction?.uploaded ? (
                auction?.mediaLink ? (
                  <div
                    className="font-nexa text-yellow-400 my-auto cursor-pointer"
                    onClick={() => {
                      if (!auction.mediaLink) {
                        return;
                      }
                      downloadZipFromUrl(auction.mediaLink);
                    }}
                  >
                    1 file attached
                  </div>
                ) : (
                  <div className="max-w-[8rem] my-auto py-2 px-6 rounded flex justify-center items-center font-bold select-none cursor-not-allowed bg-gray-300 text-gray-200">
                    Under Review
                  </div>
                )
              ) : (
                <div
                  className="max-w-[8rem] my-auto py-2 px-6 rounded flex justify-center items-center font-bold select-none cursor-pointer bg-yellow-300 hover:bg-yellow-200 text-gray-300 "
                  onClick={() => {
                    showModal(MODAL_TYPES.upload_zip, {
                      auction: auction,
                      idx: idx,
                      internallyManagedProps: {
                        selectedFile: undefined,
                        notes: "",
                        uploading: false,
                        isLoading: false,
                        errMsg: ""
                      },
                      updateWonBids: (
                        idx: number,
                        data: { success: boolean; link?: string; notes?: string; error?: any }
                      ) => {
                        const wb = wonBids;
                        if (wb[idx].auction) {
                          // @ts-ignore
                          wb[idx].auction = { ...wb[idx].auction };
                          // @ts-ignore
                          wb[idx].auction.mediaLink = data.link;
                          //@ts-ignore
                          wb[idx].auction.notes = data.notes;
                          // @ts-ignore
                          wb[idx].auction.uploaded = data.success;
                        }
                        handleWonBids(wb);
                      },
                      animation: MODAL_ANIMATIONS.unfold,
                      handleWonBids: handleWonBids
                    });
                  }}
                >
                  Upload
                </div>
              )}
            </td>
            <td className="h-full">
              <div className="flex flex-col">
                <MoreInfoDropdown
                  dropdownItems={dropdownItems}
                  onSelection={(idx: number) => {
                    showModal(MODAL_TYPES.ad_details, {
                      data: wonBids.at(entry.idx ? entry.idx : idx),
                      animation: MODAL_ANIMATIONS.unfold
                    });
                  }}
                  buttonClassName={`flex select-none`}
                  dropdownClassName={`select-none overflow-hidden bg-gray-bg-dark rounded-lg text-white mt-2`}
                  dropdownButtonClassName={`px-4 py-2 hover:bg-gray-500 cursor-pointer right-0`}
                  dropdownZIndexClassName="z-20"
                  zeroHeightDropdown={true}
                  zeroWidthDropdown={true}
                />
              </div>
            </td>
          </tr>
        );
      })
    : null;

  if (!account || loading || !wonBids) {
    return renderSkeleton();
  }

  return (
    <>
      <div
        ref={widthRef}
        className="w-full flex flex-col bg-gray-600 border border-gray-500 mt-4 md:mt-8 rounded-lg overflow-hidden"
      >
        <div className="w-full bg-gray-500 border-b border-gray-600 flex items-center h-[58px] px-4 flex-none">
          <div className="h-full flex w-full items-center text-base font-bold text-gray-100">
            <GavelIcon className="h-6 w-auto mr-2 fill-gray-100" />
            My Ads
          </div>
        </div>
        {wonBids && wonBids.length > 0 ? (
          <div className="text-gray-200 flex flex-col pt-8 pb-4 px-6 overflow-x-auto dark-overflow">
            <table className="w-full min-w-full text-left text-gray-100">
              <thead>
                <tr>
                  <th scope="col" className="pr-2 text-15 font-semibold pb-4 whitespace-nowrap">
                    Metaverse
                  </th>
                  <th scope="col" className="px-2 hidden md:table-cell text-15 font-semibold pb-4 whitespace-nowrap">
                    Lands
                  </th>
                  <th scope="col" className="px-2 hidden md:table-cell text-15 font-semibold pb-4 whitespace-nowrap">
                    Bid Date
                  </th>
                  <th scope="col" className="px-2 text-15 font-semibold pb-4 whitespace-nowrap">
                    Ads Timeframe
                  </th>
                  <th scope="col" className="pl-2 text-15 font-semibold pb-4 whitespace-nowrap">
                    Poster File
                  </th>
                  <th className="m-0 p-0 w-6" />
                </tr>
              </thead>
              <tbody>{tableRows}</tbody>
            </table>
          </div>
        ) : (
          <div className="flex min-h-72 text-gray-100">
            <div className="m-auto">
              You don&apos;t have any ads yet.{" "}
              <span
                className="underline cursor-pointer text-yellow-300 hover:text-yellow-200"
                onClick={() => {
                  navigate("../auction", { replace: true });
                }}
              >
                Join an auction
              </span>{" "}
              now.
            </div>
          </div>
        )}
      </div>
      <div className="mx-auto md:mr-0 md:ml-auto">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          previousLabel="<"
          onPageChange={handlePageClick}
          pageRangeDisplayed={width > 600 ? 2 : 1}
          marginPagesDisplayed={width > 600 ? 2 : 1}
          pageCount={Math.ceil(wonBids ? (wonBids.length / itemsPerPage < 1 ? 0 : wonBids.length / itemsPerPage) : 0)}
          // @ts-ignore
          renderOnZeroPageCount={null}
          forcePage={currentPage}
          className="flex flex-row gap-1 text-gray-200 font-nexa mt-3 select-none"
          activeLinkClassName="bg-gray-200 text-gray-100"
          pageLinkClassName="inline-block bg-gray-600 w-6 sm:w-8 h-6 sm:h-8 rounded-md hover:bg-gray-200 hover:text-gray-100 text-center sm:leading-8"
          previousLinkClassName="inline-block bg-gray-600 hover:bg-gray-200 hover:text-gray-100 rounded-md text-center w-6 h-6"
          nextLinkClassName="inline-block bg-gray-600 hover:bg-gray-200 hover:text-gray-100 rounded-md text-center w-6 h-6"
          breakLinkClassName="inline-block w-6 sm:w-8 h-6 sm:h-8 rounded-md hover:bg-gray-600 hover:text-gray-100 text-center sm:leading-8"
        />
      </div>
    </>
  );
}

export default ProfileMyAds;
