import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { getBidAndFundsClaimedEvents } from "helpers/graphql";
import { stringEqualsIgnoreCase } from "helpers/misc";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setBidAndFundsClaimed } from "store/auctionSlice";
import { setAccountBalance } from "store/userSlice";
import ProfileAccountDetails from "./ProfileAccountDetails";
import ProfileClaimRefund from "./ProfileClaimRefund";
import ProfileHeader from "./ProfileHeader";
import ProfilePastBids from "./ProfilePastBids";
import { useHeight } from "helpers/hooks/useHeight";
import ProfileMyAds from "./ProfileMyAds";

interface ProfilePageProps {
  madAuctionContract: Contract | null;
  madAuctionInstance: Contract | null;
}

function ProfilePage(props: ProfilePageProps) {
  const [loading, handleLoading] = useState(true);
  const [data, handleData] = useState<
    { timestamp: Date; amount: string; id?: string; metaverse?: number }[] | undefined
  >();
  const { madAuctionContract, madAuctionInstance } = props;
  const dispatch = useDispatch();
  const { bidAndFundsClaimed, auctionData } = useSelector((state: RootState) => state.auction);
  const { account, active } = useWeb3React();
  const [heightRef, height] = useHeight();

  useEffect(() => {
    if (!account || !active || !madAuctionContract) {
      handleLoading(false);
      return;
    }

    (async () => {
      handleLoading(true);
      // Load email
      // Load account balance
      dispatch(setAccountBalance((await madAuctionContract.balance(account)) / 1e18));

      // Load bid and claim events
      handleData(undefined);
      dispatch(setBidAndFundsClaimed(await getBidAndFundsClaimedEvents(account)));

      handleLoading(false);
    })();
  }, [account, active, madAuctionContract]);

  // Set max-height property of Claim Refund component relative to the Account Details component
  useEffect(() => {
    // Container
    let el = document.getElementById("pcr");
    if (el) {
      el.style.maxHeight = height + "px";
    }

    // Parent div of the list
    el = document.getElementById("pcr2");
    if (el) {
      el.style.maxHeight = height - 58 + "px";
    }
  }, [height, bidAndFundsClaimed]);

  useEffect(() => {
    if (!bidAndFundsClaimed.bidEvents) {
      handleData(undefined);
      return;
    } else if (bidAndFundsClaimed.bidEvents.length === 0) {
      handleData([]);
      return;
    }

    (async () => {
      const auctionIdSet = new Set<string>();
      const auctionMap = new Map<string, BidEvent[]>();
      // Filter bid events by their auction id
      if (!bidAndFundsClaimed.bidEvents) {
        return;
      }
      for (const be of bidAndFundsClaimed.bidEvents) {
        // Add the event if they are not the auction winner or the auction was cancelled (funds claimable)
        if (be.auction && (!stringEqualsIgnoreCase(be.auction.highestBidder, account) || be.auction.cancelled)) {
          auctionIdSet.add(be.auction.id);
          // Even if the user bids over themself, this will display all bids (including the old ones)
          // if (auctionMap.has(be.auction.id)) {
          //   auctionMap.get(be.auction.id)?.push(be);
          // } else
          if (!auctionMap.has(be.auction.id)) {
            auctionMap.set(be.auction.id, [be]);
          }
        }
      }

      // Remove all bid events from an auction if there is a FundClaimedEvent
      // for the given auction id
      for (const fce of bidAndFundsClaimed.fundsClaimedEvents) {
        if (fce.auction.id) {
          if (auctionIdSet.has(fce.auction.id)) {
            auctionIdSet.delete(fce.auction.id);
          }
        }
      }

      // Remove bid events from the active auctions
      if (auctionData) {
        for (const auc of auctionData.values()) {
          if (auctionIdSet.has(auc.id)) {
            auctionIdSet.delete(auc.id);
          }
        }
      }

      // Create data array in correct format for ClaimRefundModal
      const dataArr: { timestamp: Date; amount: string; id?: string; metaverse?: number }[] = [];
      for (const aucId of auctionIdSet) {
        if (auctionMap.get(aucId)) {
          // @ts-ignore
          for (const aucBid of auctionMap.get(aucId)) {
            dataArr.push({
              timestamp: new Date(aucBid.timestamp * 1000),
              amount: aucBid.amount,
              id: aucBid.auction?.id,
              metaverse: aucBid.auction?.metaverse
            });
          }
        }
      }

      // Set loading to false and set the data array for ClaimRefundModal
      handleData(dataArr);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidAndFundsClaimed, auctionData]);

  return (
    <div className="w-full flex flex-col bg-gray-bg-dark pt-20 pb-10">
      <ProfileHeader />
      <div className="w-full flex flex-col bg-gray-bg-dark pt-4 px-3 md:pt-8 md:px-10">
        <div className="w-full mx-auto my-5 flex flex-col max-w-7xl">
          <div className={`w-full flex flex-col-reverse gap-4 md:gap-6 lg:flex-row`}>
            <div id="pcr" className={`overflow-hidden flex-shrink-0 rounded-lg`}>
              <ProfileClaimRefund
                data={data}
                handleData={handleData}
                loading={loading}
                madAuctionContract={madAuctionContract}
                madAuctionInstance={madAuctionInstance}
              />
            </div>
            <div ref={heightRef} className="w-full flex mb-auto overflow-hidden">
              <ProfileAccountDetails madAuctionInstance={madAuctionInstance} loading={loading} />
            </div>
          </div>
          <ProfileMyAds loading={loading} />
          <ProfilePastBids loading={loading} />
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
