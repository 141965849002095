import { PROFILE_ENDPOINT } from "components/constants/constants";
import { BigNumber, ethers, Signer } from "ethers";

export async function getAsByteArray(file: File): Promise<Uint8Array> {
  return new Uint8Array(await readFile(file));
}

function readFile(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    // Create file reader
    const reader = new FileReader();
    // Register event listeners
    reader.addEventListener("loadend", (e: any) => resolve(e.target.result));
    reader.addEventListener("error", reject);
    // Read file
    reader.readAsArrayBuffer(file);
  });
}

export function blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
}

export function b64toBlob(b64Data: string, contentType = "", sliceSize = 512): Blob {
  const byteCharacters = Buffer.from(b64Data, "base64").toString();
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export async function downloadZipFromUrl(url: string): Promise<Blob | undefined> {
  try {
    await fetch(url)
      .then((r) => r.text())
      .then((res) => {
        const idx = res.indexOf(";");
        if (idx >= 0) {
          const type = res.substring(5, idx);
          res = res.substring(idx + 8);

          const a = window.document.createElement("a");
          a.href = `data:${type};base64,` + res;
          a.download = "download.zip";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch(() => {
        return Promise.resolve(undefined);
      });
  } catch (err) {
    return Promise.resolve(undefined);
  }
}

export async function resetAuctionUpload(account: string, signer: Signer, auctionId: string): Promise<void> {
  const sig = await signer.signMessage(ethers.utils.hashMessage(BigNumber.from(auctionId).toNumber().toString()));
  const tmpBody = {
    address: account,
    auctionId: BigNumber.from(auctionId).toNumber(),
    signature: sig
  };
  return fetch(`${PROFILE_ENDPOINT}/upload-reset`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(tmpBody)
  })
    .then((response) => response.json())
    .then((res) => {
      console.log(res);
      return;
    })
    .catch(() => {
      return;
    });
}

export async function getBidUploadStatus(aucId: string): Promise<boolean> {
  return fetch(`${PROFILE_ENDPOINT}/upload-status?auctionId=${aucId}`, {
    method: "GET"
  })
    .then((response) => response.json())
    .then((res) => {
      return Promise.resolve(res.success);
    })
    .catch(() => {
      return Promise.resolve(false);
    });
}

export async function getBidEventUploadStatusBatch(bids: BidEvent[]): Promise<BidEvent[]> {
  // const tmp: string[] = [];
  let tmp = "";
  for (const bid of bids) {
    tmp += (bid.auction?.id ? bid.auction.id : "") + ",";
  }
  tmp = tmp.substring(0, tmp.length - 1);
  return fetch(`${PROFILE_ENDPOINT}/upload-status-batch?auctionIds=${tmp}`, {
    method: "GET"
  })
    .then((response) => response.json())
    .then((res) => {
      const tmpbids: BidEvent[] = [];
      for (const i in bids) {
        tmpbids[i] = { ...bids[i] };
        if (tmpbids[i].auction) {
          // @ts-ignore
          tmpbids[i].auction = {
            ...tmpbids[i].auction,
            uploaded: res.success && res.data && res.data[i]?.link && res.data[i].link !== "",
            mediaLink: res.success && res.data && res.data[i]?.link && res.data[i].link !== "" ? res.data[i].link : "",
            notes: res.success && res.data && res.data[i]?.notes && res.data[i].notes !== "" ? res.data[i].notes : ""
          };
        }
      }
      return tmpbids;
    })
    .catch(() => {
      const tmpbids: BidEvent[] = [];
      for (const i in bids) {
        tmpbids[i] = { ...bids[i] };
        if (tmpbids[i].auction) {
          // @ts-ignore
          tmpbids[i].auction = { ...tmpbids[i].auction, uploaded: false };
        }
      }
      return tmpbids;
    });
}

export async function uploadFileAndNotes(
  account: string,
  signer: Signer,
  auction: Auction,
  file: File,
  notes: string
): Promise<{ success: boolean; link?: string; notes?: string; error?: any }> {
  const b64 = await blobToBase64(file);
  const sig = await signer.signMessage(
    ethers.utils.hashMessage(ethers.utils.hashMessage(JSON.stringify(b64)) + ethers.utils.hashMessage(notes))
  );
  const tmpBody = {
    address: account,
    auctionId: BigNumber.from(auction.id).toNumber(),
    signature: sig,
    file: b64,
    notes: notes
  };
  return fetch(`${PROFILE_ENDPOINT}/upload`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(tmpBody)
  })
    .then((response) => response.json())
    .then((res) => {
      return res;
    });
}
