import React, { useEffect, useState, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract, ethers } from "ethers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "store";

import Button from "../ui/Button";
import ParcelGroup from "../ParcelGroup";
import Sidebar from "../Sidebar";
import Search from "../Search";
import { getDecentralandEstates, getDecentralandParcels } from "helpers/graphql";
import { getLayerName } from "helpers/misc";
import Drawer from "../Drawer";
import Images from "components/common/ImageIcons";
import { MAD_ADDRESS } from "components/constants/constants";

interface LeaseEstatesProps {
  handleActivePage: (page: string) => void;
  estateContract: Contract | null;
  parcelContract: Contract | null;
  estateInstance: Contract | null;
  parcelInstance: Contract | null;
  cvContract: Contract | null;
}

const NavData = [
  {
    name: <FormattedMessage id="Decentraland" />,
    current: true,
    children: [],
    value: 0,
    disabled: false,
    id: "Decentraland"
  },
  {
    name: <FormattedMessage id="CryptoVoxels" />,
    current: false,
    children: [],
    value: 1,
    disabled: false,
    id: "CryptoVoxels"
  },
  {
    name: <FormattedMessage id="The Sandbox" />,
    current: false,
    children: [],
    value: 2,
    disabled: true,
    id: "Sandbox"
  }
];

const platforms = [
  {
    name: <FormattedMessage id="Decentraland" />
  },
  {
    name: <FormattedMessage id="CryptoVoxels" />
  }
];

function LeaseEstates(props: LeaseEstatesProps) {
  const locale = useSelector((state: RootState) => state.intl.locale);

  const isZH = useMemo(() => locale.includes("zh"), [locale]);

  const [loading, handleLoading] = useState(false);
  const [error, handleError] = useState(false);
  const [layer, setLayer] = useState(0);
  const [activeParcels, handleActiveParcels] = useState([]);
  const [dParcels, handleDParcels] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cParcels, handleCParcels] = useState([]);
  const [data, setData] = useState(NavData);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPlatformSelectOpen, handleIsPlatformSelectOpen] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState(layer);

  const [searchContent, setSearchContent] = useState("");

  const { handleActivePage, estateContract, parcelContract, estateInstance, parcelInstance, cvContract } = props;
  const { account, active } = useWeb3React();
  const intl = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleActivePage("/lease");
  }, [handleActivePage]);

  useEffect(() => {
    switch (layer) {
      case 0:
        handleActiveParcels(dParcels);
        break;
      case 1:
        handleActiveParcels(cParcels);
        break;
      default:
        break;
    }
  }, [layer, dParcels, cParcels]);

  useEffect(() => {
    (async () => {
      // When we re-enable CV, uncomment this.
      // handleDParcels([]);
      // handleCParcels([]);

      if (account && active && estateContract && parcelContract && cvContract) {
        if (layer === 0) {
          // Decentraland
          handleError(false);
          handleLoading(true);
          const parcels: any = await getDecentralandParcels(parcelContract, account); //'0xeb5edfa128c39bd50879d8ea36e4e84218fa31b0'
          const estates: any = await getDecentralandEstates(estateContract, account);

          // Check if a parcel belongs to an estate
          for (const p of parcels) {
            try {
              const landEstateId = await estateContract.getLandEstateId(p.tokenId);
              if (landEstateId !== null && landEstateId.isZero()) {
                // Parcel does not belong to an estate. We can display it.
                estates.push(p);
              }
            } catch (err) {
              console.log(err);
            }
          }

          handleDParcels(estates);
          handleActiveParcels(estates);
          handleLoading(false);
        } else if (layer === 1) {
          // For now, we will do nothing (CV issues, the following code should work great!)
          // CryptoVoxels
          // handleError(false);
          // handleLoading(true);
          // try {
          //   let balance = await cvContract.balanceOf(account);
          //   if(!balance) {
          //     handleCParcels([]);
          //   } else {
          //     let tcp = [];
          //     for(let i = 0; i < balance; i++) {
          //       let parcelId = await cvContract.tokenOfOwnerByIndex(account, i);
          //       let id = parcelId.toString();
          //       let data = await fetch(`https://www.cryptovoxels.com/p/${id}`, {
          //         headers: {
          //           'Content-Type': 'application/json',
          //         },
          //       }).then((res) => res.json());
          //       let extradata = await fetch(`https://www.cryptovoxels.com/grid/parcels/${id}`, {
          //         headers: {
          //           'Content-Type': 'application/json',
          //         },
          //       }).then((res) => res.json());
          //       let td = {
          //         type: 'parcel',
          //         image: data.image,
          //         name: data.name,
          //         parcel: {x: Math.ceil((extradata.parcel.x1 + extradata.parcel.x2) / 2), y: Math.ceil((extradata.parcel.z1 + extradata.parcel.z2) / 2)},
          //         tokenId: id,
          //         updateOperator: _.includes(extradata.contributors, MAD_ADDRESS),
          //       }
          //       tcp.push(td);
          //     }
          //     handleCParcels(tcp);
          //     handleActiveParcels(tcp);
          //   }
          // } catch(err) {
          //   console.log(err);
          //   handleError(true);
          // }
          // handleLoading(false);
        }
      } else {
        handleError(true);
      }
    })();
  }, [account, active, estateContract, parcelContract, layer, cvContract]);

  const handleClose = () => {
    setIsDrawerOpen(false);
  };

  const applyFilter = () => {
    setLayer(selectedLayer);
    setIsDrawerOpen(false);
  };

  const lease = (obj: any) => {
    if (!obj) {
      return;
    }

    if (layer === 0) {
      if (obj.type === "estate") {
        if (estateInstance) {
          estateInstance.setUpdateOperator(obj.tokenId, MAD_ADDRESS);
        }
      } else if (obj.type === "parcel") {
        if (parcelInstance) {
          parcelInstance.setUpdateOperator(obj.tokenId, MAD_ADDRESS);
        }
      }
    } else if (layer === 1) {
      window.open(`https://www.cryptovoxels.com/parcels/${obj.tokenId}`, "_blank")?.focus?.();
    }
  };

  const cancelLease = (obj: any) => {
    if (!obj) {
      return;
    }

    if (layer === 0) {
      if (obj.type === "estate") {
        if (estateInstance) {
          estateInstance.setUpdateOperator(obj.tokenId, ethers.constants.AddressZero);
        }
      } else if (obj.type === "parcel") {
        if (parcelInstance) {
          parcelInstance.setUpdateOperator(obj.tokenId, ethers.constants.AddressZero);
        }
      }
    } else if (layer === 1) {
      window.open(`https://www.cryptovoxels.com/parcels/${obj.tokenId}`, "_blank")?.focus?.();
    }
  };

  const claimProfit = (obj: any) => {
    console.log("Claim project: " + obj);
  };

  const renderMainContent = () => {
    return (
      <div className="max-w-7xl mx-auto flex flex-row w-full">
        <div className="w-56 flex-shrink-0 hidden md:block">
          <Sidebar title="Platforms" data={data} setData={setData} onClick={setLayer} isArrow />
        </div>
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-row">
            <div className="w-full ml-4 px-2">
              <Search value={searchContent} isZH={isZH} onSearch={setSearchContent} />
            </div>
            <div className="flex-shrink-0 ml-auto flex items-center mr-4">
              <div
                className="flex md:hidden font-nexa text-green-200 cursor-pointer"
                onClick={() => {
                  setSelectedLayer(layer);
                  setIsDrawerOpen(true);
                }}
              >
                FILTER <img src={Images.FilterIcon} className="ml-1" alt="filterIcon" />
              </div>
            </div>
          </div>

          {active && !loading && !error ? (
            activeParcels.length > 0 ? (
              <>
                <div>
                  <ParcelGroup
                    parcels={activeParcels}
                    layerName={getLayerName(intl, layer)}
                    leaseCallback={lease}
                    cancelCallback={cancelLease}
                    claimProfitCallback={claimProfit}
                    metaverseId={layer}
                  />
                </div>
              </>
            ) : layer === 0 ? (
              <>
                <div className="w-full text-center my-16 text-gray-200 select-none">
                  <FormattedMessage id="No parcels or estates have been found for this account." />
                </div>
              </>
            ) : (
              <>
                <div className="w-full text-center mt-16 text-gray-200 select-none">
                  <FormattedMessage id="Coming soon" />{" "}
                  <span
                    className="hover:text-white underline cursor-pointer"
                    onClick={() => {
                      window.open("https://www.cryptovoxels.com/account/parcels", "_blank")?.focus?.();
                    }}
                  >
                    <FormattedMessage id="set MAD as contributor now" />
                  </span>
                  !
                </div>
                <div className="flex mx-auto justify-center mt-4 mb-16">
                  <div className="text-gray-200 select-none mr-1">
                    <FormattedMessage id="MAD's Contributor Address:" />
                  </div>
                  <CopyToClipboard text={MAD_ADDRESS}>
                    <div className="inline mb-1 cursor-pointer text-gray-200 hover:text-white">
                      <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M13.4375 0.1875H3.49219C3.41055 0.1875 3.34375 0.254297 3.34375 0.335938V1.375C3.34375 1.45664 3.41055 1.52344 3.49219 1.52344H12.6953V14.2891C12.6953 14.3707 12.7621 14.4375 12.8438 14.4375H13.8828C13.9645 14.4375 14.0312 14.3707 14.0312 14.2891V0.78125C14.0312 0.452832 13.7659 0.1875 13.4375 0.1875ZM11.0625 2.5625H1.5625C1.23408 2.5625 0.96875 2.82783 0.96875 3.15625V13.0032C0.96875 13.1609 1.03184 13.3112 1.14316 13.4226L4.35869 16.6381C4.39951 16.6789 4.4459 16.7123 4.496 16.7401V16.7754H4.57393C4.63887 16.7995 4.70752 16.8125 4.77803 16.8125H11.0625C11.3909 16.8125 11.6562 16.5472 11.6562 16.2188V3.15625C11.6562 2.82783 11.3909 2.5625 11.0625 2.5625ZM4.49414 14.8865L2.89658 13.2871H4.49414V14.8865ZM10.3203 15.4766H5.68164V12.8418C5.68164 12.4317 5.34951 12.0996 4.93945 12.0996H2.30469V3.89844H10.3203V15.4766Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </CopyToClipboard>
                </div>
              </>
            )
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <div className="pt-10 lg:pt-52 lg:pb-52">
                <img src={Images.LoginGif} alt="Login" className="w-44 h-auto mx-auto my-auto mb-10" />
                <p className="font-commuter text-xl font-normal text-gray-200 text-center">
                  <FormattedMessage id={error ? "Please log in to view your parcels and estates." : "Loading"} />
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="block md:hidden">
          <Drawer isOpen={isDrawerOpen} handleClose={handleClose}>
            {isPlatformSelectOpen ? (
              <div className="text-12 text-gray-100" style={{ height: "30vh" }}>
                {platforms.map((option, index) => (
                  <div
                    key={`sort-${index}`}
                    className="pl-10 flex h-11 items-center border-b border-black-21 cursor-pointer"
                    onClick={() => {
                      setSelectedLayer(index);
                      handleIsPlatformSelectOpen(false);
                    }}
                  >
                    <p>{option.name}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="px-12 py-6 bg-black-17">
                <p className="font-nexa text-gray-100 text-12 mb-2">PLATFORM</p>
                <div
                  className="bg-black-26 rounded-xl h-9 text-gray-100 font-nexa font-bold text-12 w-full flex items-center px-2 cursor-pointer"
                  onClick={() => handleIsPlatformSelectOpen(true)}
                >
                  {getLayerName(intl, selectedLayer)}
                </div>
                <p className="font-nexa text-gray-100 text-12 mb-2 mt-7">CATEGORY</p>
                <div className="text-12 text-gray-100">
                  <div className="flex h-9 items-center border-b border-black-21">
                    <p>All Lands</p>
                  </div>
                </div>
                <Button
                  text="Apply Filter"
                  borderColor="yellow-300"
                  className="flex items-center text-center font-nexa font-bold text-12 text-gray-600 w-full bg-yellow-300 m-auto h-9 rounded-lg justify-center mt-5"
                  onClick={applyFilter}
                />
              </div>
            )}
          </Drawer>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="w-full flex flex-col text-gray-100" onDragStart={(e) => e.preventDefault()}>
        <div className="w-full flex flex-col md:pl-10 xl:pl-0 bg-gray-bg-dark">
          <div className="w-full flex flex-col-reverse md:flex-row max-w-7xl mx-auto mt-5 xs:mt-10 md:mt-20">
            <div className="md:absolute max-w-7xl w-full md:w-3/4 flex flex-col mr-auto mb-auto text-left p-4 md:px-0 md:pt-12">
              <div className="font-commuter text-3xl md:text-4xl text-center md:text-left font-semibold lg:text-5xl">
                {isZH ? (
                  <FormattedMessage id="LEASE YOUR LANDS" />
                ) : (
                  <>
                    <div className="block md:hidden">
                      <FormattedMessage id="LEASE YOUR LANDS" />
                    </div>
                    <div className="hidden md:block">
                      <FormattedMessage id="LEASE" /> <br /> <FormattedMessage id="YOUR LANDS" />
                    </div>
                  </>
                )}
              </div>
              <div className="mx-auto md:mx-0 md:w-80 lg:w-96 xl:w-1/3 text-center md:text-left text-xl sm:text-xl lg:text-lg font-bold">
                <FormattedMessage id="Bid metaverse land use right for a lower price anytime anywhere." />
              </div>
            </div>
            <div className="ml-auto w-7/10 md:w-3/4 overflow-x-visible">
              <img className="h-full object-cover" src={Images.Billboard} alt="" />
            </div>
          </div>
        </div>
        <div className="flex justify-between bg-gray-600 w-full bg-gray-600 mx-auto md:px-10 py-16">
          {renderMainContent()}
        </div>
      </div>
    </>
  );
}

export default React.memo(LeaseEstates);
