import { FormattedMessage } from "react-intl";

import Images from "components/common/ImageIcons";
import Button from "../ui/Button";

export default function RadicalMarket() {
  // const navigate = useNavigate();

  // const handleClick = useCallback(() => {
  //   navigate("radicalmarket/", { replace: true });
  // }, [])

  return (
    <div className="w-full flex flex-col px-6 py-12 sm:p-8 md:p-20 max-w-screen-8xl mx-auto">
      <div className="text-green-100 text-2xl sm:text-4xl md:text-5xl font-commuter font-semibold text-center">
        <FormattedMessage id="The Radical Market" />
      </div>
      <div className="text-white mt-4 mb-8 text-center text-base sm:text-xl md:text-2xl">
        <FormattedMessage id="radicalDescription" />
      </div>
      <div className="flex flex-col cursor-default">
        {" "}
        {/* cursor-pointer */}
        <Button
          onClick={() => {
            /*handleClick()*/
          }}
          text={<FormattedMessage id="Stay Tuned..." />}
          borderColor="none"
          className="text-center rounded-full md:leading-6 bg-green-200 border-none hover:bg-green-100 text-sm sm:text-xl md:text-2xl mx-auto px-6 py-4 md:px-8 md:py-5 font-nexa font-bold"
        />
      </div>
      <div className="w-10/12 h-auto mx-auto mt-7">
        <img className="w-full" src={Images.Skyline} alt="" />
      </div>
    </div>
  );
}
