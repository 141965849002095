import { useContext, useState, useEffect, useCallback } from "react";
import { ModalContext } from "context/ModalContext";
import { DrawerWrapper, ModalWrapper } from "./ModalWrapper";
import { useWindowSize } from "helpers/hooks";
import { MODAL_TYPES } from "helpers/constants";
import PlaceBidContent from "./ModalContent/PlaceBidContent";
import AdDetailsContent from "./ModalContent/AdDetailsContent";
import { FileUploadContent } from "./ModalContent/FileUploadContent";
import ClaimRefundContent from "./ModalContent/ClaimRefundContent";
import { ExtendLeaseContent } from "./ModalContent/ExtendLeaseContent";
import { BidSuccessfulContent } from "./ModalContent/BidSuccessfulContent";
import { SetBuilderContent } from "./ModalContent/SetBuilderContent";
import { RadicalBidContent } from "./ModalContent/RadicalBidContent";

const ModalContents = {
  [MODAL_TYPES.auction_bid]: PlaceBidContent,
  [MODAL_TYPES.ad_details]: AdDetailsContent,
  [MODAL_TYPES.upload_zip]: FileUploadContent,
  [MODAL_TYPES.claim_refund]: ClaimRefundContent,
  [MODAL_TYPES.extend_lease]: ExtendLeaseContent,
  [MODAL_TYPES.radical_bid]: RadicalBidContent,
  [MODAL_TYPES.bid_success]: BidSuccessfulContent,
  [MODAL_TYPES.set_builder]: SetBuilderContent
};

const RootModal = () => {
  const { hideModal, props, active, componentId, internallyManagedProps } = useContext(ModalContext);

  const [internalProps, handleInternalProps] = useState<any>(internallyManagedProps ? internallyManagedProps : {});
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [allowClosing, handleAllowClosing] = useState<boolean>(true);

  const [width] = useWindowSize();

  useEffect(() => {
    if (width) {
      const wideScreen = width > 768;
      setIsMobile(!wideScreen);
    }
  }, [width]);

  const handleCloseModal = useCallback(() => {
    if (allowClosing) {
      setIsClosing(true);
      setTimeout(() => {
        setIsClosing(false);
        handleInternalProps({});
        if (hideModal) {
          hideModal();
        }
      }, 550);
    }
  }, [hideModal, allowClosing]);

  const handleConfirm = useCallback(() => {
    if (props?.onConfirm) {
      props.onConfirm();
    }
    handleCloseModal();
  }, [props, handleCloseModal]);

  const handleInternalPropChange = (name: string, value: any) => {
    const tmp = Object.assign({}, internalProps);
    tmp[name] = value;
    handleInternalProps(tmp);
  };

  const Modal = isMobile ? DrawerWrapper : ModalWrapper;
  const ModalContent = ModalContents[componentId] ? ModalContents[componentId] : null;

  return (
    <Modal
      onClose={handleCloseModal}
      isClosing={isClosing}
      active={active}
      animation={props?.animation}
      hideOverlay={!isMobile && props?.hiddenOverlay}
      showCloseButton={props?.showCloseButton}
      horizontal={props?.horizontal}
      vertical={props?.vertical}
    >
      {ModalContent ? (
        <ModalContent
          {...props}
          {...internalProps}
          handlePropChange={handleInternalPropChange}
          onConfirm={handleConfirm}
          onCancel={handleCloseModal}
          handleAllowClosing={handleAllowClosing}
        />
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default RootModal;
