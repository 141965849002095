import { SVGProps } from "react";

export const TwitterIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.5562 0.203125C9.51151 0.203125 0.556152 9.15848 0.556152 20.2031C0.556152 31.2478 9.51151 40.2031 20.5562 40.2031C31.6008 40.2031 40.5562 31.2478 40.5562 20.2031C40.5562 9.15848 31.6008 0.203125 20.5562 0.203125ZM30.1678 15.279C30.1812 15.4888 30.1812 15.7076 30.1812 15.9219C30.1812 22.4754 25.1901 30.0246 16.0695 30.0246C13.257 30.0246 10.6499 29.2076 8.45347 27.8013C8.85526 27.846 9.23919 27.8638 9.6499 27.8638C11.9713 27.8638 14.1053 27.0781 15.8062 25.7478C13.6276 25.7031 11.7972 24.2746 11.1722 22.3103C11.9356 22.4219 12.6231 22.4219 13.4088 22.221C12.2871 21.9931 11.2788 21.3839 10.5553 20.4968C9.83186 19.6097 9.43779 18.4996 9.44008 17.3549V17.2924C10.0963 17.6629 10.8687 17.8906 11.6767 17.9219C10.9974 17.4692 10.4403 16.8559 10.0549 16.1363C9.66939 15.4167 9.46742 14.6132 9.46687 13.7969C9.46687 12.8728 9.70794 12.029 10.141 11.2969C11.3861 12.8296 12.9398 14.0832 14.7011 14.9762C16.4624 15.8691 18.3919 16.3815 20.3642 16.4799C19.6633 13.1094 22.1812 10.3817 25.2079 10.3817C26.6365 10.3817 27.9222 10.9799 28.8285 11.9442C29.949 11.7344 31.0204 11.3147 31.9758 10.7522C31.6053 11.8996 30.8285 12.8683 29.7972 13.4799C30.7972 13.3728 31.7615 13.096 32.6544 12.7076C31.9803 13.6987 31.1365 14.5781 30.1678 15.279Z"
        fill="#00AEEF"
      />
    </svg>
  );
};
