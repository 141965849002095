import { Dispatch } from "redux";
import { setOpenLoginModal } from "store/userSlice";

export function getSignerActiveAction(active: boolean, dispatch: Dispatch): boolean {
  if (!active) {
    dispatch(setOpenLoginModal(true));
    return false;
  }

  return true;
}

// async function checkForUpdatedNonce(dispatch: Dispatch, nonce: EmailData | undefined, account: string) {
//   let tmpNonce = nonce;
//   // Check if we need to update the saved nonce
//   if(!tmpNonce || tmpNonce.expiry * 1000 < Date.now()) {
//     await fetch(`https://jeybyqj1207s.usemoralis.com:2053/server/functions/getNonce?address=${account}`, {
//       method: "GET",
//     }).then(function(response) {
//       return response.text();
//     }).then(function(data) {
//       return JSON.parse(data);
//     }).then(async function(res) {
//       if(res.result.nonce) {
//         tmpNonce = res.result;
//       }

//       dispatch(setNonce(tmpNonce));
//     });
//   }

//   return tmpNonce;
// }

// export async function setUserEmailAddress(dispatch: Dispatch, nonce: EmailData | undefined, account: string, email: string, signer: Signer) {
//   try {
//     let tmpNonce = await checkForUpdatedNonce(dispatch, nonce, account);

//     if(!tmpNonce || tmpNonce.expiry * 1000 < Date.now()) {
//       return;
//     }

//     let message = `{"email":"${email}","nonce":"${tmpNonce.nonce}"}`;
//     let signature = await signer.signMessage(message);
//     await fetch(`https://jeybyqj1207s.usemoralis.com:2053/server/functions/setEmail?address=${account}&signature=${signature}&message=${message}`, {
//       method: "GET",
//     }).then(function(response) {
//       return response.text();
//     }).then(function(data) {
//       return JSON.parse(data);
//     }).then(function(res) {
//       if(res.error || !res.result?.success) {
//         storeNotif("Error Setting Email", "To set your email, please retry from your account's profile page.", "danger");
//       }
//     })
//   } catch(err: any) {
//     storeNotif("Error Setting Email", err.message, "danger");
//   }
// }

// export async function getUserEmailAddress(dispatch: Dispatch, nonce: EmailData | undefined, account: string, signer: Signer) {
//   let ret = "";
//   try {
//     let tmpNonce = await checkForUpdatedNonce(dispatch, nonce, account);

//     if(!tmpNonce || tmpNonce.expiry * 1000 < Date.now()) {
//       return;
//     }
//     let message = `{"nonce":"${tmpNonce.nonce}"}`;
//     let signature = await signer.signMessage(message);
//     await fetch(`https://jeybyqj1207s.usemoralis.com:2053/server/functions/getEmail?address=${account}&signature=${signature}&message=${message}`, {
//       method: "GET",
//     }).then(function(response) {
//       return response.text();
//     }).then(function(data) {
//       return JSON.parse(data);
//     }).then(function(res) {
//       if(res.result?.email) {
//         ret = res.result.email;
//       }
//     });
//   } catch(err: any) {
//     console.log("Error retrieving user email address: " + err.message);
//   }

//   return ret;
// }

// export async function getIfUserEmailExists(account: string) {
//   let ret = false;

//   try {
//     await fetch(`https://jeybyqj1207s.usemoralis.com:2053/server/functions/getIfEmailExists?address=${account}`, {
//       method: "GET",
//     }).then(function(response) {
//       return response.text();
//     }).then(function(data) {
//       return JSON.parse(data);
//     }).then(function(res) {
//       ret = res.result.hasEmail;
//     })
//   } catch(e) {
//     ret = false;
//     console.log(e);
//   }

//   return ret;
// }
