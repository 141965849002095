function ProfileHeader() {
  return (
    <>
      <div className="w-full flex flex-col gap-4 justify-center h-[200px] px-10 bg-gray-600 min-h-[200px]">
        <div className="w-full mx-auto max-w-7xl flex flex-col gap-4">
          <div className="text-2xl font-bold text-white">My Profile</div>
          <div className="w-full mx-auto max-w-7xl flex items-center">
            <div className="text-xs text-gray-100 font-thin">
              Manage your account, see your current balance, and view your past bids.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileHeader;
