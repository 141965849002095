import { ReactComponent as DescriptionIcon } from "../../assets/description.svg";
import Images from "components/common/ImageIcons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setSelectedCoord, setSelectedLatLng } from "store/auctionSlice";
import { scrollIntoViewIfNeeded } from "helpers/misc";
import MADColorLogo from "../../assets/logo_sketched.gif";
import { FormattedMessage } from "react-intl";
const TempLocations = new Array(8).fill(1);

const renderLocationSkeleton = (item: any, i: number) => {
  return (
    <div
      className="animate-pulse border-b border-gray-500 w-full h-[89px] min-h-[89px]"
      key={`location-skeleton-${item}-${i}`}
    >
      <div className="w-full flex items-center gap-4 px-4 h-full">
        <div className="w-[56px] h-[56px] bg-gray-300 rounded-xs" />
        <div>
          <div className="h-4 w-[100px] bg-white rounded" />
          <div className="h-4 w-[80px] bg-gray-200 mt-3 rounded" />
        </div>
      </div>
    </div>
  );
};

const AuctionLocations = () => {
  const { activeMetaverseParcels } = useSelector((state: RootState) => state.auction);
  const dispatch = useDispatch();

  type RenderFunctionType = ((item: CVParcel, i: number) => JSX.Element) | ((item: DCLLand, i: number) => JSX.Element);

  const renderLocationCV: RenderFunctionType = (item: CVParcel, i: number) => {
    return (
      <div
        key={`cv-parcel-${i}`}
        className="flex flex-row px-8 py-4 border-b border-gray-400 text-white cursor-pointer"
        onClick={() => {
          scrollIntoViewIfNeeded(document.getElementById("map-container"));
          dispatch(setSelectedLatLng([item.z / 100, item.x / 100]));
        }}
      >
        <img className="rounded-md h-12 w-12 mr-6 my-auto flex-shrink-0" src={item.image} alt="Parcel" />
        <div className="flex flex-col overflow-hidden mr-6">
          <div className="font-bold truncate">{item.address}</div>
          <div className="bg-gray-200 rounded-md px-5 mt-1 text-center mr-auto">{`${item.x}, ${item.z}`}</div>
        </div>
        <img
          onClick={(e: any) => {
            e.stopPropagation();
            window.open(`https://www.cryptovoxels.com/parcels/${item.id}/visit`, "_blank", "noopener");
          }}
          className="h-8 w-8 my-auto ml-auto cursor-pointer flex-shrink-0"
          src={Images.ArrowDownCircle}
          alt="Navigate"
        />
      </div>
    );
  };

  const renderLocationDCL = (item: DCLLand, i: number) => {
    return (
      <div
        key={`dcl-parcel-${i}`}
        className="flex flex-row px-8 py-4 border-b border-gray-400 text-white cursor-pointer"
        onClick={() => {
          scrollIntoViewIfNeeded(document.getElementById("map-container"));
          dispatch(setSelectedCoord([item.x ? item.x : 0, item.y ? item.y : 0]));
        }}
      >
        <img className="rounded-md h-12 w-12 mr-6 my-auto flex-shrink-0" src={item.image} alt="Parcel" />
        <div className="flex flex-col overflow-hidden mr-6">
          <div className="font-bold truncate">{item.name}</div>
          <div className="bg-gray-200 rounded-md px-5 mt-1 text-center mr-auto">{`${item.x}, ${item.y}`}</div>
        </div>
        <img
          onClick={(e: any) => {
            e.stopPropagation();
            window.open(`https://play.decentraland.org/?position=${item.x},${item.y}`, "_blank", "noopener");
          }}
          className="h-8 w-8 my-auto ml-auto cursor-pointer flex-shrink-0"
          src={Images.ArrowDownCircle}
          alt="Navigate"
        />
      </div>
    );
  };

  return (
    <div className="flex-1 h-[240px] max-h-[240px] md:h-[420px] md:max-h-[420px] flex flex-col rounded-lg overflow-hidden border border-gray-500">
      <div className="w-full h-[58px] bg-gray-500 border-b border-gray-600 px-4 flex items-center flex-none">
        <div className="flex text-white text-base font-bold items-center">
          <DescriptionIcon className="h-6 mr-2" />
          {activeMetaverseParcels ? activeMetaverseParcels.length : ""}{" "}
          <FormattedMessage
            id={activeMetaverseParcels ? (activeMetaverseParcels.length !== 1 ? "Locations" : "Location") : "Locations"}
          />
        </div>
      </div>
      <div className="flex-1 bg-gray-600 w-full flex flex-col styled-overflow overflow-auto">
        {activeMetaverseParcels === null ? (
          <div className="m-auto text-gray-200 py-10 flex flex-col">
            <img src={MADColorLogo} className="h-20 w-20 mx-auto" />
            <div className="text-white font-nexa text-sm text-center mt-2 px-10 lg:px-20">
              It looks like we had trouble loading our list of locations. Please check your internet connection and try
              again.
            </div>
          </div>
        ) : activeMetaverseParcels ? (
          activeMetaverseParcels.length > 0 ? (
            // @ts-ignore
            activeMetaverseParcels[0].address === undefined ? (
              // @ts-ignore
              activeMetaverseParcels.map(renderLocationDCL)
            ) : (
              // @ts-ignore
              activeMetaverseParcels.map(renderLocationCV)
            )
          ) : (
            <div className="m-auto text-gray-200 py-10">No bids.</div>
          )
        ) : (
          TempLocations.map(renderLocationSkeleton)
        )}
      </div>
    </div>
  );
};

export default AuctionLocations;
