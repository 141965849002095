import React, { createContext, useState } from "react";

interface ModalState {
  componentId: string;
  props?: any;
  internallyManagedProps?: {
    [key: string]: any;
  };
  active: boolean;
  showModal: (componentId: string, props: any) => void;
  hideModal: () => void;
}

export const ModalContext = createContext<ModalState>({
  // @ts-ignore
  componentId: null,
  props: null,
  active: false,
  internallyManagedProps: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showModal: (componentId: string, props: any) => null,
  hideModal: () => null
});

const ModalProvider = (ownProps: any) => {
  const [component, setComponent] = useState<string>("");
  const [props, setProps] = useState<any>({});
  const [active, setActive] = useState<boolean>(false);

  const handleShowModal = (componentId: string, childProps = null) => {
    setComponent(componentId);
    setProps(childProps);
    setActive(true);
  };

  const handleHideModal = () => {
    setComponent("");
    setProps({});
    setActive(false);
  };

  return (
    <ModalContext.Provider
      value={{
        componentId: component,
        props,
        active,
        showModal: handleShowModal,
        hideModal: handleHideModal
      }}
    >
      {ownProps.children}
    </ModalContext.Provider>
  );
};

export const ModalConsumer = ModalContext.Consumer;

export default ModalProvider;
