import { createSlice } from "@reduxjs/toolkit";

export interface IAlert {
  id: string;
  content?: string;
  message?: string;
  title?: string;
  duration?: number;
  active?: boolean;
  contentProps?: any;
}

export interface AlertState {
  contentNotifications: IAlert[];
  commonNotifications: IAlert[];
  newNotification: IAlert | null;
  vertical: string;
  horizontal: string;
}

const AlertSlice = createSlice({
  name: "alert",
  initialState: {
    contentNotifications: [],
    commonNotifications: [],
    newNotification: null,
    vertical: "bottom",
    horizontal: "right"
  } as AlertState,
  reducers: {
    addNotification(state, action) {
      if (action.payload.content) {
        state.contentNotifications = [action.payload, ...state.contentNotifications];
      } else {
        state.commonNotifications = [
          {
            ...action.payload,
            id: `${action.payload.id}-${Date.now().toString()}`
          },
          ...state.commonNotifications
        ];
      }
    },
    updateNotification(state, action) {
      const notificationId = action.payload.id;
      const contentNotifications = [...state.contentNotifications];
      const commonNotifications = [...state.commonNotifications];
      const indexToEditContent = contentNotifications.findIndex((notify: IAlert) => notify.id === notificationId);
      const indexToEditCommon = commonNotifications.findIndex((notify: IAlert) => notify.id === notificationId);
      if (indexToEditContent > -1) {
        contentNotifications[indexToEditContent] = { ...contentNotifications[indexToEditContent], ...action.payload };
        state.contentNotifications = contentNotifications;
      }
      if (indexToEditCommon > -1) {
        commonNotifications[indexToEditCommon] = { ...commonNotifications[indexToEditCommon], ...action.payload };
        state.commonNotifications = commonNotifications;
      }
    },
    removeNotification(state, action) {
      const contentNotifications = [...state.contentNotifications];
      const commonNotifications = [...state.commonNotifications];
      const indexToEditContent = contentNotifications.findIndex((notify: IAlert) => notify.id === action.payload.id);
      const indexToEditCommon = commonNotifications.findIndex((notify: IAlert) => notify.id === action.payload.id);
      contentNotifications.splice(indexToEditContent, 1);
      commonNotifications.splice(indexToEditCommon, 1);
      state.contentNotifications = contentNotifications;
      state.commonNotifications = commonNotifications;
      if (state.newNotification && action.payload.id === state.newNotification.id) {
        state.newNotification = null;
      }
    },
    setNotificationSetting(state, action) {
      state.vertical = action.payload.vertical || "bottom";
      state.horizontal = action.payload.horizontal || "right";
    },
    setInitialize(state) {
      state.contentNotifications = [];
      state.commonNotifications = [];
      state.newNotification = null;
    }
  }
});

export const { addNotification, updateNotification, removeNotification, setNotificationSetting, setInitialize } =
  AlertSlice.actions;

export const AlertReducer = AlertSlice.reducer;
