// @param production
// false: Testnet support for Goerli + testnet endpoints and testnet contract addresses.
// true: Mainnet support ONLY. Use real endpoints and real contract addresses.
const production = process.env.REACT_APP_SENTRY_DSN ? true : false;

const GOERLI_PROVIDER_URL = "https://goerli.infura.io/v3/59e38e7a0505462d810e0ac606665fd1";
export const MAINNET_PROVIDER_URL = "https://eth-mainnet.alchemyapi.io/v2/xTrIufa8bBMhQmEc14yrjDOV0yKIka9r";

const PROFILE_ENDPOINT_MAINNET = "https://api.mad.xyz";
const PROFILE_ENDPOINT_TESTNET = "https://dev.mad.xyz";

const MAD_AUCTION_CONTRACT_ADDRESS_MAINNET = "0x5289de6F87cE2D6C44179Dc48A622476BD841E2e";
const MAD_AUCTION_CONTRACT_ADDRESS_TESTNET = "0xa2EeFB2b3A9935585Fa1a62D3aa9D90977bA29Ab";
export const DCL_ESTATE_CONTRACT_ADDRESS = "0x959e104E1a4dB6317fA58F8295F586e1A978c297";
export const DCL_PARCEL_CONTRACT_ADDRESS = "0xF87E31492Faf9A91B02Ee0dEAAd50d51d56D5d4d";
export const CV_CONTRACT_ADDRESS = "0x79986aF15539de2db9A5086382daEdA917A9CF0C";
export const RADICAL_MARKET_CONTRACT_ADDRESS = "0xFf834467d91c44862284A5883a9f841d7a52eB01";

const MAD_AUCTION_GRAPHQL_ENDPOINT_MAINNET =
  "https://api.thegraph.com/subgraphs/name/metaverse-advertisement-dao/mad-auction-main";
const MAD_AUCTION_GRAPHQL_ENDPOINT_TESTNET =
  "https://api.thegraph.com/subgraphs/name/metaverse-advertisement-dao/mad-ads-auction";

export const MAD_CONTRACT_ADMIN_ADDRESS = "0xf57BF72D0c40c78360bAFD5C757FAF4707F440a9";
export const MAD_ADDRESS = "0x2B7F0615979eddDeAF83BFEC23F66fb5a6D177A4";
export const MAD_DELEGATION_ADDRESS = "0x9D4DdDbe95192Ad8bE81ee88E021c9Eaf575BAf8";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const PROVIDER_URL = production ? MAINNET_PROVIDER_URL : GOERLI_PROVIDER_URL;
export const MAD_AUCTION_CONTRACT_ADDRESS = production
  ? MAD_AUCTION_CONTRACT_ADDRESS_MAINNET
  : MAD_AUCTION_CONTRACT_ADDRESS_TESTNET;
export const MAD_AUCTION_GRAPHQL_ENDPOINT = production
  ? MAD_AUCTION_GRAPHQL_ENDPOINT_MAINNET
  : MAD_AUCTION_GRAPHQL_ENDPOINT_TESTNET;
export const INJECTED_SUPPORTED_CHAIN_IDS = production ? [1] : [1, 5];
export const PROFILE_ENDPOINT = production ? PROFILE_ENDPOINT_MAINNET : PROFILE_ENDPOINT_TESTNET;

export {};
