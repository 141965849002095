import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import LocationIcon from "../assets/location.svg";
import Button from "./ui/Button";

interface ParcelProps {
  cancelCallback: (e?: any) => void;
  claimProfitCallback: (e?: any) => void;
  leaseCallback: (e?: any) => void;
  metaverseId: number;
  layerName: string;
  i: number;
  [key: string]: any;
}

const Parcel = (props: ParcelProps) => {
  const {
    parcel,
    i,
    leaseCallback,
    cancelCallback
    // claimProfitCallback,
  } = props;

  const handleCancel = useCallback(() => {
    cancelCallback(parcel);
  }, [parcel, cancelCallback]);

  // const handleClaim = useCallback(() => {
  //   claimProfitCallback(parcel);
  // }, [parcel, claimProfitCallback]);

  const handleStake = useCallback(() => {
    leaseCallback(parcel);
  }, [parcel, leaseCallback]);

  return (
    <div key={`op-${i}`} className="">
      <div className="h-40 overflow-hidden rounded-t-md">
        <img onDragStart={(e) => e.preventDefault()} className="mx-auto w-full -m-20" src={parcel.image} alt="" />
      </div>
      <div className="h-1/2 w-full px-4 py-4 font-commuter">
        <div className="w-full flex flex-col">
          <div className="flex justify-between items-center">
            <div className="text-gray-100 text-sm font-nexa font-bold">
              {parcel?.name
                ? parcel.name
                : `Parcel (${parcel?.parcel ? parcel.parcel.x : "--"}, ${parcel?.parcel ? parcel.parcel.y : "--"})`}
            </div>
          </div>
          <div className="w-full mt-auto">
            <div className="flex flex-row mt-1">
              <div className="bg-gray-45 mt-auto px-2 py-1 rounded-md flex flex-row flex-shrink-0">
                {parcel.type === "estate" ? (
                  <div className="text-xs my-auto">
                    {`${parcel.parcels.length} `}
                    <FormattedMessage id="Parcels" />
                  </div>
                ) : (
                  <>
                    <img className="h-4 w-4 my-auto" src={LocationIcon} alt="" />
                    <div className="text-xs ml-1 my-auto">{`${parcel.parcel ? parcel.parcel.x : "--"}, ${
                      parcel.parcel ? parcel.parcel.y : "--"
                    }`}</div>
                  </>
                )}
              </div>
              {parcel.updateOperator ? (
                <>
                  <div className="ml-auto">
                    <Button
                      onClick={handleCancel}
                      text={<FormattedMessage id="Cancel" />}
                      light={true}
                      className="bg-gray-100 text-gray-500 w-max hover:bg-white ml-auto px-4 font-bold font-nexa"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="ml-auto">
                    <Button
                      onClick={handleStake}
                      text={<FormattedMessage id="Lease" />}
                      light={true}
                      color="pink"
                      className="bg-green-100 text-gray-500 w-max hover:bg-gray-100 ml-auto px-4 font-bold font-nexa"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Parcel);
