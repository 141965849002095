import { useEffect, useState } from "react";
import { ReactComponent as OfferIcon } from "../../assets/offer.svg";
import { ReactComponent as EthLogo } from "../../assets/eth_logo.svg";
import MADColorLogo from "../../assets/logo_sketched.gif";
import { getCurrentEthPriceInUsd } from "helpers/chainlink";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ethers } from "ethers";
import { FormattedMessage } from "react-intl";

const TempBids = new Array(8).fill(1);

const renderBidSkeleton = (item: any, i: number) => {
  return (
    <div
      className="animate-pulse border-b border-gray-500 w-full h-[62px] min-h-[62px]"
      key={`location-skeleton-${item}-${i}`}
    >
      <div className="w-full h-full flex items-center px-4">
        <div className="w-6/12 text-left">
          <div className="h-4 w-2/3 bg-gray-100" />
        </div>
        <div className="w-6/12 text-left">
          <div className="h-4 w-2/3 bg-gray-100" />
        </div>
      </div>
    </div>
  );
};

const AuctionBids = () => {
  const [ethUsd, handleEthUsd] = useState(0);

  const { auctionBids, metaverse } = useSelector((state: RootState) => state.auction);

  useEffect(() => {
    (async () => {
      handleEthUsd(await getCurrentEthPriceInUsd());
    })();
  }, []);

  const renderBids = (item: BidEvent, i: number) => {
    return (
      <div className="border-b border-gray-500 w-full h-[62px] min-h-[62px]" key={`bid-${item}-${i}`}>
        <div className="w-full h-full flex items-center px-4 text-gray-100">
          <div className="flex w-6/12 text-left items-center">
            <EthLogo className="h-6" />{" "}
            {`${ethers.utils.formatEther(item.amount)} ($${
              ethUsd > 0 ? (parseFloat(ethers.utils.formatEther(item.amount)) * ethUsd).toFixed(2) : "--"
            })`}
          </div>
          <div className="w-6/12 flex text-left overflow-none">
            <div className="w-full truncate" data-tip={item.from}>
              {item.from}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 h-[240px] max-h-[240px] md:h-[420px] md:max-h-[420px] flex flex-col rounded-lg overflow-hidden border border-gray-500">
      <ReactTooltip
        className={`rounded-lg text-xs text-white px-2 py-0 bg-gray-300`}
        delayShow={500}
        delayHide={100}
        offset={{ top: -5 }}
        arrowColor="rgba(0,0,0,0)"
        place="top"
        type="dark"
        effect="solid"
      />
      <div className="w-full h-[58px] bg-gray-500 border-b border-gray-600 px-4 flex items-center flex-none">
        <div className="flex text-white text-base font-bold items-center">
          <OfferIcon className="h-6 mr-2" />
          <FormattedMessage id="Current Bids" />
        </div>
      </div>
      <div className="flex-1 bg-gray-600 w-full flex flex-col styled-overflow overflow-auto">
        <div className="flex items-center h-[48px] min-h-[48px] px-4 bg-gray-500 border-b border-gray-600">
          <div className="w-6/12 text-left">
            <div className="h-4 text-sm font-bold text-white">
              <FormattedMessage id="Price" />
            </div>
          </div>
          <div className="w-6/12 text-left">
            <div className="h-4 text-sm font-bold text-white">
              <FormattedMessage id="From" />
            </div>
          </div>
        </div>
        {auctionBids ? (
          auctionBids.has(metaverse) ? (
            // @ts-ignore
            auctionBids.get(metaverse).length > 0 ? (
              // @ts-ignore
              auctionBids.get(metaverse).map(renderBids)
            ) : (
              <div className="m-auto text-gray-200 py-10">No bids.</div>
            )
          ) : (
            <div className="m-auto text-gray-200 py-10 flex flex-col">
              <img src={MADColorLogo} className="h-20 w-20 mx-auto" />
              <div className="text-white font-nexa text-sm text-center mt-2 px-10 lg:px-20">
                <FormattedMessage id="LoadingBidsError" /> <FormattedMessage id="CheckInternetConnection" />
              </div>
            </div>
          )
        ) : (
          TempBids.map(renderBidSkeleton)
        )}
      </div>
    </div>
  );
};

export default AuctionBids;
