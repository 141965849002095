/* eslint-disable @typescript-eslint/no-var-requires */
const DarkForest = require("../../assets/darkforest.mp4").default;
const JayChou = require("../../assets/jaychou.mp4").default;
const ApeNFT = require("../../assets/apenft.mp4").default;
const MoleDAO = require("../../assets/moleDAO.mp4").default;
const Orangedao = require("../../assets/orangedao.mp4").default;
const F1showroom = require("../../assets/f1.mp4").default;
const Futurecraft = require("../../assets/futurecraft.mp4").default;

const ex = {
  DarkForest,
  JayChou,
  ApeNFT,
  MoleDAO,
  Orangedao,
  F1showroom,
  Futurecraft
};

export default ex;
