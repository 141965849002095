import React from "react";
import { MODAL_ANIMATIONS } from "helpers/constants";

export const DrawerWrapper = (props: any) => {
  const handleHide = React.useCallback(() => {
    props?.onClose();
  }, [props]);

  const handleContentClickable = React.useCallback((e) => {
    e.stopPropagation();
  }, []);

  const activeClass = props?.active ? "active" : "";
  const hideClass = props?.isClosing ? "hide" : "";

  return (
    <div className={`drawer ${activeClass} ${hideClass}`}>
      <div className="drawer-overlay" onClick={handleHide}>
        <div className="drawer-content" onClick={handleContentClickable}>
          {props?.showCloseButton && (
            <div className="absolute right-4 top-4 z-10 cursor-pointer" onClick={handleHide}>
              <span className="text-xl text-white ">&#10006;</span>
            </div>
          )}
          {props.children}
        </div>
      </div>
    </div>
  );
};

export const ModalWrapper = (props: any) => {
  const animation = React.useRef("");
  const handleHide = React.useCallback(() => {
    if (!props?.hideOverlay) {
      props?.onClose();
    }
  }, [props]);

  const handleClose = React.useCallback(() => {
    props?.onClose();
  }, [props]);

  const handleContentClickable = React.useCallback((e) => {
    e.stopPropagation();
  }, []);

  React.useEffect(() => {
    if (props?.animation && props?.animation !== animation.current) {
      animation.current = props?.animation;
    } else if (!props?.animation && props?.active && animation.current !== MODAL_ANIMATIONS.unfold) {
      animation.current = MODAL_ANIMATIONS.unfold;
    } else if (!props?.animation && !props?.active && animation.current !== "") {
      setTimeout(() => {
        animation.current = "";
      }, 500);
    }
  }, [props?.animation, props?.active]);

  const overlayHidden = props?.hideOverlay ? "hidden" : "";
  const modalActiveClass = props?.active ? "active" : "";
  const modalHideClass = props?.isClosing ? "hide" : "";
  const animationClass = props?.animation
    ? props.animation
    : props?.active
    ? MODAL_ANIMATIONS.unfold
    : animation.current;

  return (
    <div className={`modal ${animationClass} ${modalActiveClass} ${modalHideClass}`}>
      <div
        className={`modal-overlay ${overlayHidden} ${props?.horizontal || ""} ${props?.vertical || ""}`}
        onClick={handleHide}
      >
        <div className="modal-content" onClick={handleContentClickable}>
          {props?.showCloseButton && (
            <div className="absolute right-4 top-4 z-10 cursor-pointer" onClick={handleClose}>
              <span className="text-xl text-white ">&#10006;</span>
            </div>
          )}
          {props.children}
        </div>
      </div>
    </div>
  );
};
