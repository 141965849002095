import { useWeb3React } from "@web3-react/core";
import { truncate } from "helpers/misc";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { RootState } from "store";
import { ReactComponent as ProfileIcon } from "../../assets/profile_filled.svg";
import { ReactComponent as EditIcon } from "../../assets/edit_filled.svg";
import { ReactComponent as EthLogo } from "../../assets/eth_logo.svg";
import Identicon from "../common/IdentIcon";
import { useEffect, useState } from "react";
import { getCurrentEthPriceInUsd } from "helpers/chainlink";
import { Contract } from "ethers";
import { setAccountBalance, setOpenLoginModal } from "store/userSlice";
import * as EmailValidator from "email-validator";
import Fade from "react-reveal/Fade";
import { setUserEmail } from "helpers/siwe";
import { addNotify } from "store/notificationMiddleware";

interface ProfileAccountDetailsProps {
  madAuctionInstance: Contract | null;
  loading: boolean;
}

function ProfileAccountDetails(props: ProfileAccountDetailsProps) {
  const { madAuctionInstance, loading } = props;

  const [emailError, handleEmailError] = useState(false);
  const [emailErrorMsg, handleEmailErrorMsg] = useState("");
  const [email, handleEmail] = useState("");
  const [editEmail, handleEditEmail] = useState(false);
  const [ethToUsd, handleEthToUsd] = useState(0);
  const [withdrawing, handleWithdrawing] = useState(false);
  const [savingEmail, handleSavingEmail] = useState(false);
  const { ethAlias, ethAvatar, accountBalance, nonceObj } = useSelector((state: RootState) => state.user);
  const { account } = useWeb3React();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      handleEthToUsd(await getCurrentEthPriceInUsd());
    })();
  }, []);

  useEffect(() => {
    if (!editEmail) {
      if (account && nonceObj[account]?.emailSet && nonceObj[account]?.email) {
        // @ts-ignore
        handleEmail(nonceObj[account].email);
      }
    }
  }, [account, nonceObj]);

  const handleSaveEmail = async () => {
    let shouldContinue = true;
    if (email.length === 0) {
      handleEmailErrorMsg("Please enter an email.");
      handleEmailError(true);
      shouldContinue = false;
    }
    if (email.length > 0 && !EmailValidator.validate(email)) {
      handleEmailErrorMsg("Invalid email address.");
      handleEmailError(true);
      shouldContinue = false;
    }

    if (shouldContinue && !savingEmail) {
      handleSavingEmail(true);
      if (account && email.length > 0) {
        await setUserEmail(dispatch, nonceObj, account, email);
      }
      handleEditEmail(false);
      handleSavingEmail(false);
    }
  };

  const withdraw = async () => {
    if (withdrawing) {
      return;
    }

    handleWithdrawing(true);
    if (madAuctionInstance) {
      try {
        await madAuctionInstance
          .withdraw()
          .then(async (tx: any) => {
            await tx.wait(1).then(() => {
              dispatch(setAccountBalance("0"));
            });
          })
          .catch((err: any) => {
            addNotify({
              message: err?.message ? err.message : err,
              type: "error"
            });
          });
      } catch (err: any) {
        addNotify({
          message: err?.message ? err.message : err,
          type: "error"
        });
      }
    }
    handleWithdrawing(false);
  };

  const renderConnectWallet = () => {
    return (
      <div className="rounded-lg w-full bg-gray-600 overflow-hidden">
        <div className="w-full h-[58px] bg-gray-500 border-b border-gray-600 px-4 flex items-center flex-none">
          <div className="flex text-gray-100 text-base font-bold items-center">
            <ProfileIcon className="h-6 w-auto mr-2 fill-gray-100" />
            Account Details
          </div>
        </div>
        <div className="m-auto text-gray-100 p-8 flex flex-col min-h-72">
          <div className="m-auto">
            <p>
              Please{" "}
              <span
                className="text-yellow-300 hover:text-yellow-200 cursor-pointer underline"
                onClick={() => {
                  dispatch(setOpenLoginModal(true));
                }}
              >
                connect your wallet
              </span>{" "}
              to view your profile.
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderSkeleton = () => {
    return (
      <div className="rounded-lg w-full bg-gray-600 overflow-hidden">
        <div className="w-full h-[58px] bg-gray-500 border-b border-gray-600 px-4 flex items-center flex-none">
          <div className="flex text-gray-100 text-base font-bold items-center">
            <ProfileIcon className="h-6 w-auto mr-2 fill-gray-100" />
            Account Details
          </div>
        </div>
        <div className="m-auto text-gray-200 p-8 flex flex-col min-h-72 animate-pulse">
          <div className="flex">
            <div className="bg-white w-11 h-11 rounded-full" />
            <div className="bg-white my-auto ml-4 h-5 w-36 rounded" />
          </div>
          <div className="flex flex-col text-gray-100 mt-8">
            <div className="bg-white h-5 w-32 rounded mb-2" />
            <div className="bg-white h-4 w-full md:w-96 mt-4 rounded" />
            <div className="bg-white h-4 w-full md:hidden mt-4 rounded" />
          </div>
          <div className="flex flex-col sm:flex-row flex-wrap text-gray-100 mt-1">
            <div className="flex flex-col mt-8 mr-4 grow">
              <div className="bg-white h-5 w-32 rounded mt-1" />
              <div className="flex flex-row mt-2">
                <div className="bg-white rounded h-4 w-40 mt-1" />
                <div className="bg-white rounded h-4 w-4 mt-1 ml-2" />
              </div>
            </div>
            <div className="flex flex-col mt-8 grow">
              <div className="bg-white h-5 w-32 rounded mt-1" />
              <div className="flex flex-row mt-2">
                <div className="bg-white h-6 w-6 rounded" />
                <div className="bg-white rounded h-4 w-20 mt-1 ml-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!loading && !account) {
    return renderConnectWallet();
  }

  if (loading || !account) {
    return renderSkeleton();
  }

  return (
    <>
      <div className="rounded-lg w-full bg-gray-600 overflow-hidden">
        <ReactTooltip
          className={`rounded-lg text-xs text-white px-2 py-0 bg-gray-300`}
          delayShow={500}
          delayHide={100}
          offset={{ top: -5 }}
          arrowColor="rgba(0,0,0,0)"
          place="top"
          type="dark"
          effect="solid"
        />
        <div className="w-full h-[58px] bg-gray-500 border-b border-gray-600 px-4 flex items-center flex-none">
          <div className="flex text-gray-100 text-base font-bold items-center">
            <ProfileIcon className="h-6 w-auto mr-2 fill-gray-100" />
            Account Details
          </div>
        </div>
        <div className="m-auto text-gray-200 p-8 flex flex-col min-h-72">
          <div className="flex">
            <div className="identicon hover:bg-black border mr-4 border-green-200 flex rounded-full">
              {ethAvatar ? (
                <img className="w-11 h-11 rounded-full object-cover" src={ethAvatar} alt="" />
              ) : (
                <div className="p-2">
                  <Identicon
                    string={account ? account : ""}
                    size={25}
                    palette={["#D7EEFF", "#eef2ff", "#991A1A", "#FFDECC", "#E98234", "#D98234", "#EE9F63", "#464648"]}
                  />
                </div>
              )}
            </div>
            <div className="my-auto font-nexa font-bold text-gray-100">
              {ethAlias ? ethAlias : truncate(account ? account : "", 7, 4, "...")}
            </div>
          </div>
          <div className="flex flex-col text-gray-100 mt-8">
            <div className="font-semibold">Wallet Address</div>
            <div className="break-words mt-2">
              {account}
              <CopyToClipboard text={account ? account : ""}>
                <span className="inline-flex self-center top-0.5 relative ml-2 cursor-pointer">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8 4.10352V16.1035C8 16.6339 8.21071 17.1427 8.58579 17.5177C8.96086 17.8928 9.46957 18.1035 10 18.1035H18C18.5304 18.1035 19.0391 17.8928 19.4142 17.5177C19.7893 17.1427 20 16.6339 20 16.1035V7.34552C20 7.07908 19.9467 6.81533 19.8433 6.56976C19.7399 6.32419 19.5885 6.10176 19.398 5.91552L16.083 2.67352C15.7094 2.30817 15.2076 2.10358 14.685 2.10352H10C9.46957 2.10352 8.96086 2.31423 8.58579 2.6893C8.21071 3.06437 8 3.57308 8 4.10352V4.10352Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 18.1035V20.1035C16 20.6339 15.7893 21.1427 15.4142 21.5177C15.0391 21.8928 14.5304 22.1035 14 22.1035H6C5.46957 22.1035 4.96086 21.8928 4.58579 21.5177C4.21071 21.1427 4 20.6339 4 20.1035V9.10352C4 8.57308 4.21071 8.06437 4.58579 7.6893C4.96086 7.31423 5.46957 7.10352 6 7.10352H8"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </CopyToClipboard>
            </div>
          </div>
          <div className="flex flex-col xs:flex-row flex-wrap text-gray-100">
            <div className="flex flex-col mt-8 xs:mr-4 grow">
              <div className="font-semibold">Email Address</div>
              <div className="flex flex-col mt-2">
                {editEmail ? (
                  <>
                    <div className="flex flex-col xs:flex-row w-full">
                      <div className="z-0 bg-gray-600 text-gray-200 border rounded-md border-gray-300 h-10 flex items-center flex-grow">
                        <input
                          type="text"
                          placeholder="Enter your email address"
                          value={email}
                          disabled={savingEmail}
                          onChange={(e: any) => {
                            if (savingEmail) {
                              return;
                            }
                            handleEmail(e.target.value);
                            if (emailError) {
                              handleEmailError(false);
                            }
                          }}
                          className="bg-transparent h-full rounded-md text-white placeholder-gray-200 placeholder-opacity-100 bg-gray-600 w-full px-3 focus:outline-none"
                        />
                      </div>
                      <div
                        className={`rounded h-8 xs:h-full ${
                          savingEmail ? "bg-yellow-200" : "bg-yellow-300"
                        } hover:bg-yellow-200 cursor-pointer text-gray-300 font-bold select-none flex justify-center items-center px-6 mt-4 xs:mt-0 xs:ml-2`}
                        onClick={() => {
                          handleSaveEmail();
                        }}
                      >
                        {savingEmail ? "Saving..." : "Save"}
                      </div>
                    </div>
                    <Fade top collapse when={emailError}>
                      <div className="mt-2 xs:mt-1 text-red-200 flex" style={{ display: "block", minHeight: 24 }}>
                        {emailErrorMsg}
                      </div>
                    </Fade>
                  </>
                ) : (
                  <div className="flex flex-row">
                    <div className={`${account && nonceObj[account]?.emailSet ? "block" : "hidden"}`}>
                      {account && nonceObj[account]?.emailSet
                        ? nonceObj[account]?.email
                          ? nonceObj[account]?.email
                          : "--"
                        : ""}
                    </div>
                    <div
                      className={`${account && !nonceObj[account]?.emailSet ? "block" : "hidden"}${
                        account && nonceObj[account]?.emailSet === undefined
                          ? ""
                          : " text-yellow-400 hover:text-yellow-300 cursor-pointer font-semibold"
                      }`}
                      onClick={() => handleEditEmail(true)}
                    >
                      {nonceObj[account]?.emailSet === undefined
                        ? "Please Sign Signature Request"
                        : "+ Add email address"}
                    </div>
                    <EditIcon
                      className={`${
                        account && nonceObj[account]?.emailSet && nonceObj[account]?.email ? "block" : "hidden"
                      } fill-gray-100 h-6 cursor-pointer`}
                      onClick={() => handleEditEmail(true)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col mt-8 grow">
              <div className="font-semibold">Current Balance</div>
              <div className="flex flex-row mt-2">
                <EthLogo className="h-6 flex-shrink-0" />
                <div className="font-semibold">{accountBalance}</div>
                <div className="font-light text-xs font-nexa mt-1 ml-1">{`($${(accountBalance * ethToUsd).toFixed(
                  2
                )})`}</div>
                {accountBalance > 0 ? (
                  <div
                    className={`ml-3 cursor-pointer font-semibold select-none ${
                      withdrawing ? "text-gray-200 hover:text-gray-200" : "text-yellow-400 hover:text-yellow-300"
                    }`}
                    onClick={withdraw}
                  >
                    {withdrawing ? "Withdrawing..." : "Withdraw"}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileAccountDetails;
