import { SVGProps } from "react";

export const GoogleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M40.1672 16.2861H38.5562V16.2031H20.5562V24.2031H31.8592C30.2102 28.8601 25.7792 32.2031 20.5562 32.2031C13.9292 32.2031 8.55615 26.8301 8.55615 20.2031C8.55615 13.5761 13.9292 8.20312 20.5562 8.20312C23.6152 8.20312 26.3982 9.35712 28.5172 11.2421L34.1742 5.58512C30.6022 2.25612 25.8242 0.203125 20.5562 0.203125C9.51115 0.203125 0.556152 9.15812 0.556152 20.2031C0.556152 31.2481 9.51115 40.2031 20.5562 40.2031C31.6012 40.2031 40.5562 31.2481 40.5562 20.2031C40.5562 18.8621 40.4182 17.5531 40.1672 16.2861Z"
        fill="#FFC107"
      />
      <path
        d="M2.86206 10.8941L9.43306 15.7131C11.2111 11.3111 15.5171 8.20312 20.5561 8.20312C23.6151 8.20312 26.3981 9.35712 28.5171 11.2421L34.1741 5.58512C30.6021 2.25612 25.8241 0.203125 20.5561 0.203125C12.8741 0.203125 6.21206 4.54012 2.86206 10.8941Z"
        fill="#FF3D00"
      />
      <path
        d="M20.5562 40.2028C25.7222 40.2028 30.4162 38.2258 33.9652 35.0108L27.7752 29.7728C25.6998 31.3512 23.1637 32.2049 20.5562 32.2028C15.3542 32.2028 10.9372 28.8858 9.27322 24.2568L2.75122 29.2818C6.06122 35.7588 12.7832 40.2028 20.5562 40.2028Z"
        fill="#4CAF50"
      />
      <path
        d="M40.1672 16.2861H38.5562V16.2031H20.5562V24.2031H31.8592C31.0704 26.4195 29.6495 28.3563 27.7722 29.7741L27.7752 29.7721L33.9652 35.0101C33.5272 35.4081 40.5562 30.2031 40.5562 20.2031C40.5562 18.8621 40.4182 17.5531 40.1672 16.2861Z"
        fill="#1976D2"
      />
    </svg>
  );
};
