import { useEffect, useState, useContext } from "react";
import Button from "components/ui/Button";
import { FormattedMessage } from "react-intl";
import EthIcon from "assets/eth_special.svg";
import MadImage from "assets/logo_sketched.gif";
import Fade from "react-reveal/Fade";
import * as EmailValidator from "email-validator";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ModalContext } from "context/ModalContext";
import { setCurrentBidEth } from "store/auctionSlice";
import { IAlert, removeNotification } from "store/alertSlice";
import { getCurrentEthPriceInUsd } from "helpers/chainlink";
import { getSignerActiveAction } from "helpers/auth";
import { useWeb3React } from "@web3-react/core";
import { countDecimals, decimalAdjust } from "helpers/misc";
import { MONTH_NAMES, NOTIFICATION_TYPES, MODAL_TYPES, MODAL_ANIMATIONS } from "helpers/constants";
import { addNotify } from "store/notificationMiddleware";

type PlaceBidContentProps = {
  onCancel: () => void;
  submitBid: (bid: number, email: string) => Promise<{ success: boolean; message: string }>;
  handleAllowClosing: (allow: boolean) => void;
  [key: string]: any;
};

export default function PlaceBidContent(props: PlaceBidContentProps) {
  const { showModal } = useContext(ModalContext);
  const [isSuccess, handleIsSuccess] = useState(props?.isSuccess || false);
  const [processingTx, handleProcessingTx] = useState(false);
  const [error, setError] = useState(false);
  const [emailError, handleEmailError] = useState(false);
  const [errorMsg, handleErrorMsg] = useState("");
  const [emailErrorMsg, handleEmailErrorMsg] = useState("");
  const [email, handleEmail] = useState("");
  const [ethPriceUsd, handleEthPriceUsd] = useState(0);

  const { auctionData, activeMetaverseParcels, currentBidEth, metaverse } = useSelector(
    (state: RootState) => state.auction
  );
  const { nonceObj } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const { active, account } = useWeb3React();
  const [showEmailResetInput, handleShowEmailResetInput] = useState(false);

  const { onCancel, submitBid, handleAllowClosing } = props;

  useEffect(() => {
    if (!props?.isSuccess) {
      (async () => {
        handleEthPriceUsd(await getCurrentEthPriceInUsd());
      })();
    }

    return () => {
      dispatch(setCurrentBidEth(null));
    };
  }, []);

  useEffect(() => {
    handleAllowClosing(!processingTx);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingTx]);

  const changeBid = (e: any) => {
    // Round the decimal to 3 places
    try {
      const val = parseFloat(e.target.value);
      if (countDecimals(val) > 3) {
        dispatch(setCurrentBidEth(val.toFixed(3)));
      } else {
        dispatch(setCurrentBidEth(e.target.value));
      }
    } catch (err) {
      dispatch(setCurrentBidEth(e.target.value));
    }

    if (e.target.value?.length > 0 && (isNaN(parseFloat(e.target.value)) || parseFloat(e.target.value) < 0)) {
      handleErrorMsg("Invalid bid entered.");
      setError(true);
    } else {
      // additional error checking can go here. simply update the error message
      setError(false);
      handleErrorMsg("");
    }
  };

  const handleClickDetail = () => {
    showModal(MODAL_TYPES.auction_bid, { submitBid, animation: MODAL_ANIMATIONS.unfold, isSuccess: true });
  };

  const handlePlaceBid = async () => {
    if (!getSignerActiveAction(active, dispatch)) {
      return;
    }

    const aucData = auctionData?.get(metaverse);

    if (!aucData) {
      handleErrorMsg("Unexpected error loading auction details. Please refresh your page.");
      setError(true);
    } else if (currentBidEth?.length === 0) {
      handleErrorMsg("Please enter a bid to continue.");
      setError(true);
    } else {
      // Only do something if there is no existing error
      if (!error && !emailError) {
        const notifyId = `waiting-bid`;
        try {
          handleProcessingTx(true);
          const bid = parseFloat(currentBidEth);

          let shouldContinue = true;

          if (email?.length > 0 && !EmailValidator.validate(email)) {
            handleEmailErrorMsg("You have entered an invalid email address.");
            handleEmailError(true);
            shouldContinue = false;
          }

          // Verify bid value is higher than current bid + increment
          if (bid < aucData.highestBid / 1e18 + aucData.increment / 1e18) {
            handleErrorMsg(
              `Your bid must be at least ${decimalAdjust(
                "ceil",
                aucData.highestBid / 1e18 + aucData.increment / 1e18,
                -3
              )} ETH`
            );
            setError(true);
            shouldContinue = false;
          }

          if (shouldContinue) {
            // Submit the bid
            const { success, message } = await submitBid(bid, email);
            dispatch(removeNotification({ id: notifyId } as IAlert));
            if (!success) {
              addNotify({ message, type: "error" });
            } else {
              addNotify({
                id: "success-bid",
                content: NOTIFICATION_TYPES.bid_success,
                other: {
                  onClickAction: handleClickDetail
                }
              });
              handleIsSuccess(true);
            }
          }
        } catch (err) {
          handleErrorMsg("Invalid bid entered.");
          dispatch(removeNotification({ id: notifyId } as IAlert));
          setError(true);
        }

        handleProcessingTx(false);
      }
    }
  };

  let aucData: Auction | undefined;
  if (auctionData) {
    aucData = auctionData.get(metaverse);
  }

  const renderProcessing = () => {
    return (
      <div className="font-nexa px-6 py-6 sm:py-10 sm:px-14 bg-gray-600 rounded-2xl">
        <div>
          <div className="text-center py-6">
            <img src={MadImage} alt="logo-gif" className="m-auto h-[106px]" />
          </div>
          <p className="text-gray-100 font-nexa text-xl leading-7 font-light mb-4 md:text-2xl md:leading-normal md:font-bold md:mb-8 text-center">
            <FormattedMessage id="Awaiting Transaction" />
          </p>
          <p className="text-sm md:text-xl font-light font-nexa leading-4 md:leading-8 text-gray-100 md:w-95/100 md:w-144 text-center mb-8">
            <span>Please complete the transaction on your wallet</span>
          </p>
        </div>
      </div>
    );
  };

  const renderMainContent = () => {
    return (
      <div className="px-6 py-6 sm:py-10 sm:px-14 bg-gray-600 rounded-2xl">
        <div className="hidden md:grid gap-y-8">
          <p className="font-nexa font-bold text-2xl leading-8 text-gray-100 text-center">
            <FormattedMessage id="Place My Bid" />
          </p>
          <div className="rounded-lg flex flex-wrap gap-4 md:gap-0 w-144 p-4 bg-gray-500">
            <div className="w-auto mr-auto md:mr-0 md:w-7/12 flex flex-col flex-shrink-0">
              <div className="w-full h-4 text-purple-200 text-base text-left font-bold flex-shrink-0">Duration</div>
              <div className="w-full h-4 text-left text-base text-white mt-3 flex-shrink-0">
                {aucData ? MONTH_NAMES[new Date(aucData.birth * 1000).getMonth()] : "---"}{" "}
                {aucData ? new Date(aucData.birth * 1000).getDate() : "---"} -{" "}
                {aucData ? MONTH_NAMES[new Date(aucData.deadline * 1000).getMonth()] : "---"}{" "}
                {aucData ? new Date(aucData.deadline * 1000).getDate() : "---"}
              </div>
            </div>
            <div className="w-auto md:w-5/12 flex flex-col flex-shrink-0">
              <div className="w-full h-4 text-purple-200 text-base text-left font-bold flex-shrink-0">
                <FormattedMessage id="Billboard Coverage" />
              </div>
              <div className="w-full h-4 text-left text-base text-white mt-3 flex-shrink-0">
                {`${activeMetaverseParcels ? activeMetaverseParcels.length : 0} ${
                  activeMetaverseParcels ? (activeMetaverseParcels.length !== 1 ? "Lands" : "Land") : "Lands"
                }`}
              </div>
            </div>
          </div>
          <div>
            <p className="font-nexa leading-6 text-base text-gray-100 mb-2 font-semibold">Bid:</p>
            <div className="font-nexa items-center font-light text-base leading-6 flex h-10 border rounded-md border-gray-300">
              <div className="text-gray-100 bg-gray-500 rounded-l-md h-full flex items-center justify-center flex-shrink-0 py-2 pl-2 pr-3 md:pl-4 md:pr-6">
                <img src={EthIcon} alt="eth-icon" className="mr-3 h-6" />
                ETH
              </div>
              <input
                type="text"
                placeholder={
                  aucData && aucData.highestBid.toString() === "0"
                    ? `Bid Starting At: ${(aucData.increment / 1e18).toFixed(3)}`
                    : `Current Highest Bid: ${aucData ? (aucData.highestBid / 1e18).toFixed(3) : "-.--"}`
                }
                className="bg-transparent h-full text-white placeholder-gray-200 placeholder-opacity-100 bg-gray-600 w-full pl-2 focus:outline-none flex-shrink"
                value={currentBidEth ? currentBidEth : ""}
                onChange={changeBid}
              />
              <div className="text-gray-100 bg-gray-500 rounded-r-md h-full flex items-center justify-center flex-shrink-0 py-2 pl-2 pr-3 md:pl-4 md:pr-6">
                {ethPriceUsd && !isNaN(parseFloat(currentBidEth))
                  ? `$${(ethPriceUsd * parseFloat(currentBidEth)).toFixed(2)}`
                  : "$0.00"}
              </div>
            </div>
            <Fade bottom collapse when={error}>
              <div className="text-red-200 flex" style={{ display: "block", minHeight: 24 }}>
                {errorMsg}
              </div>
            </Fade>
            <div className="mt-10">
              <div className="flex flex-row mb-2">
                <p className="font-nexa leading-6 text-base text-gray-100 font-semibold">Email (Optional):</p>
                {account && nonceObj[account]?.emailSet ? (
                  <div
                    onClick={() => {
                      handleShowEmailResetInput(!showEmailResetInput);
                    }}
                    className="select-none ml-auto font-nexa leading-6 text-base font-semibold text-yellow-300 hover:text-yellow-200 cursor-pointer"
                  >
                    Change Email
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {account && nonceObj[account]?.emailSet && !showEmailResetInput ? (
                <div className="text-gray-200 text-base font-nexa mt-4 mb-2">
                  We have an email on record from your previous bid.
                </div>
              ) : (
                <div className="z-0 bg-gray-600 text-gray-200 border rounded-md border-gray-300 h-10 flex items-center pl-2">
                  <input
                    type="text"
                    placeholder=" This is how we notify you if you win the auction."
                    value={email ? email : ""}
                    onChange={(e: any) => {
                      handleEmail(e.target.value);
                      if (emailError) {
                        handleEmailError(false);
                      }
                    }}
                    className="bg-transparent h-full text-white placeholder-gray-200 placeholder-opacity-100 bg-gray-600 w-full pl-2 focus:outline-none"
                  />
                </div>
              )}
            </div>
            <Fade bottom collapse when={emailError}>
              <div className="text-red-200 flex" style={{ display: "block", minHeight: 24 }}>
                {emailErrorMsg}
              </div>
            </Fade>
          </div>
          <div className="flex flex-col-reverse sm:flex-row sm:justify-evenly">
            <Button
              text={<FormattedMessage id="Cancel" />}
              onClick={onCancel}
              className="text-center text-gray-400 bg-gray-100 hover:bg-white font-nexa font-bold text-tiny sm:text-lg lg:text-xl w-auto px-5 py-1 sm:px-10 sm:py-2 rounded"
            />
            <Button
              text={<FormattedMessage id="Place My Bid" />}
              onClick={handlePlaceBid}
              className={`text-center font-nexa font-bold text-tiny sm:text-lg lg:text-xl w-auto px-5 py-1 sm:px-10 sm:py-2 mb-4 sm:mb-0 rounded ${
                error || emailError
                  ? "bg-yellow-100 hover:bg-yellow-200 text-gray-200"
                  : "bg-yellow-300 hover:bg-yellow-200 text-gray-400"
              }`}
            />
          </div>
        </div>
        <div className="grid gap-y-6 md:hidden">
          <p className="font-nexa font-light text-xl leading-8 text-gray-100 text-center">
            <FormattedMessage id="Place My Bid" />
          </p>
          <div className="bg-gray-500 rounded-md px-3 py-3 flex">
            <div className="w-full text-left">
              <p className="font-nexa text-sm leading-4 font-bold text-pink-30 mb-2">Duration</p>
              <p className="font-nexa text-sm leading-4 font-light text-white">
                {aucData ? MONTH_NAMES[new Date(aucData.birth * 1000).getMonth()] : "---"}{" "}
                {aucData ? new Date(aucData.birth * 1000).getDate() : "---"} -{" "}
                {aucData ? MONTH_NAMES[new Date(aucData.deadline * 1000).getMonth()] : "---"}{" "}
                {aucData ? new Date(aucData.deadline * 1000).getDate() : "---"}
              </p>
            </div>
            <div className="flex w-full">
              <div className="w-fit text-left">
                <p className="font-nexa text-sm leading-4 font-bold text-pink-30 mb-2">
                  <FormattedMessage id="Billboard Coverage" />
                </p>
                <p className="font-nexa text-sm leading-4 font-light text-white">
                  {`${activeMetaverseParcels ? activeMetaverseParcels.length : 0} ${
                    activeMetaverseParcels ? (activeMetaverseParcels.length !== 1 ? "Lands" : "Land") : "Lands"
                  }`}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="font-nexa items-center font-light text-base leading-6 flex h-10 border rounded-md border-gray-300">
              <div className="text-gray-100 bg-gray-500 rounded-l-md h-full flex items-center justify-center flex-shrink-0 py-2 pl-2 pr-3 md:pl-4 md:pr-6">
                <img src={EthIcon} alt="eth-icon" className="mr-3 h-6" />
                ETH
              </div>
              <input
                type="text"
                placeholder={
                  aucData && aucData.highestBid.toString() === "0"
                    ? `Bid Starting At: ${(aucData.increment / 1e18).toFixed(3)}`
                    : `Current Highest Bid: ${aucData ? (aucData.highestBid / 1e18).toFixed(3) : "-.--"}`
                }
                className="bg-transparent h-full text-white placeholder-gray-200 placeholder-opacity-100 bg-gray-600 w-full pl-2 focus:outline-none flex-shrink"
                value={currentBidEth}
                onChange={changeBid}
              />
              <div className="text-gray-100 bg-gray-500 rounded-r-md h-full flex items-center justify-center flex-shrink-0 py-2 pl-2 pr-3 md:pl-4 md:pr-6">
                {ethPriceUsd && !isNaN(parseFloat(currentBidEth))
                  ? `$${(ethPriceUsd * parseFloat(currentBidEth)).toFixed(2)}`
                  : "$0.00"}
              </div>
            </div>
            <Fade bottom collapse when={error}>
              <div className="text-red-200 flex" style={{ display: "block", minHeight: 24 }}>
                {errorMsg}
              </div>
            </Fade>
            <div className="mt-8 flex flex-row mb-2">
              <p className="font-nexa leading-6 text-base text-gray-100 font-semibold">Email (Optional):</p>
              {account && nonceObj[account]?.emailSet ? (
                <div
                  onClick={() => {
                    handleShowEmailResetInput(!showEmailResetInput);
                  }}
                  className="select-none ml-auto font-nexa leading-6 text-base font-semibold text-yellow-300 hover:text-yellow-200 cursor-pointer"
                >
                  Change Email
                </div>
              ) : (
                <></>
              )}
            </div>
            {account && nonceObj[account]?.emailSet && !showEmailResetInput ? (
              <div className="text-gray-200 text-base font-nexa mt-4 mb-2">
                We have an email on record from your previous bid.
              </div>
            ) : (
              <div className="z-0 bg-gray-600 text-gray-200 border rounded-md border-gray-300 h-10 flex items-center pl-2">
                <input
                  type="text"
                  placeholder=" This is how we notify you if you win the auction."
                  value={email}
                  onChange={(e: any) => {
                    handleEmail(e.target.value);
                    if (emailError) {
                      handleEmailError(false);
                    }
                  }}
                  className="bg-transparent h-full text-white placeholder-gray-200 placeholder-opacity-100 bg-gray-600 w-full pl-2 focus:outline-none"
                />
              </div>
            )}
          </div>
          <Fade bottom collapse when={error}>
            <div className="text-red-200 flex" style={{ display: "block", minHeight: 24 }}>
              {emailErrorMsg}
            </div>
          </Fade>
          <div className="flex flex-col-reverse">
            <Button
              text={<FormattedMessage id="Place My Bid" />}
              onClick={handlePlaceBid}
              className="text-center text-gray-500 bg-yellow-400 hover:bg-yellow-300 font-nexa font-bold text-base leading-7 w-full px-10 py-2 mb-4 rounded"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <div className="px-6 py-6 sm:py-10 sm:px-14 bg-gray-600 rounded-2xl">
        <div>
          <p className="text-gray-100 font-nexa text-xl leading-7 font-light md:text-2xl md:leading-normal md:font-bold md:mb-8 text-center">
            <FormattedMessage id="Bid Successful" />
          </p>
          <p className="text-sm md:text-xl font-light font-nexa leading-4 md:leading-8 text-gray-100 md:w-95/100 md:w-144 text-center mb-8">
            <span>
              Congratulations! Your bid for ads in {`${metaverse ? "CryptoVoxels" : "Decentraland"}`} has been
              successfully processed. Should you win the auction, you will receive instructions on how to set the ad
              poster through our website and by email.
            </span>
          </p>
          <div className="flex justify-center">
            <Button
              text={<FormattedMessage id="Complete My Bid" />}
              className="text-center text-gray-500 bg-yellow-400 font-nexa font-bold text-lg w-full md:w-auto hover:bg-yellow-200 px-10 py-2 mb-4 mb-0 rounded"
              borderColor="none"
              onClick={onCancel}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="transparent">
      <div className="relative border bg-gray-bg-dark border-gray-300 rounded-2xl">
        {processingTx ? renderProcessing() : isSuccess ? renderSuccess() : renderMainContent()}
      </div>
    </div>
  );
}
