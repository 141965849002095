import { useWeb3React } from "@web3-react/core";
import Button from "components/ui/Button";
import { stringEqualsIgnoreCase } from "helpers/misc";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

interface BidSuccessfulContentProps {
  onCancel: () => void;
  landData: Land | null | undefined;
  parcelName: string;
  handleOpenSetBuildModal: () => void;
}

export function BidSuccessfulContent(props: BidSuccessfulContentProps) {
  const { account } = useWeb3React();
  const navigate = useNavigate();

  const { onCancel, landData, parcelName, handleOpenSetBuildModal } = props;

  const handleViewMyBid = (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    onCancel();
    setTimeout(() => {
      navigate("../profile", { replace: true });
    }, 400);
  };

  const handleSetBuilderNow = (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    onCancel();
    setTimeout(() => {
      handleOpenSetBuildModal();
    }, 600);
  };

  const renderMainContent = () => {
    return (
      <div className="lg:pt-11 lg:pb-14 lg:px-24 pt-6 pb-5 px-6 bg-gray-bg-dark lg:auto modal-container">
        <p className="text-white font-commuter lg:text-3xl text-xl font-semibold mb-6 text-center">
          <FormattedMessage id="Bid Successful" />
        </p>
        <p className="text-lg font-light font-commuter text-gray-100">
          {landData?.currentLender && !stringEqualsIgnoreCase(landData.currentLender, account) ? (
            <span>
              Congratulations! Your bid for {parcelName} is successful. You will receive the land after a{" "}
              <span className="text-yellow-300">one day grace period</span> for the current lender to update their bid.
            </span>
          ) : (
            `Congratulations! Your bid for ${parcelName} is successful. You
              can set your builder as operator or find a builder from our
              BuildersDAO community.`
          )}
        </p>
        <div className="flex mt-6 justify-center flex-col-reverse md:flex-row">
          <Button
            text={<FormattedMessage id="Finish" />}
            className="text-center text-gray-400 bg-gray-100 hover:bg-white font-nexa font-bold text-lg px-8 py-3 w-full md:w-1/3 md:mr-2"
            borderColor="none"
            onClick={onCancel}
          />
          {landData?.currentLender && !stringEqualsIgnoreCase(landData.currentLender, account) ? (
            <Button
              text={<FormattedMessage id="View My Bids" />}
              className="text-center text-gray-500 bg-yellow-400 font-nexa font-bold text-lg hover:bg-yellow-200 px-8 py-3 w-full md:w-1/3 md:ml-2 mb-4 md:mb-0"
              borderColor="none"
              onClick={handleViewMyBid}
            />
          ) : (
            <Button
              text={<FormattedMessage id="Set Builder Now" />}
              className="text-center text-gray-500 bg-yellow-400 font-nexa font-bold text-lg hover:bg-yellow-200 px-8 py-3 w-full md:w-1/3 md:ml-2 mb-4 md:mb-0"
              borderColor="none"
              onClick={handleSetBuilderNow}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="transparent">
      <div className="relative border bg-gray-bg-dark border-gray-300 rounded-2xl">{renderMainContent()}</div>
    </div>
  );
}
