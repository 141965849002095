import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { countDecimals, stringEqualsIgnoreCase } from "helpers/misc";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { ReactComponent as GavelIcon } from "../../assets/gavel_filled.svg";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import { useElementSize } from "usehooks-ts";

interface ProfilePastBidsProps {
  loading: boolean;
}

function ProfilePastBids(props: ProfilePastBidsProps) {
  const { loading } = props;

  const [currentPage, handleCurrentPage] = useState(0);

  const { bidAndFundsClaimed } = useSelector((state: RootState) => state.auction);

  const navigate = useNavigate();

  const { account } = useWeb3React();
  const [widthRef, { width }] = useElementSize();
  const itemsPerPage = 5;

  useEffect(() => {
    handleCurrentPage(0);
  }, [bidAndFundsClaimed]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    handleCurrentPage(event.selected);
  };

  const tableRows = bidAndFundsClaimed.bidEvents
    ? bidAndFundsClaimed.bidEvents.map((entry, idx) => {
        if (idx < currentPage * itemsPerPage || (currentPage + 1) * itemsPerPage <= idx) {
          return null;
        }
        const auction = entry.auction;
        return (
          <tr key={`past-bid-${idx}`} className="border-t border-gray-400">
            <td className="pr-2 py-4 whitespace-nowrap text-15 text-gray-100">
              {auction ? (auction.metaverse ? "CryptoVoxels" : "Decentraland") : "--"}
            </td>
            <td className="px-2 my-auto">
              <div className="flex">
                <div
                  className={`px-2 py-1 ${
                    auction?.deadline && auction.deadline * 1000 > Date.now()
                      ? "bg-yellow-100"
                      : stringEqualsIgnoreCase(auction?.highestBidder, account)
                      ? "bg-green-100"
                      : "bg-pink-10"
                  } text-gray-600 rounded-md my-auto`}
                >
                  {auction?.deadline && auction.deadline * 1000 > Date.now()
                    ? "Pending"
                    : stringEqualsIgnoreCase(auction?.highestBidder, account)
                    ? "Won"
                    : "Lost"}
                </div>
              </div>
            </td>
            <td className="px-2 hidden md:table-cell py-4 whitespace-nowrap text-15 text-gray-100">
              {auction?.uri?.landCount ? auction.uri.landCount : "--"}
            </td>
            <td className="px-2 hidden md:table-cell py-4 whitespace-nowrap text-15 text-gray-100">
              {new Date(entry.timestamp * 1000).toLocaleDateString()}
            </td>
            <td className="px-2 py-4 whitespace-nowrap text-15 text-gray-100">
              {auction ? new Date(auction.deadline * 1000 + 604800000).toLocaleDateString() : "--"} - <br />
              {auction ? new Date(auction.deadline * 1000 + 1814400000).toLocaleDateString() : "--"}
            </td>
            <td className="pl-2 py-4 whitespace-nowrap text-15 text-gray-100">
              {countDecimals(parseFloat(ethers.utils.formatEther(entry.amount))) <= 3
                ? ethers.utils.formatEther(entry.amount)
                : parseFloat(ethers.utils.formatEther(entry.amount)).toFixed(3)}{" "}
              ETH
            </td>
          </tr>
        );
      })
    : null;

  const skeletonRow = () => {
    return (
      <tr className="border-t border-gray-400 animate-pulse">
        <td scope="col" className="pr-2 pt-4">
          <div className="flex">
            <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-24" />
          </div>
        </td>
        <td scope="col" className="px-2 pt-4">
          <div className="flex">
            <div className="bg-white rounded mr-2 mt-1 mb-4 h-8 w-12" />
          </div>
        </td>
        <td scope="col" className="px-2 pt-4 hidden md:table-cell">
          <div className="flex">
            <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-12" />
          </div>
        </td>
        <td scope="col" className="px-2 pt-4 hidden md:table-cell">
          <div className="flex">
            <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-20" />
          </div>
        </td>
        <td scope="col" className="px-2 pt-4">
          <div className="flex flex-col">
            <div className="bg-white rounded mr-2 mt-1 mb-1 h-4 w-24" />
            <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-24" />
          </div>
        </td>
        <td scope="col" className="pl-2 pt-4">
          <div className="flex">
            <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-20" />
          </div>
        </td>
      </tr>
    );
  };

  const renderSkeleton = () => {
    return (
      <div className="w-full flex flex-col bg-gray-600 border border-gray-500 mt-4 md:mt-8 rounded-lg overflow-hidden">
        <div className="w-full bg-gray-500 border-b border-gray-600 flex items-center h-[58px] px-4 flex-none">
          <div className="h-full flex w-full items-center text-base font-bold text-gray-100">
            <GavelIcon className="h-6 w-auto mr-2 fill-gray-100" />
            Past Bids
          </div>
        </div>
        <div className="text-gray-200 flex flex-col min-h-72 pt-8 pb-4 px-6 overflow-x-auto dark-overflow">
          <table className="w-full min-w-full text-left text-gray-100">
            <thead>
              <tr className="animate-pulse">
                <th scope="col" className="pr-2 pb-0.5">
                  <div className="flex">
                    <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-24" />
                  </div>
                </th>
                <th scope="col" className="px-2 pb-0.5">
                  <div className="flex">
                    <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-14" />
                  </div>
                </th>
                <th scope="col" className="px-2 pb-0.5 hidden md:table-cell">
                  <div className="flex">
                    <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-12" />
                  </div>
                </th>
                <th scope="col" className="px-2 pb-0.5 hidden md:table-cell">
                  <div className="flex">
                    <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-16" />
                  </div>
                </th>
                <th scope="col" className="px-2 pb-0.5">
                  <div className="flex">
                    <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-32" />
                  </div>
                </th>
                <th scope="col" className="pl-2 pb-0.5">
                  <div className="flex">
                    <div className="bg-white rounded mr-2 mt-1 mb-4 h-4 w-24" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {skeletonRow()}
              {skeletonRow()}
              {skeletonRow()}
              {skeletonRow()}
              {skeletonRow()}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  if (!account || loading) {
    return renderSkeleton();
  }

  return (
    <>
      <div
        ref={widthRef}
        className="w-full flex flex-col bg-gray-600 border border-gray-500 mt-4 md:mt-8 rounded-lg overflow-hidden"
      >
        <div className="w-full bg-gray-500 border-b border-gray-600 flex items-center h-[58px] px-4 flex-none">
          <div className="h-full flex w-full items-center text-base font-bold text-gray-100">
            <GavelIcon className="h-6 w-auto mr-2 fill-gray-100" />
            Past Bids
          </div>
        </div>
        {bidAndFundsClaimed.bidEvents && bidAndFundsClaimed.bidEvents.length > 0 ? (
          <div className="text-gray-200 flex flex-col min-h-72 pt-8 pb-4 px-6 overflow-x-auto dark-overflow">
            <table className="w-full min-w-full text-left text-gray-100">
              <thead>
                <tr>
                  <th scope="col" className="pr-2 text-15 font-semibold pb-4 whitespace-nowrap">
                    Metaverse
                  </th>
                  <th scope="col" className="px-2 text-15 font-semibold pb-4 whitespace-nowrap">
                    Status
                  </th>
                  <th scope="col" className="px-2 hidden md:table-cell text-15 font-semibold pb-4 whitespace-nowrap">
                    Lands
                  </th>
                  <th scope="col" className="px-2 hidden md:table-cell text-15 font-semibold pb-4 whitespace-nowrap">
                    Bid Date
                  </th>
                  <th scope="col" className="px-2 text-15 font-semibold pb-4 whitespace-nowrap">
                    Ads Timeframe
                  </th>
                  <th scope="col" className="pl-2 text-15 font-semibold pb-4 whitespace-nowrap">
                    Bid Amount
                  </th>
                </tr>
              </thead>
              <tbody>{tableRows}</tbody>
            </table>
          </div>
        ) : (
          <div className="flex min-h-72 text-gray-100">
            <div className="m-auto">
              No past bids found.{" "}
              <span
                className="underline cursor-pointer text-yellow-300 hover:text-yellow-200"
                onClick={() => {
                  navigate("../auction", { replace: true });
                }}
              >
                Bid on an auction
              </span>{" "}
              and keep track of your bid here.
            </div>
          </div>
        )}
      </div>
      <div className="mx-auto md:mr-0 md:ml-auto">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          previousLabel="<"
          onPageChange={handlePageClick}
          pageRangeDisplayed={width > 600 ? 2 : 1}
          marginPagesDisplayed={width > 600 ? 2 : 1}
          pageCount={Math.ceil(
            bidAndFundsClaimed.bidEvents
              ? bidAndFundsClaimed.bidEvents.length / itemsPerPage < 1
                ? 0
                : bidAndFundsClaimed.bidEvents.length / itemsPerPage
              : 0
          )}
          // @ts-ignore
          renderOnZeroPageCount={null}
          forcePage={currentPage}
          className="flex flex-row gap-1 text-gray-200 font-nexa mt-3 select-none"
          activeLinkClassName="bg-gray-200 text-gray-100"
          pageLinkClassName="inline-block bg-gray-600 w-6 sm:w-8 h-6 sm:h-8 rounded-md hover:bg-gray-200 hover:text-gray-100 text-center sm:leading-8"
          previousLinkClassName="inline-block bg-gray-600 hover:bg-gray-200 hover:text-gray-100 rounded-md text-center w-6 h-6"
          nextLinkClassName="inline-block bg-gray-600 hover:bg-gray-200 hover:text-gray-100 rounded-md text-center w-6 h-6"
          breakLinkClassName="inline-block w-6 sm:w-8 h-6 sm:h-8 rounded-md hover:bg-gray-600 hover:text-gray-100 text-center sm:leading-8"
        />
      </div>
    </>
  );
}

export default ProfilePastBids;
