import { Contract, ethers } from "ethers";
import { addNotify } from "store/notificationMiddleware";

export async function stakeAndBid(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  metaverseInfo: any,
  metaverseid: string,
  assetid: string,
  radicalMarketInstance: Contract,
  pricePerDay: number,
  days: number
): Promise<{
  success: boolean;
  message: string;
}> {
  let ret = { success: false, message: "" };
  let minimumDays = 1;
  if (metaverseInfo) {
    minimumDays = parseFloat(metaverseInfo.minPeriod);
  }
  try {
    if (days >= minimumDays && pricePerDay > 0 && assetid !== "") {
      // bid(metaverse_enum, asset_id, price_per_day) with bid value attached
      await radicalMarketInstance
        .bid(metaverseid, assetid, ethers.utils.parseUnits(`${pricePerDay}`, 18), {
          value: ethers.utils.parseUnits(`${pricePerDay * days}`, 18)
        })
        .then(async (tx: any) => {
          await tx.wait(1).then(() => {
            // Store notif success
            addNotify({
              title: "Bid Successful",
              message: "You are now the operator of this parcel."
            });
            ret = { success: true, message: "" };
          });
        })
        .catch((e: any) => {
          let err;
          if (e.message) {
            err = e.message;
          } else {
            err = e;
          }
          err = err.toString().split(" (")[0];
          ret = { success: false, message: err };
        });
    } else {
      ret = {
        success: false,
        message: "Validate your bid per day and number of days and try again."
      };
    }
  } catch (e: any) {
    let err;
    if (e.message) {
      err = e.message;
    } else {
      err = e;
    }
    err = err.toString().split(" (")[0];
    ret = { success: false, message: err };
  }

  return ret;
}

export async function setBuilder(
  radicalMarketInstance: Contract,
  metaverseId: string,
  assetId: string,
  operators: string[]
): Promise<{
  success: boolean;
  message: string;
}> {
  let ret = { success: false, message: "" };
  try {
    await radicalMarketInstance
      .setAssetOperators(metaverseId, assetId, operators)
      .then(async (tx: any) => {
        await tx.wait(1).then(() => {
          // Store notif success
          // storeNotif(
          //   "Builder Successfully Set",
          //   "The parcel's operator has been successfully set.",
          //   "success"
          // );
          ret = { success: true, message: "" };
        });
      })
      .catch((e: any) => {
        if (e.message) {
          e = e.message;
        }
        e = e.toString().split(" (")[0];
        ret = { success: false, message: e };
      });
  } catch (e: any) {
    let err;
    if (e.message) {
      err = e.message;
    } else {
      err = e;
    }
    err = err.toString().split(" (")[0];
    ret = { success: false, message: err };
  }

  return ret;
}
