import React, { useCallback, useState } from "react";
import { ReactComponent as ArrowRight } from "../assets/arrow_short.svg";

interface INavItemChildren {
  name: any;
  current: boolean;
  value: number;
  disabled: boolean;
  icon?: string;
}

export type INavItem = {
  children: INavItemChildren[];
} & INavItemChildren;

export interface ISidebarProps {
  title: string;
  setData: React.Dispatch<React.SetStateAction<Array<INavItem>>>;
  data: INavItem[];
}

interface SideBarProps {
  data: any[];
  setData: (e: any) => void;
  onClick?: (e: any) => void;
  isArrow?: boolean;
  [key: string]: any;
}

interface SubListProps {
  item: any;
  parentIdx: number;
  onClick: (...args: any[]) => void;
  [key: string]: any;
}

const SubList = ({ item, parentIdx, onClick }: SubListProps) => {
  const handleClick = useCallback(
    (index: number) => {
      onClick(parentIdx, index);
    },
    [parentIdx, onClick]
  );

  const renderSubItem = useCallback(
    (subItem: any, idx: number) => {
      return (
        <div
          key={"tree-item-" + subItem.id + idx}
          className={`${subItem.icon ? "inline-grid w-full items-center grid-cols-icon-tab" : "flex"}`}
          onClick={() => handleClick(idx)}
        >
          {subItem.icon && <img src={subItem.icon} alt={subItem.id} className="min-w" />}
          <p
            className={`font-normal text-xl ${
              subItem.disabled ? "text-gray-300 cursor-not-allowed" : "cursor-pointer "
            }`}
          >
            {subItem.name}
          </p>
        </div>
      );
    },
    [handleClick]
  );

  return <div className="space-y-6">{item.children.map(renderSubItem)}</div>;
};

const Sidebar = ({ title, data = [], isArrow, setData, onClick }: SideBarProps) => {
  const [showList, setShowList] = useState<boolean[]>(Array(data.length).fill(false));

  const onClickChildren = (parent_index: number, child_index: number) => {
    data[parent_index].children[child_index].current = !data[parent_index].children[child_index].current;
    setData([...data]);
    if (onClick && !data[parent_index].children[child_index].current) {
      onClick(data[parent_index].children[child_index].value);
    }
  };

  const onHandleSidebar = (parent_index: number, disabled: boolean) => {
    if (!disabled) {
      showList[parent_index] = !showList[parent_index];
      setShowList([...showList]);
    }
  };

  const handleClickSidebar = (val: number, index: number) => {
    data.forEach((d) => (d.current = false));
    data[index].current = true;
    setData([...data]);
    if (onClick) {
      onClick(val);
    }
  };

  return (
    <div className="w-56 flex flex-col flex-grow rounded-2.5xl pl-0 pr-9 py-4 overflow-y-auto font-lato">
      <div className="font-commuter flex-grow flex flex-col">
        <nav className="flex-1 space-y-6" aria-label="Sidebar">
          <div className="text-2xl text-gray-100 font-semibold select-none">{title}</div>

          {data.map((item, item_idx) =>
            !item.children.length ? (
              <div
                key={item.id}
                className={`select-none ${
                  item.icon ? "inline-grid w-full items-center grid-cols-icon-tab" : "flex items-center"
                }`}
                onClick={() => !item.disabled && handleClickSidebar(item.value, item_idx)}
              >
                {item.icon && <img src={item.icon} alt={item.name} className="min-w" />}
                <p
                  className={`font-normal text-xl ${
                    item.disabled
                      ? "text-gray-300 cursor-not-allowed"
                      : !item.current
                      ? "text-gray-300 cursor-pointer "
                      : "cursor-pointer "
                  }`}
                >
                  {item.name}
                </p>

                {isArrow && (
                  <ArrowRight
                    className={`transform duration-100 ml-auto rotate-0`}
                    fill={`${item.disabled || !item.current ? "#4F4F4F" : "white"}`}
                  />
                )}
              </div>
            ) : (
              <div key={item.id}>
                {
                  <>
                    <button
                      className={`flex min-w-full items-center font-normal text-xl select-none ${
                        showList[item_idx] ? "mb-6" : ""
                      } ${item.disabled ? "text-gray-300 cursor-not-allowed" : "cursor-pointer"}`}
                      onClick={() => onHandleSidebar(item_idx, item.disabled)}
                    >
                      {item.icon && <img src={item.icon} alt={item.id} className="mr-1 w-5 h-5" />}
                      <p>{item.name}</p>
                      <ArrowRight
                        className={`transform duration-100 ml-auto ${showList[item_idx] ? "rotate-90" : "rotate-0"}`}
                        fill={`${item.disabled ? "#4F4F4F" : "white"}`}
                      />
                    </button>
                    {showList[item_idx] && <SubList item={item} parentIdx={item_idx} onClick={onClickChildren} />}
                  </>
                }
              </div>
            )
          )}
        </nav>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
