import { useMemo } from "react";

import { ReactComponent as CloseIcon } from "../../../assets/clear-x.svg";

const CommonToastContent = (props: any) => {
  const handleClick = () => {
    if (props?.onClickAction) {
      props?.onClickAction();
    }
    props?.onClose();
  };

  const colorStyle = useMemo(() => {
    let bgColor = "bg-gray-600";
    let borderColor = "border-gray-500";

    if (props?.type === "error") {
      bgColor = "bg-red-600";
      borderColor = "border-red-500";
    } else if (props?.type === "warning") {
      bgColor = "bg-yellow-600";
      borderColor = "border-yellow-500";
    } else if (props?.type === "success") {
      bgColor = "bg-green-400";
      borderColor = "border-green-300";
    }
    return {
      bgColor,
      borderColor
    };
  }, [props?.type]);

  const title = useMemo(() => {
    if (props?.title) {
      return props?.title;
    }
    let value = "Info";
    if (props?.type === "error") {
      value = "ERROR";
    } else if (props?.type === "warning") {
      value = "WARNING";
    } else if (props?.type === "success") {
      value = "SUCCESS";
    }
    return value;
  }, [props?.type, props?.title]);

  return (
    <div
      className={`w-full flex flex-col border rounded-lg overflow-hidden ${colorStyle.bgColor} ${colorStyle.borderColor}`}
    >
      <div className="w-full border-b border-gray-600 flex items-center h-[66px] px-4 flex-none">
        <div className="font-nexa h-full flex w-full justify-between items-center text-base font-bold text-white">
          {title}
          <div className="font-nexa ml-2 text-green-200 cursor-pointer" onClick={handleClick}>
            <CloseIcon className="w-[20px]" />
          </div>
        </div>
      </div>
      <div className="w-full h-[66px] items-center px-4 flex-none">
        <div className="font-nexa h-full flex w-full justify-between items-center text-base font-normal text-white">
          {props.message}
        </div>
      </div>
    </div>
  );
};

export default CommonToastContent;
