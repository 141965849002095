import { getAuction } from "helpers/graphql";
import { useState } from "react";
import { ReactComponent as DescriptionIcon } from "../../assets/description.svg";
import AdminDetailInfo from "./AdminDetailInfo";

function AdminAuctionDetailSearch() {
  const [aucData, handleAucData] = useState<Auction>();
  const [aucId, handleAucId] = useState("");
  const [searching, handleSearching] = useState(false);
  const [error, handleError] = useState(false);

  const search = async () => {
    if (searching) {
      return;
    }

    handleError(false);
    handleSearching(true);

    const val = await getAuction(aucId);
    handleAucData(val);
    handleError(!val);

    handleSearching(false);
  };

  return (
    <div className="flex-1 md:min-w-[calc(50%-.75rem)] flex flex-col rounded-lg overflow-hidden border border-gray-500 md:max-w-[calc(50%-.75rem)]">
      <div className="w-full h-[58px] bg-gray-500 border-b border-gray-600 px-4 flex items-center flex-none">
        <div className="flex text-white text-base font-bold items-center">
          <DescriptionIcon className="h-6 mr-2" />
          Search Auction Details
        </div>
      </div>
      <div className="flex-1 bg-gray-600 p-4 font-nexa text-white w-full flex flex-col styled-overflow overflow-auto">
        <div className="flex flex-col md:flex-row">
          <div className="my-auto mr-auto">Auction ID:</div>
          <div className="bg-gray-600 text-gray-200 border rounded-md border-gray-300 h-10 flex items-center pl-2 md:w-56 mt-2 md:mt-0">
            <input
              className="bg-transparent h-full rounded-md text-white placeholder-gray-200 placeholder-opacity-100 bg-gray-600 w-full pl-2 focus:outline-none"
              type="text"
              value={aucId}
              onChange={(e: any) => {
                if (error) {
                  handleError(false);
                }
                handleAucId(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          className={`mt-4 py-2 rounded ${
            searching ? "bg-yellow-200" : "bg-yellow-300"
          } hover:bg-yellow-200 cursor-pointer flex justify-center items-center font-bold text-gray-300 select-none mb-4`}
          onClick={search}
        >
          {searching ? "Loading..." : "Search"}
        </div>
        {aucData ? (
          <>
            <AdminDetailInfo label="ID:" value={aucData?.id} margin="" hasBorder />
            <AdminDetailInfo
              label="Metaverse:"
              value={aucData?.metaverse ? "CryptoVoxels" : aucData?.metaverse === 0 ? "Decentraland" : "--"}
              margin=""
              hasBorder
            />
            <AdminDetailInfo
              label="Birth:"
              value={aucData ? new Date(aucData?.birth * 1000).toLocaleString() : "--"}
              margin="mt-1"
              hasBorder
            />
            <AdminDetailInfo
              label="Deadline:"
              value={aucData ? new Date(aucData?.deadline * 1000).toLocaleString() : "--"}
              margin="mt-1"
              hasBorder
            />
            <AdminDetailInfo label="Highest Bidder:" value={aucData?.highestBidder} margin="mt-1" hasBorder />
            <AdminDetailInfo
              label="Highest Bid:"
              value={aucData ? (aucData.highestBid / 1e18).toFixed(3) + " ETH" : "-- ETH"}
              margin="mt-1"
              hasBorder
            />
            <AdminDetailInfo
              label="Increment:"
              value={aucData ? (aucData.increment / 1e18).toFixed(3) + " ETH" : "-- ETH"}
              margin="mt-1"
              hasBorder
            />
            <AdminDetailInfo label="URI:" value={aucData ? aucData.uri : "--"} margin="mt-1" />
          </>
        ) : (
          <div className="flex h-full w-full">
            {error ? (
              <div className="m-auto text-center text-red-400 font-nexa">Auction {aucId} does not exist.</div>
            ) : (
              <div className="m-auto text-center text-gray-200 font-nexa">Search to view auction details.</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminAuctionDetailSearch;
