import { createSlice } from "@reduxjs/toolkit";

export type TParcel = {
  [key: string]: any;
};

export interface ParcelStates {
  currentParcels: TParcel[];
}

const ParcelSlice = createSlice({
  name: "auth",
  initialState: { currentParcels: [] } as ParcelStates,
  reducers: {
    setParcels(state, action) {
      state.currentParcels = action.payload;
    }
  }
});

export const { setParcels } = ParcelSlice.actions;

export const parcelReducer = ParcelSlice.reducer;
