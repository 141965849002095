import { useEffect } from "react";
import AuctionHeroSection from "./AuctionHeroSection";
import AuctionLocations from "./AuctionLocations";
import AuctionBids from "./AuctionBids";
import AuctionPriceHistory from "./AuctionPriceHistory";
import AuctionHeader from "./AuctionHeader";
import AuctionBanner from "./AuctionBanner";
import {
  getAuctionPriceHistory,
  getCurrentBids,
  getAllCVParcelsWithMADAsContributor,
  getActiveAuctions,
  getPreviousAuctions
} from "helpers/graphql";
import { Contract } from "ethers";
import {
  setAuctionBids,
  setAuctionData,
  setAuctionPriceHistory,
  setCvParcels,
  setDclParcels,
  setPreviousAuctionData
} from "store/auctionSlice";
import { useDispatch } from "react-redux";
import { manager } from "helpers/DCLLandManager";
import { MAD_ADDRESS } from "components/constants/constants";
import { addNotify } from "store/notificationMiddleware";

interface AuctionPageProps {
  madAuctionContract: Contract | null;
  madAuctionInstance: Contract | null;
  handleActivePage: (a: string) => void;
}

const AuctionPage = (props: AuctionPageProps) => {
  const { handleActivePage, madAuctionContract, madAuctionInstance } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    handleActivePage("/auction");

    (async () => {
      // Load current auction data
      const aucData: Map<number, Auction> | undefined = await getActiveAuctions(
        parseInt((Date.now() / 1000).toFixed())
      );
      dispatch(setAuctionData(aucData ? aucData : null));

      // Load the data for previous auctions
      dispatch(setPreviousAuctionData(await getPreviousAuctions(parseInt((Date.now() / 1000).toFixed()))));

      // Current bids auction price list
      dispatch(setAuctionBids(await getCurrentBids(aucData)));

      // Data for price history graph
      dispatch(setAuctionPriceHistory(await getAuctionPriceHistory()));

      // DCL Parcels - null means failed to load
      let dclParcels: DCLLand[] | null | undefined = [];
      try {
        dclParcels = (await manager.fetchLand(MAD_ADDRESS))[0];
        if (!dclParcels) {
          dclParcels = null;
        }
      } catch (err: any) {
        dclParcels = null;
      }
      dispatch(setDclParcels(dclParcels));

      // CV Parcels - null means failed to load
      let cvParcels: CVParcel[] | null | undefined = [];
      try {
        cvParcels = await getAllCVParcelsWithMADAsContributor();
        if (!cvParcels) {
          addNotify({
            title: "Error Fetching CV Parcels",
            message: "There has been a problem fetching CV Parcels. Please try again.",
            type: "error"
          });
          cvParcels = null;
        } else {
          cvParcels.reverse();
        }
      } catch (err: any) {
        addNotify({
          title: "Error Fetching CV Parcels",
          message: "There has been a problem fetching CV Parcels. Please try again.",
          type: "error"
        });
        cvParcels = null;
      }
      dispatch(setCvParcels(cvParcels));
    })();
  }, [handleActivePage, dispatch]);

  return (
    <div className="w-full flex flex-col bg-gray-bg-dark pt-20 pb-10">
      <AuctionBanner madAuctionInstance={madAuctionInstance} />
      <AuctionHeader />
      <div className="w-full flex flex-col bg-gray-bg-dark pt-4 px-3 md:pt-8 md:px-10">
        <div className="w-full mx-auto my-5 flex flex-col max-w-7xl">
          <AuctionHeroSection madAuctionContract={madAuctionContract} madAuctionInstance={madAuctionInstance} />
          <div className="w-full flex-col gap-4 md:gap-6 md:flex-row mt-8 hidden md:flex">
            <AuctionLocations />
            <AuctionBids />
          </div>
          <div className="w-full flex-col mt-4 flex md:hidden">
            <AuctionBids />
          </div>
          <AuctionPriceHistory />
        </div>
      </div>
    </div>
  );
};

export default AuctionPage;
