import { FormattedMessage } from "react-intl";
import Fade from "react-reveal/Fade";
import LandingDaoItem from "./LandingDaoItem";
import { useWindowSize } from "helpers/hooks";
import Images from "components/common/ImageIcons";

export interface ILogoProps {
  scrollPosition: number;
}

const WIDE_WIDTH = 1245;
const FADE_SCROLL_POSITION = 150;

export default function Logo(props: ILogoProps) {
  const { scrollPosition } = props;
  // const locale = useSelector((state: RootState) => state.intl.locale);
  const [width] = useWindowSize();

  // const isZH = useMemo(() => locale.includes("zh"), [locale]);

  return (
    <div className="bg-land-logo-bg bg-no-repeat bg-cover w-full">
      <div>
        <div className="max-w-7xl mx-auto">
          <div className="fixed w-full max-w-7xl mx-auto mt-16 top-64 pointer-events-none	">
            <img src={Images.MADLOGO} className="ml-auto hidden md:block" alt="" />
          </div>
          <div className="flex flex-col xl:flex-row">
            <div className="xl:self-start xl:sticky xl:top-0 xl:overflow-y-auto">
              <div className="py-16 mx-4 xl:mx-0">
                <img src={Images.GRIDS1} className="mx-auto xl:ml-0" alt="" />
                {width > WIDE_WIDTH ? (
                  <p className="font-commuter text-12 md:text-3xl font-normal w-2/3 md:w-full ml-auto md:ml-0 text-center xl:text-left">
                    <Fade top cascade collapse>
                      Building the virtual land market protocol <br />
                    </Fade>
                    <Fade top cascade collapse when={scrollPosition > FADE_SCROLL_POSITION}>
                      <span className="text-yellow-400">and bringing metaverse in-game players together.</span>
                    </Fade>
                  </p>
                ) : (
                  <p className="font-commuter text-12 md:text-3xl font-normal w-2/3 md:w-full ml-auto md:ml-0 text-left md:text-center">
                    Building the virtual land market protocol{" "}
                    <span className="text-yellow-400">and bringing metaverse in-game players together.</span>
                  </p>
                )}
                <p className="font-commuter text-12 md:text-3xl font-normal w-2/3 md:w-full ml-auto md:ml-0 text-left"></p>
              </div>
            </div>
            <div className="px-4 md:px-0 flex flex-col w-full xl:w-1/3 xl:mr-8 xl:mr-0 xl:ml-auto pt-16 pb-32">
              <LandingDaoItem
                logo={Images.LandingDaoLogo}
                title={"LandownersDAO"}
                text={<FormattedMessage id="LandownersDAO" />}
                className="-rotate-5 xl:-rotate-15"
              />
              <LandingDaoItem
                logo={Images.BuilderDaoLogo}
                title={"BuildersDAO"}
                text={<FormattedMessage id="BuildersDAO" />}
                link="https://app.dework.xyz/metaverse-advertisem/builders-subdao"
                className="rotate-5 xl:rotate-15 mt-14 md:mt-20 xl:mt-36"
              />

              <LandingDaoItem
                logo={Images.AnalysisDaoLogo}
                title={"AnalystsDAO"}
                text={<FormattedMessage id="AnalystsDAO" />}
                link="https://app.dework.xyz/metaverse-advertisem/analysts-subdao"
                className="-rotate-5 xl:-rotate-15 mt-14 md:mt-20 xl:mt-36"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
