import { FormattedMessage } from "react-intl";
// @ts-ignore
import file from "../../assets/MAD_Whitepaper.pdf";

export const getNavbarLinks = (navigate: any, isZH: boolean, handleLocale: (lang: any) => void) => [
  {
    text: <FormattedMessage id={"Lease My Estate"} />,
    onClick: () => {
      navigate("../lease", { replace: true });
    }
  },
  {
    text: <FormattedMessage id={"Products"} />,
    children: [
      {
        text: <FormattedMessage id={"Ads Auction"} />,
        onClick: () => {
          navigate("../auction", { replace: true });
        }
      }
    ]
  },
  {
    text: <FormattedMessage id={"Community"} />,
    children: [
      {
        text: "White Paper",
        onClick: () => {
          const a = document.createElement("A") as HTMLAnchorElement;
          a.href = file;
          a.target = "_blank";
          a.rel = "noreferrer";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      },
      {
        text: "GitBook",
        onClick: () => {
          window.open("https://metaverse-advertisement-dao.gitbook.io/mad-dao", "_blank", "noreferrer");
        }
      }
    ]
  },
  {
    text: !isZH ? "English (US)" : "Chinese (汉语)",
    children: [
      {
        text: "English (US)",
        onClick: () => {
          handleLocale("en");
        }
      },
      {
        text: "Chinese (汉语)",
        onClick: () => {
          handleLocale("zh");
        }
      }
    ]
  },
  {
    text: <FormattedMessage id={"Report a Bug"} />,
    onClick: () => {
      try {
        const win = window.open("https://form.typeform.com/to/nlsQCm9J", "_blank");
        if (win) {
          win.opener = null;
        }
      } catch (err) {
        // Do nothing
      }
    }
  }
];

export function getPageTitleMap(): Map<string, string> {
  const pageTitleMap = new Map<string, string>();

  pageTitleMap.set("/", "Home");
  pageTitleMap.set("//", "Home");
  pageTitleMap.set("/home/", "Home");
  pageTitleMap.set("/radicalmarket/", "Radical Market");
  pageTitleMap.set("/auction", "Ads Auction");
  pageTitleMap.set("/auction/", "Ads Auction");
  pageTitleMap.set("/lease", "Lease My Estate");
  pageTitleMap.set("/lease/", "Lease My Estate");
  pageTitleMap.set("/stake", "Stake Land");
  pageTitleMap.set("/stake/", "Stake Land");
  pageTitleMap.set("/profile", "My Account");
  pageTitleMap.set("/profile/", "My Account");
  pageTitleMap.set("/admin", "Admin");
  pageTitleMap.set("/admin/", "Admin");

  return pageTitleMap;
}
