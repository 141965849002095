import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import { getCurrentEthPriceInUsd } from "helpers/chainlink";
import Button from "../ui/Button";
import LandingSwiperItem from "./LandingSwiperItem";
import Images from "components/common/ImageIcons";
import Videos from "components/common/Videos";

const PastAds = () => {
  const [playingVideoIndex, handlePlayingVideoIndex] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hover, handleHover] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [auctionBidEth, handleAuctionBidEth] = useState(0.5);
  const [ethToDollars, handleEthToDollars] = useState("$--");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hours, handleHours] = useState(20);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [minutes, handleMinutes] = useState(45);
  // const [seconds, handleSeconds] = useState(9);
  const navigate = useNavigate();

  const player = useRef<any>(null);

  const videoArray = useMemo(() => {
    return [
      {
        name: <FormattedMessage id={"Jay Chou Phanta Bear NFT"} defaultMessage={"Jay Chou Phanta Bear NFT"} />,
        video: Videos.JayChou,
        image: Images.JayChouImage,
        link: "https://opensea.io/collection/phantabear"
      },
      {
        name: <FormattedMessage id={"Dark Forest Guild W"} defaultMessage={"Dark Forest Guild W"} />,
        video: Videos.DarkForest,
        image: Images.DarkForestImage,
        link: "https://guildw.xyz/"
      },
      {
        name: <FormattedMessage id={"Tron*ApeNFT 2021"} defaultMessage={"Tron*ApeNFT 2021"} />,
        video: Videos.ApeNFT,
        image: Images.ApeNFTImage,
        link: "https://www.apenft.org/"
      },
      {
        name: <FormattedMessage id={"MoleDAO Hackathon"} defaultMessage={"MoleDAO Hackathon"} />,
        video: Videos.MoleDAO,
        image: Images.moleDAOImage,
        link: "https://moledao.io/#/hackathon"
      },
      {
        name: <FormattedMessage id={"OrangeDAO"} defaultMessage={"OrangeDAO"} />,
        video: Videos.Orangedao,
        image: Images.Placeholder,
        link: "https://twitter.com/orangedaoxyz"
      },
      {
        name: <FormattedMessage id={"F1 Showroom"} defaultMessage={"F1 Showroom"} />,
        video: Videos.F1showroom,
        image: Images.Placeholder,
        link: "https://www.f1autos.com.sg/"
      },
      {
        name: <FormattedMessage id={"#Futurecraft Artists"} defaultMessage={"#Futurecraft Artists"} />,
        video: Videos.Futurecraft,
        image: Images.Placeholder,
        link: "https://mobile.twitter.com/r0bp"
      },
      {
        name: <FormattedMessage id={"More..."} defaultMessage={"More..."} />,
        video: null,
        link: null
      }
    ];
  }, []);

  const seekNextVideoIndex = () => {
    if (hover) {
      return;
    }
    if (playingVideoIndex === videoArray.length - 2) {
      handlePlayingVideoIndex(0);
    } else {
      handlePlayingVideoIndex(playingVideoIndex + 1);
    }
  };

  const fetchCurrentEthPrice = useCallback(async () => {
    const currentPrice = await getCurrentEthPriceInUsd();
    const val = (currentPrice * auctionBidEth).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    handleEthToDollars("$" + val);
  }, [auctionBidEth]);

  useEffect(() => {
    fetchCurrentEthPrice();
  }, [auctionBidEth, fetchCurrentEthPrice]);

  // const handleClickDiscord = useCallback(() => {
  //   // TODO: navigate('../auction', {replace: true});
  //   window.open("https://discord.gg/RnMhaZBRgR", "_blank"); // Open discord invite link for now
  // }, []);

  // const handleClickLease = useCallback(() => {
  //   navigate("lease", { replace: true });
  // }, []);

  const handleClickAuction = useCallback(() => {
    navigate("auction", { replace: true });
  }, [navigate]);

  const handlePlayerEnded = useCallback(() => {
    player.current?.seekTo?.(0);
    seekNextVideoIndex();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player.current, playingVideoIndex, seekNextVideoIndex]);

  return (
    <>
      <div className="w-full bg-mad-white z-1">
        <div className="w-full flex flex-col px-6 py-12 sm:p-8 md:p-20 max-w-screen-8xl mx-auto">
          <div className="text-purple-200 text-2xl sm:text-4xl md:text-5xl font-bold font-nexa text-center">
            <FormattedMessage id="Advertisement in the Metaverse" />
          </div>
          <div className="text-gray-45 mt-4 mb-8 text-center text-base sm:text-xl md:text-2xl">
            <FormattedMessage id="attendDescription" />
          </div>
          <div className="flex flex-col cursor-default">
            <Button
              onClick={handleClickAuction}
              text={<FormattedMessage id="Attend Auction" />}
              borderColor="none"
              className="text-center rounded-full md:leading-6 bg-purple-200 border-purple-200 hover:bg-purple-300 text-sm sm:text-xl md:text-2xl mx-auto px-6 py-4 md:px-8 md:py-5 font-nexa font-bold"
            />
          </div>
          <div className="flex flex-col lg:flex-row mx-2 sm:px-9 my-10">
            <div className="font-commuter font-semibold sm:ml-0 sm:h-full flex-col flex-shrink-0 flex lg:pl-8 lg:pr-12 pb-4 lg:pb-0">
              <div className="h-full w-full bg-gray-bg-dark rounded-3xl py-6 pl-12 border-gray-400 opacity-90">
                <div className="mb-0 md:mb-12 text-left text-2xl sm:text-3xl md:text-4xl">
                  <FormattedMessage id="Auction" />
                </div>
                <div className="flex flex-col w-full justify-evenly md:justify-start">
                  <div className="mt-6 md:mt-2 lg:mt-4">
                    <div className="text-gray-80 mr-6 md:mr-0 text-sm md:text-xl">
                      <FormattedMessage id="Highest Bid" />
                    </div>
                    <div className="flex flex-row my-2 items-center">
                      <img className="h-6 md:h-8 mr-2 my-auto" src={Images.EthIcon} alt="" />
                      <div className="text-sm md:text-4xl">{auctionBidEth}</div>
                    </div>
                    <div className="text-gray-80 text-sm">{ethToDollars}</div>
                  </div>
                  <div className="mt-2 lg:mt-10">
                    <div className="text-gray-80 text-left">
                      <FormattedMessage id="Auction Ends In" />
                    </div>
                    <div className="flex flex-row">
                      <div className="flex flex-col">
                        <div className="text-sm md:text-4xl font-bold my-2 md:mb-0 md:mt-1 text-left">{hours}</div>
                        <div className="text-sm text-gray-80 text-center md:text-left">Hours</div>
                      </div>
                      <div className="flex flex-col mx-4 md:mx-8">
                        <div className="text-sm md:text-4xl font-bold my-2 md:mb-0 md:mt-1 text-left">{minutes}</div>
                        <div className="text-sm text-gray-80 text-center md:text-left">
                          <FormattedMessage id="Minutes" />
                        </div>
                      </div>
                      <div className="flex flex-col relative ml-auto">
                        <img
                          className="h-24 w-24 md:h-48 md:w-48 xl:h-36 xl:w-36 -mt-8 md:-mt-16"
                          src={Images.Gavel}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full relative flex-shrink ml-1 my-auto">
              {/* We need a placeholder image to allocate proper space on the page.
              We also need a placeholder image to display the box shadow (otherwise
              it flickers when the videos switch). We finally need our ReactPlayer
              to play the actual video. */}
              <img className="relative left-0 right-0 opacity-0" src={Images.Placeholder} alt="" />
              <div className="absolute left-0 top-0">
                <img className="w-full h-auto opacity-0" src={Images.Placeholder} alt="" />
              </div>
              <div className="absolute left-0 top-0 rounded-2xl">
                <ReactPlayer
                  ref={player}
                  url={videoArray[playingVideoIndex].video}
                  fallback={<img src={videoArray[playingVideoIndex].image} alt="" />}
                  playing={true}
                  muted={true}
                  width="100%"
                  height="auto"
                  onEnded={handlePlayerEnded}
                  className="react-player"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="-mt-16 w-full hidden md:block">
        <Swiper
          spaceBetween={40}
          slidesPerView={4}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            920: {
              slidesPerView: 2.5,
              spaceBetween: 20
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            1500: {
              slidesPerView: 4,
              spaceBetween: 40
            }
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          <SwiperSlide>
            <LandingSwiperItem
              logo={Images.BitskiIcon}
              title={"Bitski & Shoes wearable NFT Collab Build"}
              date={"2022.3 Decentraland"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LandingSwiperItem
              logo={Images.AutoCarIcon}
              title={"F1 Auto Car Dealer Shop Collab Build"}
              date={"2022.1 Cryptovoxels"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LandingSwiperItem
              logo={Images.OrangeDaoIcon}
              title={"OrangeDAO Office Collab Build"}
              date={"2022.1 Cryptovoxels"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LandingSwiperItem
              logo={Images.PhantaBearIcon}
              title={"PhantaBear NFT Advertisement Build"}
              date={"2021.12 Cryptovoxels"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LandingSwiperItem
              logo={Images.FutureCraftIcon}
              title={"Futurecraft NFT artistgroup virtual gallery"}
              date={"2021.12 Cryptovoxels"}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default React.memo(PastAds);
