import English from "../lang/en.json";
import Chinese from "../lang/zh.json";

export function loadState(): any | undefined {
  try {
    const serializedState = localStorage.getItem("state");
    if (!serializedState) {
      return undefined;
    }
    const json = JSON.parse(serializedState);
    json.auction = undefined;
    if (json.intl.locale === "en") {
      json.intl.messages = English;
    } else {
      json.intl.messages = Chinese;
    }
    return json;
  } catch (err) {
    return undefined;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function saveState(state: any): void {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // Ignore write errors. The app will initialize just fine.
  }
}
