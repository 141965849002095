import { useEffect, useState } from "react";
import { Atlas, AtlasTile } from "../common/Atlas";
import { Layer } from "react-tile-map";

interface AtlasWrapperProps {
  tiles: Record<string, AtlasTile> | undefined;
  selectedX: number;
  selectedY: number;
  zoom: number;
  handleTiles: (r: Record<string, AtlasTile>) => void;
  activeMetaverseParcels: DCLLand[] | undefined;
}

export function AtlasWrapper(props: AtlasWrapperProps) {
  const { tiles, selectedX, selectedY, zoom, handleTiles, activeMetaverseParcels } = props;

  const [parcelMap, handleParcelMap] = useState<Record<string, DCLLand>>();

  useEffect(() => {
    (async () => {
      handleTiles(await Atlas.fetchTiles());
    })();
  }, [handleTiles]);

  useEffect(() => {
    if (activeMetaverseParcels) {
      const pm: Record<string, DCLLand> = {};
      for (const p of activeMetaverseParcels) {
        pm[`${p.x},${p.y}`] = p;
      }
      handleParcelMap(pm);
    }
  }, [activeMetaverseParcels]);

  const strokeLayer: Layer = (x, y) => {
    const key = x + "," + y;
    if (tiles && tiles[key] && parcelMap && parcelMap[key]) {
      return { color: "#E5F127" };
    }
    return null;
  };

  const fillLayer: Layer = (x, y) => {
    const key = x + "," + y;
    if (tiles && tiles[key] && parcelMap && parcelMap[key]) {
      return { color: "#EEF78E", scale: 0.9 };
    }
    return null;
  };

  const selectedStrokeLayer: Layer = (x, y) => {
    const key = x + "," + y;
    if (x === selectedX && y === selectedY && tiles && tiles[key] && parcelMap && parcelMap[key]) {
      return { color: "#7DCEAD", scale: 1.4 };
    }
    return null;
  };

  const selectedFillLayer: Layer = (x, y) => {
    const key = x + "," + y;
    if (x === selectedX && y === selectedY && tiles && tiles[key] && parcelMap && parcelMap[key]) {
      return { color: "#B9E5D4", scale: 1.2 };
    }
    return null;
  };

  if (!parcelMap) {
    return <></>;
  }

  return (
    <>
      <Atlas
        tiles={tiles}
        layers={[strokeLayer, fillLayer, selectedStrokeLayer, selectedFillLayer]}
        x={selectedX}
        y={selectedY}
        zoom={zoom}
      />
    </>
  );
}
