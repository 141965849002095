import React from "react";

interface IDrawerProps {
  handleClose: (e?: any) => void;
  isOpen: boolean;
  children?: JSX.Element | JSX.Element[];
  [key: string]: any;
}

export default function Drawer({ children, isOpen, handleClose }: IDrawerProps) {
  return (
    <div
      className={
        " fixed overflow-hidden z-10 bg-opacity-25 inset-0 transform ease-in-out " +
        (isOpen
          ? " transition-opacity opacity-100 duration-500 translate-y-0  "
          : " transition-all delay-500 opacity-0 translate-y-full")
      }
    >
      <section
        className={
          "w-full max-h-lg bottom-0 absolute bg-black-17 shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
          (isOpen ? " translate-y-0 " : " translate-y-full ")
        }
      >
        <article className="relative w-full flex flex-col space-y-6 h-full">{children}</article>
      </section>
      <section
        className="w-screen h-full cursor-pointer "
        onClick={() => {
          handleClose();
        }}
      ></section>
    </div>
  );
}
