import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "assets/css/tailwind.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "store";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { HashRouter as Router } from "react-router-dom";
import { install } from "resize-observer";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    enabled: true
  });
}

if (!window.ResizeObserver) install();

const getLibrary = (provider: any) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p className="text-white">An error has occurred</p>}>
      <Provider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Router>
            <App />
          </Router>
        </Web3ReactProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
