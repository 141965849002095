import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Button from "../ui/Button";
import { getCurrentEthPriceInUsd } from "helpers/chainlink";
import Images from "components/common/ImageIcons";

interface RadicalMarketParcelProps {
  cancelCallback: (e?: any) => void;
  claimProfitCallback: (e?: any) => void;
  leaseCallback: (e?: any) => void;
  metaverseId: number;
  layerName: string;
  i: number;
  [key: string]: any;
}

const RadicalMarketParcel = (props: RadicalMarketParcelProps) => {
  const { parcel, metaverseId } = props;
  const navigate = useNavigate();
  const [madPriceUsd, handleMadPriceUsd] = useState("");

  const handleClick = useCallback(() => {
    navigate(`../radicalmarket/${metaverseId}/${parcel?.tokenId}`, {
      replace: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcel]);

  const handlePriceValue = useCallback(async () => {
    if (parcel?.madPrice) {
      handleMadPriceUsd(((await getCurrentEthPriceInUsd()) * parcel.madPrice).toFixed(2));
    }
  }, [parcel]);

  useEffect(() => {
    handlePriceValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcel]);

  return (
    <>
      <div className="h-40 overflow-hidden rounded-t-md">
        <img
          onDragStart={(e) => e.preventDefault()}
          className="mx-auto w-full -m-20 cursor-pointer"
          src={parcel?.image}
          onClick={handleClick}
          alt=""
        />
      </div>
      <div className="h-1/2 w-full px-4 py-4">
        <div className="w-full h-full flex flex-col">
          <div className="w-full flex flex-col">
            <div className="flex justify-between items-center">
              <div className="text-gray-100 text-sm font-nexa font-bold">
                {parcel?.name
                  ? parcel.name
                  : `Parcel (${parcel?.parcel ? parcel.parcel.x : "--"}, ${parcel?.parcel ? parcel.parcel.y : "--"})`}
              </div>
              <div className="bg-gray-300 px-2 py-1 rounded-xl flex w-max">
                <img className="h-4 w-4 my-auto" src={Images.LocationIcon} alt="" />
                <div className="text-xs ml-1 my-auto font-nexa">{`${parcel?.parcel ? parcel.parcel.x : "--"}, ${
                  parcel?.parcel ? parcel.parcel.y : "--"
                }`}</div>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="text-right justify-start">
                <p className="text-12 text-gray-100 font-normal text-left mb-2">
                  <FormattedMessage id="Current Bid" />:
                </p>
                <div className="flex items-center">
                  <img src={Images.EthBlackIcon} alt="eth-icon" className="mr-2 w-4" />

                  <p className="text-gray-100 text-12 font-nexa font-bold mr-2">{parcel?.madPrice}</p>

                  <p className="text-gray-200 text-12 font-nexa font-bold">${madPriceUsd}/day</p>
                </div>
              </div>
              <Button
                onClick={handleClick}
                className="bg-green-100 text-gray-500 w-max hover:bg-gray-100 hover:text-gray-500 ml-auto mt-2 px-2 font-bold font-nexa"
                text={<FormattedMessage id="Bid Now" />}
                borderColor={"gray-100"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(RadicalMarketParcel);
