import { createSlice } from "@reduxjs/toolkit";
import English from "../lang/en.json";
import Chinese from "../lang/zh.json";

export interface langState {
  locale: string;
  messages: any;
}

const LangSlice = createSlice({
  name: "lang",
  initialState: { locale: "en", messages: English } as langState,
  reducers: {
    selectLanguage(state, action) {
      state.locale = action.payload;
      if (action.payload.includes("zh")) {
        state.messages = Chinese;
      } else {
        state.messages = English;
      }
    }
  }
});

export const { selectLanguage } = LangSlice.actions;

export const langReducer = LangSlice.reducer;
