import Button from "components/ui/Button";
import { Contract } from "ethers";
import { setBuilder } from "helpers/bid";
import { getLandInfo } from "helpers/graphql";
import { addNotify } from "store/notificationMiddleware";

interface SetBuilderContentProps {
  onCancel: () => void;
  radicalMarketInstance: Contract | null;
  metaverseId: number;
  assetId: string;
  handleLandData?: (data: any) => void;
}

export function SetBuilderContent(props: SetBuilderContentProps) {
  const { onCancel, radicalMarketInstance, metaverseId, assetId, handleLandData } = props;

  const handleSetBuilderNow = async (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    if (!radicalMarketInstance) {
      return;
    }
    if ((document.getElementById("bidder-input") as HTMLInputElement)?.value?.trim().length > 0) {
      const { success, message } = await setBuilder(radicalMarketInstance, metaverseId.toString(), assetId, [
        (document.getElementById("bidder-input") as HTMLInputElement).value
      ]);
      if (success) {
        onCancel();
        // Try to update land data so the operator is updated without refreshing the page (assumes graphql is already up to date)
        if (handleLandData) {
          handleLandData(await getLandInfo(metaverseId.toString(), assetId));
        }
      } else {
        addNotify({
          title: "Transaction Failed",
          message,
          type: "error"
        });
      }
    }
  };

  const renderMainContent = () => {
    return (
      <div className="py-4 md:py-8 px-4 md:px-16 flex flex-col">
        <p className="font-commuter font-semibold text-3xl text-center">Set Builder</p>
        <div className="flex mt-8 flex-col md:flex-row">
          <input
            className="flex-grow bg-transparent border border-white border-opacity-30 rounded-md lg:text-2xl text-xl px-1 outline-none mr-0 md:mr-12 mb-6 md:mb-0"
            type="text"
            id="bidder-input"
          />
          <Button
            text="Set Builder Now"
            className="bg-yellow-300 hover:bg-yellow-200 text-black-20 text-center md:text-left font-semibold w-auto font-commuter lg:text-lg lg:px-6 md:py-auto mb-4 md:mb-0"
            onClick={handleSetBuilderNow}
          />
        </div>
        <p className="font-commuter text-md md:text-xl mt-6 text-center">
          Don&apos;t have a builder?{" "}
          <a
            href="https://app.dework.xyz/metaverse-advertisem/builders-subdao"
            target="_blank"
            rel="noreferrer"
            className="text-yellow-300 underline"
          >
            {"Create a bounty"}
          </a>{" "}
          on the BuildersDAO to find a builder.
        </p>
      </div>
    );
  };

  return (
    <div className="transparent">
      <div className="relative border bg-gray-bg-dark border-gray-300 rounded-2xl">{renderMainContent()}</div>
    </div>
  );
}
