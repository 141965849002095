import * as React from "react";

export interface ILandingSwiperItemProps {
  logo: any;
  title: string;
  date: string;
}

export default function LandingSwiperItem(props: ILandingSwiperItemProps) {
  const { logo, title, date } = props;
  return (
    <div className=" font-lato font-bold flex rounded-3xl box-border bg-white items-center py-3 px-5 justify-between w-fit-content">
      <img src={logo} alt="bit-ski-icon" className=" mx-auto w-16 h-16" />
      <div className="my-auto w-60 ml-9">
        <p className="text-lg text-gray-600 leading-none">{title}</p>
        <p className="text-red-100 text-12 font-bold mt-2">{date}</p>
      </div>
    </div>
  );
}
