import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as ArrowDown } from "../../assets/arrow_down.svg";
import { getRandomId } from "../../helpers/misc";
import { FormattedMessage } from "react-intl";

interface DropdownProps {
  initialSelectedItemIdx?: number;
  dropdownItems: string[];
  onSelectionChanged?: (id: number) => void;
  onDropdownToggled?: (active: boolean) => void;
  buttonClassName: string;
  dropdownClassName: string;
  dropdownButtonClassName: string;
  zeroHeightDropdown: boolean;
  dropdownZIndexClassName?: string;
}

export function Dropdown(props: DropdownProps) {
  const {
    initialSelectedItemIdx,
    dropdownItems,
    onSelectionChanged,
    onDropdownToggled,
    buttonClassName,
    dropdownClassName,
    dropdownButtonClassName,
    zeroHeightDropdown,
    dropdownZIndexClassName
  } = props;
  const [activeIdx, handleActiveIdx] = useState(initialSelectedItemIdx ? initialSelectedItemIdx : 0);
  const [showDropdown, handleShowDropdown] = useState(false);
  const [dropdownId] = useState(getRandomId(10));
  const dropdownContainerRef = useRef(null);

  useEffect(() => {
    // Can force-set an active item using this method (even after initial render)
    if (initialSelectedItemIdx && initialSelectedItemIdx >= 0) {
      handleActiveIdx(initialSelectedItemIdx);
    }
  }, [initialSelectedItemIdx]);

  useEffect(() => {
    let eventListener: any = null;
    if (showDropdown) {
      // @ts-ignore
      eventListener = hideMenu.bind(this);
      document.addEventListener("click", eventListener);
    }

    return () => {
      if (eventListener) {
        document.removeEventListener("click", eventListener);
      }
    };
  }, [showDropdown]);

  const hideMenu = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const dropdownComponents = document.getElementsByClassName(`dropdown-${dropdownId}-component`);
      for (let i = 0; i < dropdownComponents.length; i++) {
        if (dropdownComponents[i].contains(event.target)) {
          return;
        }
      }
    }
    handleShowDropdown(false);
  };

  const selectionChanged = (idx: number) => {
    handleActiveIdx(idx);
    if (onSelectionChanged) {
      onSelectionChanged(idx);
    }
  };

  const dropdownItemElement = (
    <CSSTransition
      in={showDropdown}
      timeout={{ enter: 300, exit: 300 }}
      classNames="dropdownitem"
      unmountOnExit
      appear
      exit={true}
      nodeRef={dropdownContainerRef}
      className={`dropdown-${dropdownId} ${dropdownClassName}`}
    >
      <div ref={dropdownContainerRef} className={`dropdown-${dropdownId}-component flex flex-col`}>
        {dropdownItems.map((item: string, idx: number) => (
          <div
            key={`dropdown-${dropdownId}-${idx}`}
            className={`${dropdownButtonClassName}`}
            onClick={() => selectionChanged(idx)}
          >
            <FormattedMessage id={item} />
          </div>
        ))}
      </div>
    </CSSTransition>
  );

  return (
    <>
      <div
        className={`dropdown-${dropdownId}-component ${buttonClassName}`}
        onClick={() => {
          if (onDropdownToggled) {
            onDropdownToggled(!showDropdown);
          }
          handleShowDropdown(!showDropdown);
        }}
      >
        <div className="text-md font-bold text-white">
          {dropdownItems[activeIdx] ? <FormattedMessage id={dropdownItems[activeIdx]} /> : ""}
        </div>
        <ArrowDown
          className={`transform duration-300 ml-2 inline-block fill-white lg:hover:fill-gray-500 stroke-white ${
            showDropdown ? "-rotate-180" : "rotate-0"
          }`}
        />
      </div>
      {zeroHeightDropdown ? (
        <div className={`h-0${dropdownZIndexClassName ? " " + dropdownZIndexClassName : ""}`}>
          <div className="relative">{dropdownItemElement}</div>
        </div>
      ) : (
        <div className={`relative${dropdownZIndexClassName ? " " + dropdownZIndexClassName : ""}`}>
          {dropdownItemElement}
        </div>
      )}
    </>
  );
}

export default Dropdown;
