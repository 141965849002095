import { useEffect, useState, memo } from "react";
import DateTimePicker from "react-datetime-picker";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { BigNumber, Contract, ethers } from "ethers";
import Fade from "react-reveal/Fade";
import "../../styles/datetime-picker/DateTimePicker.css";
import "../../styles/datetime-picker/Calendar.css";
import "../../styles/datetime-picker/Clock.css";
import { ReactComponent as ClearIcon } from "../../assets/clear-x.svg";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { ReactComponent as DescriptionIcon } from "../../assets/description.svg";
import { manager } from "helpers/DCLLandManager";
import { MAD_ADDRESS } from "components/constants/constants";
import Dropdown from "components/ui/Dropdown";
import { setCvParcels, setDclParcels } from "store/auctionSlice";
import { getAllCVParcelsWithMADAsContributor } from "helpers/graphql";
import { addNotify } from "store/notificationMiddleware";

interface AdminCreateAuctionProps {
  madAuctionInstance: Contract | null;
}

function AdminCreateAuction(props: AdminCreateAuctionProps) {
  const { madAuctionInstance } = props;

  const [metaverse, handleMetaverse] = useState(0);
  const [basePrice, handleBasePrice] = useState("0");
  const [priceIncrement, handlePriceIncrement] = useState("0.01");
  const [deadlineDate, handleDeadlineDate] = useState(new Date());
  const [error, handleError] = useState(false);
  const [errorMsg, handleErrorMsg] = useState("");
  const [processingTx, handleProcessingTx] = useState(false);

  const { dclParcels, cvParcels } = useSelector((state: RootState) => state.auction);

  const dispatch = useDispatch();

  const dropdownItems = ["Decentraland", "CryptoVoxels"];

  useEffect(() => {
    (async () => {
      // DCL Parcels - null means failed to load
      let dclParcels: DCLLand[] | null | undefined = [];
      try {
        dclParcels = (await manager.fetchLand(MAD_ADDRESS))[0];
        if (!dclParcels) {
          dclParcels = null;
        }
      } catch (err: any) {
        dclParcels = null;
      }
      dispatch(setDclParcels(dclParcels));

      // CV Parcels - null means failed to load
      let cvParcels: CVParcel[] | null | undefined = [];
      try {
        cvParcels = await getAllCVParcelsWithMADAsContributor();
        if (!cvParcels) {
          addNotify({
            title: "Error Fetching CV Parcels",
            message: "There has been a problem fetching CV Parcels. Please try again.",
            type: "error"
          });
          cvParcels = null;
        } else {
          cvParcels.reverse();
        }
      } catch (err: any) {
        addNotify({
          title: "Error Fetching CV Parcels",
          message: "There has been a problem fetching CV Parcels. Please try again.",
          type: "error"
        });
        cvParcels = null;
      }
      dispatch(setCvParcels(cvParcels));
    })();
  }, []);

  const selectMetaverse = (metaverseId: number) => {
    handleMetaverse(metaverseId);
  };

  const validateAndCreateAuction = async () => {
    if (processingTx) {
      return;
    }

    handleProcessingTx(true);
    handleError(false);
    handleErrorMsg("");

    if (!madAuctionInstance) {
      handleErrorMsg("Error instantiating MAD auction instance. Please refresh your page.");
      handleError(true);
      handleProcessingTx(false);
      return;
    }

    let bp = BigNumber.from(0);
    let pi = BigNumber.from(0);
    let d = 0;

    // Parse metaverse
    try {
      if (metaverse !== 0 && metaverse !== 1) {
        throw new Error();
      }
    } catch (err) {
      handleErrorMsg("Metaverse should be set to 0 or 1.");
      handleError(true);
      handleProcessingTx(false);
      return;
    }

    // Parse base price
    try {
      const tmpBasePrice = parseFloat(basePrice);
      if (tmpBasePrice < 0) {
        throw new Error();
      }
      bp = ethers.utils.parseUnits(`${tmpBasePrice}`, 18);
    } catch (err) {
      handleErrorMsg("Invalid base price.");
      handleError(true);
      handleProcessingTx(false);
      return;
    }

    // Parse price increment
    try {
      const tmpPriceIncrement = parseFloat(priceIncrement);
      if (tmpPriceIncrement < 0) {
        throw new Error();
      }
      pi = ethers.utils.parseUnits(`${tmpPriceIncrement}`, 18);
    } catch (err) {
      handleErrorMsg("Invalid price increment.");
      handleError(true);
      handleProcessingTx(false);
      return;
    }

    // Parse deadline
    try {
      d = Math.round(deadlineDate.getTime() / 1000);
      if (d * 1000 < Date.now()) {
        throw new Error();
      }
    } catch (err) {
      handleErrorMsg("Invalid deadline.");
      handleError(true);
      handleProcessingTx(false);
      return;
    }

    const tmpUri = JSON.stringify({
      landCount: metaverse ? cvParcels?.length : dclParcels?.length
    });

    // Create auction
    await madAuctionInstance
      .newAuction(metaverse, bp, pi, d, tmpUri)
      .then((tx: any) => {
        tx.wait(1)
          .then(() => {
            addNotify({
              message: "Successfully created auction."
            });
          })
          .catch((err: any) => {
            addNotify({
              message: err?.message ? err.message : err,
              type: "error"
            });
          });
      })
      .catch((err: any) => {
        addNotify({
          message: err?.message ? err.message : err,
          type: "error"
        });
      });

    handleProcessingTx(false);
  };

  return (
    <>
      <div className="flex-1 w-full md:w-144 flex flex-col rounded-lg overflow-hidden border border-gray-500 mx-auto text-gray-100">
        <div className="w-full h-[58px] px-4 bg-gray-500 border-b border-gray-600 flex items-center flex-none">
          <div className="flex text-white text-base font-bold items-center">
            <DescriptionIcon className="h-6 mr-2" />
            Create Auction
          </div>
        </div>
        <div className="flex-1 bg-gray-600 p-4 font-nexa text-white w-full flex flex-col styled-overflow overflow-auto">
          <div className="flex flex-col md:flex-row">
            <div className="my-auto mr-auto">Metaverse:</div>
            <div className="flex flex-col">
              <Dropdown
                dropdownItems={dropdownItems}
                onSelectionChanged={selectMetaverse}
                buttonClassName={`rounded-lg w-[200px] h-[54px] bg-gray-500 px-4 flex justify-between items-center select-none z-30 cursor-pointer`}
                dropdownClassName={`select-none overflow-hidden bg-gray-500 rounded-lg text-white mt-2`}
                dropdownButtonClassName={`p-4 hover:bg-gray-300 cursor-pointer`}
                dropdownZIndexClassName="z-20"
                zeroHeightDropdown={true}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row mt-4">
            <div className="my-auto mr-auto">Base Price (ETH):</div>
            <div className="bg-gray-600 text-gray-200 border rounded-md border-gray-300 h-10 flex items-center pl-2 md:w-56 mt-2 md:mt-0">
              <input
                className="bg-transparent h-full rounded-md text-white placeholder-gray-200 placeholder-opacity-100 bg-gray-600 w-full pl-2 focus:outline-none"
                type="text"
                value={basePrice}
                onChange={(e: any) => {
                  handleBasePrice(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row mt-4">
            <div className="my-auto mr-auto">Price Increment (ETH):</div>
            <div className="bg-gray-600 text-gray-200 border rounded-md border-gray-300 h-10 flex items-center pl-2 md:w-56 mt-2 md:mt-0">
              <input
                className="bg-transparent h-full rounded-md text-white placeholder-gray-200 placeholder-opacity-100 bg-gray-600 w-full pl-2 focus:outline-none"
                type="text"
                value={priceIncrement}
                onChange={(e: any) => {
                  handlePriceIncrement(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row mt-4">
            <div className="my-auto mr-auto">Deadline (Unix Timestamp):</div>
            <div className="bg-gray-600 text-gray-200 border rounded-md border-gray-300 h-10 flex items-center px-2 mt-2 md:mt-0">
              <DateTimePicker
                onChange={handleDeadlineDate}
                value={deadlineDate}
                className="text-white outline-none"
                calendarClassName="text-gray-100"
                clearIcon={<ClearIcon />}
                calendarIcon={<CalendarIcon />}
              />
            </div>
          </div>
          <div
            className={`mt-4 py-2 rounded ${
              processingTx ? "bg-yellow-200" : "bg-yellow-300"
            } hover:bg-yellow-200 cursor-pointer flex justify-center items-center font-bold text-gray-300 select-none`}
            onClick={validateAndCreateAuction}
          >
            {processingTx ? "Creating Auction..." : "Create Auction"}
          </div>
          <Fade top collapse when={error}>
            <div className="mt-4 text-red-200 flex" style={{ display: "block", minHeight: 24 }}>
              {errorMsg}
            </div>
          </Fade>

          <div className="mt-4">
            Decentraland: {dclParcels ? dclParcels?.length : 0}{" "}
            {dclParcels ? (dclParcels?.length !== 1 ? "Lands" : "Land") : "Lands"}
          </div>
          <div className="mt-2">
            CryptoVoxels: {cvParcels ? cvParcels?.length : 0}{" "}
            {cvParcels ? (cvParcels?.length !== 1 ? "Lands" : "Land") : "Lands"}
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(AdminCreateAuction);
