import React, { useCallback, useContext, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { setBidAndFundsClaimed, setPreviousAuctionData } from "store/auctionSlice";
import { getBidAndFundsClaimedEvents } from "helpers/graphql";
import { RootState } from "store";
import { stringEqualsIgnoreCase } from "helpers/misc";
import { useSpring, config, animated } from "react-spring";
import { Contract } from "ethers";
import { useElementSize } from "usehooks-ts";
import { MODAL_ANIMATIONS, MODAL_TYPES } from "helpers/constants";
import { ModalContext } from "context/ModalContext";
import _ from "lodash";

const WonBidBanner = (props: any) => {
  return (
    <div className="flex flex-col">
      <div className="text-2xl font-bold text-yellow-300">Congratulations! You won the bid.</div>
      <div className="mt-2 text-md font-light text-gray-100">
        Congratulations! You won the ads auction in {props.metaverse ? "CryptoVoxels" : "Decentraland"}. Please send
        your poster files to
        <span className="text-purple-300"> mad email </span> or dm <span className="text-purple-300"> @pluto </span>
        on MAD discord.{" "}
        <span className="text-purple-300">(requirement: 1000px * 800px, up to 3 images or 1 video file)</span>
      </div>
    </div>
  );
};

const NotWinBidBanner = (props: any) => {
  return (
    <div className="flex flex-col">
      <div className="text-2xl font-bold text-yellow-300">Oh no you didn&apos;t win the bid. But...</div>
      <div className="mt-2 text-md font-light text-gray-100">
        You didn&apos;t win the last ads auction in {props.metaverse ? "CryptoVoxels" : "Decentraland"}. BUT.
        Here&apos;s your money back. You are welcome to join our next round.
      </div>
    </div>
  );
};

interface AuctionBannerProps {
  madAuctionInstance: Contract | null;
}

const AuctionBanner = (props: AuctionBannerProps) => {
  const [data, handleData] = useState<
    { timestamp: Date; amount: string; id?: string; metaverse?: number }[] | undefined
  >();
  const [eqState, handleEqState] = useState(false);
  const [loading, handleLoading] = useState(false);
  const [accountLoaded, handleAccountLoaded] = useState<string | null | undefined>("");
  const [lastAccount, handleLastAccount] = useState<string | null | undefined>("");
  const [transitioned, handleTransitioned] = useState(true);
  const [animDone, handleAnimDone] = useState(true);
  const [bannerShowing, handleBannerShowing] = useState(false);

  const { showModal } = useContext(ModalContext);
  const dispatch = useDispatch();
  const { previousAuctionData, bidAndFundsClaimed, metaverse, auctionData } = useSelector(
    (state: RootState) => state.auction
  );
  const { account, active } = useWeb3React();
  const { madAuctionInstance } = props;

  const [heightRef, { height }] = useElementSize();
  const slideInStyles = useSpring({
    config: { ...config.default },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: bannerShowing ? 1 : 0,
      height: bannerShowing ? height : 0
    },
    onStart: () => {
      handleAnimDone(false);
    },
    onRest: () => {
      handleAnimDone(true);
      handleTransitioned(true);
    }
  });

  useEffect(() => {
    handleBannerShowing(
      !!(
        !loading &&
        transitioned &&
        previousAuctionData?.has(metaverse) &&
        stringEqualsIgnoreCase(accountLoaded, account) &&
        active &&
        // @ts-ignore
        ((stringEqualsIgnoreCase(previousAuctionData.get(metaverse).highestBidder, account) &&
          data &&
          !previousAuctionData.get(metaverse)?.uploaded) ||
          (data &&
            data.length !== 0 &&
            // @ts-ignore
            !stringEqualsIgnoreCase(previousAuctionData.get(metaverse).highestBidder, account)))
      )
    );
  }, [loading, transitioned, previousAuctionData, metaverse, accountLoaded, active, data, account]);

  useEffect(() => {
    if (previousAuctionData && previousAuctionData.has(metaverse) && !loading && animDone) {
      // @ts-ignore
      handleEqState(stringEqualsIgnoreCase(previousAuctionData.get(metaverse).highestBidder, account));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousAuctionData, loading, animDone, metaverse]);

  useEffect(() => {
    handleLoading(true);
    if (
      previousAuctionData &&
      previousAuctionData.has(metaverse) &&
      active &&
      // @ts-ignore
      ((stringEqualsIgnoreCase(previousAuctionData.get(metaverse).highestBidder, lastAccount) && data) ||
        // @ts-ignore
        (data && data.length !== 0 && !stringEqualsIgnoreCase(previousAuctionData.highestBidder, lastAccount)))
    ) {
      handleTransitioned(false);
    }
    handleAccountLoaded(undefined);
    handleLastAccount(account);
    dispatch(setBidAndFundsClaimed({ bidEvents: [], fundsClaimedEvents: [] }));
    handleData(undefined);
    if (account && active) {
      if (account) {
        (async () => {
          dispatch(setBidAndFundsClaimed(await getBidAndFundsClaimedEvents(account)));
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, account]);

  useEffect(() => {
    if (!bidAndFundsClaimed.bidEvents || bidAndFundsClaimed.bidEvents.length === 0) {
      handleLoading(false);
      handleAccountLoaded(account);
      handleData(bidAndFundsClaimed.bidEvents ? [] : undefined);
      return;
    }

    (async () => {
      const auctionIdSet = new Set<string>();
      const auctionMap = new Map<string, BidEvent[]>();
      // Filter bid events by their auction id
      if (!bidAndFundsClaimed.bidEvents) {
        return;
      }
      for (const be of bidAndFundsClaimed.bidEvents) {
        // Add the event if they are not the auction winner or the auction was cancelled (funds claimable)
        if (be.auction && (!stringEqualsIgnoreCase(be.auction.highestBidder, account) || be.auction.cancelled)) {
          auctionIdSet.add(be.auction.id);
          // Even if the user bids over themself, this will display all bids (including the old ones)
          // if (auctionMap.has(be.auction.id)) {
          //   auctionMap.get(be.auction.id)?.push(be);
          // } else
          if (!auctionMap.has(be.auction.id)) {
            auctionMap.set(be.auction.id, [be]);
          }
        }
      }

      // Remove all bid events from an auction if there is a FundClaimedEvent
      // for the given auction id
      for (const fce of bidAndFundsClaimed.fundsClaimedEvents) {
        if (fce.auction.id) {
          if (auctionIdSet.has(fce.auction.id)) {
            auctionIdSet.delete(fce.auction.id);
          }
        }
      }

      // Remove bid events from the active auctions
      if (auctionData) {
        for (const auc of auctionData.values()) {
          if (auctionIdSet.has(auc.id)) {
            auctionIdSet.delete(auc.id);
          }
        }
      }

      // Create data array in correct format for ClaimRefundModal
      const dataArr: { timestamp: Date; amount: string; id?: string; metaverse?: number }[] = [];
      for (const aucId of auctionIdSet) {
        if (auctionMap.get(aucId)) {
          // @ts-ignore
          for (const aucBid of auctionMap.get(aucId)) {
            dataArr.push({
              timestamp: new Date(aucBid.timestamp * 1000),
              amount: aucBid.amount,
              id: aucBid.auction?.id,
              metaverse: aucBid.auction?.metaverse
            });
          }
        }
      }

      // Set loading to false and set the data array for ClaimRefundModal
      handleLoading(false);
      handleAccountLoaded(account);
      handleData(dataArr);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidAndFundsClaimed, auctionData]);

  const claimRefund = async () => {
    let ret = { success: false, message: "" };

    if (!data) {
      ret.message = "Unexpected error while claiming refund. Please refresh your page and try again.";
      return ret;
    }

    if (!madAuctionInstance) {
      ret.message = "There was an issue connecting to your wallet. Please refresh your page and try again.";
      return ret;
    }

    const aucIdArray: string[] = [];
    for (const auc of data) {
      if (auc.id && !_.includes(aucIdArray, auc.id)) {
        aucIdArray.push(auc.id);
      }
    }

    if (aucIdArray.length > 0) {
      await madAuctionInstance
        .batchClaim(aucIdArray)
        .then(async (tx: any) => {
          await tx.wait(1).then(() => {
            ret = { success: true, message: "Funds claimed successfully." };
            handleData([]);
          });
        })
        .catch((e: any) => {
          if (e.message) {
            ret = { success: false, message: e.message };
          } else if (e) {
            const idx = e.toString().indexOf('"message":');
            if (idx < 0) {
              ret = { success: false, message: e };
            } else {
              ret = { success: false, message: e.toString().substring(idx + 11, e.toString().indexOf('"', idx + 11)) };
            }
          } else {
            ret = { success: false, message: "Unexpected error occurred. Please refresh your page and try again." };
          }
        });
    } else {
      ret.message = "No outstanding funds to claim.";
    }

    return ret;
  };

  const showClaimModal = () => {
    showModal(MODAL_TYPES.claim_refund, { claimRefund, data });
  };

  const handleSendFile = useCallback(() => {
    showModal(MODAL_TYPES.upload_zip, {
      auction: previousAuctionData?.get(metaverse),
      animation: MODAL_ANIMATIONS.unfold,
      internallyManagedProps: {
        selectedFile: undefined,
        notes: "",
        uploading: false,
        isLoading: false,
        errMsg: ""
      },
      updateWonBids: (idx: number, data: { success: boolean; link?: string; error?: any }) => {
        if (data.success && previousAuctionData) {
          const pad: Map<number, Auction> = new Map(JSON.parse(JSON.stringify(Array.from(previousAuctionData))));
          if (pad?.get(metaverse)) {
            // @ts-ignore
            pad.get(metaverse).uploaded = true;
          }
          dispatch(setPreviousAuctionData(pad));
        }
      }
    });
  }, [account]);

  if (!previousAuctionData) {
    return null;
  }

  return (
    <>
      <animated.div style={{ ...slideInStyles, overflow: "hidden" }}>
        <div
          ref={heightRef}
          className="w-full px-10 bg-gray-500 py-6 border-b-[2.5px] border-yellow-100 flex flex-col md:flex-row md:px-4"
        >
          <div className="w-full mx-auto max-w-7xl flex flex-col md:flex-row justify-between items-center gap-4">
            {eqState ? <WonBidBanner metaverse={metaverse} /> : <NotWinBidBanner metaverse={metaverse} />}
            <div
              className="ml-auto md:ml-0 px-8 py-4 rounded bg-yellow-400 hover:bg-yellow-200 cursor-pointer flex justify-center items-center font-bold text-gray-300 select-none flex-shrink-0"
              onClick={eqState ? handleSendFile : showClaimModal}
            >
              {eqState ? "Send Files" : "Claim Refund"}
            </div>
          </div>
        </div>
      </animated.div>
    </>
  );
};

export default React.memo(AuctionBanner);
