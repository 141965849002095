import { useWeb3React } from "@web3-react/core";
import { IntlProvider } from "react-intl";
import { useState, useEffect, useLayoutEffect, useCallback, useMemo } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import "react-notifications-component/dist/theme.css";
import "swiper/swiper-bundle.css";
import "./App.css";
import ComingSoon from "./components/ui/ComingSoon";
import Home from "./components/home/Home";
import LeaseEstates from "./components/leaseEstate/LeaseEstates";
import WalletSelect from "./components/WalletConnect";
import { useEagerConnect, useInactiveListener } from "./helpers/hooks";
import { Contract, ethers } from "ethers";
import parcels_abi from "./artifacts/ParcelABI.json";
import estate_abi from "./artifacts/EstateABI.json";
// import radicalmarket_abi from "./artifacts/RadicalMarketABI.json";
import cv_abi from "./artifacts/CryptoVoxelsABI.json";
import auction_abi from "./artifacts/MADAuction.json";
// import RadicalMarketPage from "./components/radicalMarket/RadicalMarketPage";
// import RadicalMarketDetails from "./components/radicalMarket/RadicalMarketDetails";
import AuctionPage from "components/auction/AuctionPage";
// import Profile from "components/Profile";
import PageNotFound from "components/PageNotFound";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setEthAlias, setEthAvatar, setLoginType, setNonceObj, setOpenLoginModal } from "store/userSlice";
import {
  CV_CONTRACT_ADDRESS,
  DCL_ESTATE_CONTRACT_ADDRESS,
  DCL_PARCEL_CONTRACT_ADDRESS,
  MAD_AUCTION_CONTRACT_ADDRESS,
  MAINNET_PROVIDER_URL,
  PROVIDER_URL
  // RADICAL_MARKET_CONTRACT_ADDRESS
} from "components/constants/constants";
import {
  getIfUserEmailExists,
  getUserEmail,
  // setUserEmail,
  signInWithEthereum
} from "helpers/siwe";
import logo from "./assets/logo.svg";
import ModalProvider from "context/ModalContext";
import RootModal from "components/modals/RootModal";
import NotificationModal from "components/notifications/Notification";
import Navbar from "components/navbar/Navbar";
import { getNavbarLinks, getPageTitleMap } from "components/navbar/navbarLinks";
import { selectLanguage } from "store/LangSlice";
import ProfilePage from "components/profile/ProfilePage";
import AdminPage from "components/admin/AdminPage";

function App() {
  const [estateContract, handleEstateContract] = useState<Contract | null>(null);
  const [estateInstance, handleEstateInstance] = useState<Contract | null>(null);
  const [parcelContract, handleParcelContract] = useState<Contract | null>(null);
  const [parcelInstance, handleParcelInstance] = useState<Contract | null>(null);
  // const [radicalMarketContract, handleRadicalMarketContract] =
  //   useState<Contract | null>(null);
  // const [radicalMarketInstance, handleRadicalMarketInstance] =
  //   useState<Contract | null>(null);
  const [madAuctionContract, handleMadAuctionContract] = useState<Contract | null>(null);
  const [madAuctionInstance, handleMadAuctionInstance] = useState<Contract | null>(null);
  const [cvContract, handleCvContract] = useState<Contract | null>(null);
  const { openLoginModal, nonceObj } = useSelector((state: RootState) => state.user);
  const { locale, messages } = useSelector((state: RootState) => state.intl);
  const [activePage, handleActivePage] = useState<string>("/home");
  const [activatingConnector, setActivatingConnector] = useState();
  const [scrollToSection, handleScrollToSection] = useState("");
  const [navbarLinks, handleNavbarLinks] = useState<NavbarItem[]>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useWeb3React();
  const {
    connector,
    library,
    // chainId,
    account,
    // activate,
    // deactivate,
    active
    // error,
  } = context;

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  useEffect(() => {
    (async () => {
      const provider = new ethers.providers.JsonRpcProvider(PROVIDER_URL);

      const aucContract = new ethers.Contract(MAD_AUCTION_CONTRACT_ADDRESS, auction_abi.abi, provider);
      handleMadAuctionContract(aucContract);
    })();
  }, []);

  useEffect(() => {
    if (active && madAuctionContract) {
      const mai = madAuctionContract.connect(library.getSigner());
      handleMadAuctionInstance(mai);
    }
  }, [active, library, madAuctionContract]);

  useEffect(() => {
    (async () => {
      const provider = new ethers.providers.JsonRpcProvider(MAINNET_PROVIDER_URL);

      // Decentraland contracts
      const ec = new ethers.Contract(DCL_ESTATE_CONTRACT_ADDRESS, estate_abi, provider);
      const pc = new ethers.Contract(DCL_PARCEL_CONTRACT_ADDRESS, parcels_abi.abi, provider);
      handleEstateContract(ec);
      handleParcelContract(pc);

      // CryptoVoxels contract
      const cvc = new ethers.Contract(CV_CONTRACT_ADDRESS, cv_abi, provider);
      handleCvContract(cvc);

      // RadicalMarket contract
      // const rmc = new ethers.Contract(
      //   RADICAL_MARKET_CONTRACT_ADDRESS,
      //   radicalmarket_abi.abi,
      //   library
      // );
      // handleRadicalMarketContract(rmc);

      // Add instance state variables to support write calls
      if (active) {
        const ei = ec.connect(library.getSigner());
        const pi = pc.connect(library.getSigner());
        handleEstateInstance(ei);
        handleParcelInstance(pi);

        // const rmi = rmc.connect(library.getSigner());
        // handleRadicalMarketInstance(rmi);
      }
    })();
  }, [active, library]);

  // handle logic to recognize the connector currently being activated
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    (async () => {
      if (!!account && !!library) {
        try {
          const alias = await library.lookupAddress(account);
          if (alias) {
            setEthAlias(alias);

            const resolver = await library.getResolver(alias);
            const avatar = await resolver.getText("avatar");
            setEthAvatar(avatar);
          } else {
            setEthAvatar(null);
            setEthAlias(null);
          }
        } catch (err) {
          setEthAvatar(null);
          setEthAlias(null);
        }
      }
    })();
  }, [account, library]);

  useEffect(() => {
    let tmpObj = nonceObj;
    if (account) {
      (async () => {
        // @ts-ignore
        if (!tmpObj || !tmpObj[account] || tmpObj[account].expiry * 1000 < Date.now() || !tmpObj[account]?.signature) {
          tmpObj = await signInWithEthereum(dispatch, tmpObj, library.getSigner());
        }
        tmpObj = await getIfUserEmailExists(dispatch, tmpObj, account);
        tmpObj = await getUserEmail(dispatch, tmpObj, account);
      })();
    }

    // Remove expired nonce object entries
    if (tmpObj) {
      for (const address of Object.keys(tmpObj)) {
        // Remove entries that are a week old
        // @ts-ignore
        if (tmpObj[address] && tmpObj[address].expiry < Date.now() / 1000 - 604800) {
          tmpObj = _.omit(tmpObj, address);
        }
      }
    }

    dispatch(setNonceObj(tmpObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const handleLocale = useCallback(
    (lang) => {
      if (lang) {
        dispatch(selectLanguage(lang));
      } else {
        if (locale.includes("zh")) {
          dispatch(selectLanguage("en"));
        } else {
          dispatch(selectLanguage("zh"));
        }
      }
    },
    [locale, dispatch]
  );

  const isZH = useMemo(() => locale.includes("zh"), [locale]);

  useEffect(() => {
    handleNavbarLinks(getNavbarLinks(navigate, isZH, handleLocale));
  }, [isZH]);

  const ScrollToTopWrapper = () => {
    const location = useLocation();
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
  };

  document.body.classList.add("bg-gray-bg-dark");
  document.body.classList.add("body-overflow");

  return (
    <IntlProvider messages={messages} locale={locale}>
      <ModalProvider>
        <ScrollToTopWrapper />
        <div
          id="parent"
          className={`flex flex-col relative min-h-screen max-h-screen bg-gray-bg-dark font-nexa overflow-x-hidden body-overflow`}
        >
          <Navbar
            activePage={activePage}
            websiteLogo={logo}
            navbarLinks={navbarLinks}
            pageTitleMap={getPageTitleMap()}
          />
          <Routes>
            <>
              <Route path="*" element={<PageNotFound />} />
              <Route
                path="/"
                element={
                  <Home
                    handleActivePage={handleActivePage}
                    scrollToSection={scrollToSection}
                    handleScrollToSection={handleScrollToSection}
                  />
                }
              />
              <Route path="/admin" element={<AdminPage madAuctionInstance={madAuctionInstance} />} />
              <Route
                path="lease"
                element={
                  <LeaseEstates
                    handleActivePage={handleActivePage}
                    estateContract={estateContract}
                    parcelContract={parcelContract}
                    estateInstance={estateInstance}
                    parcelInstance={parcelInstance}
                    cvContract={cvContract}
                  />
                }
              />
              <Route path="stake" element={<ComingSoon />} /> {/* <LiquidityMining /> */}
              <Route path="docs" element={<ComingSoon />} />
              <Route
                path="profile"
                element={
                  <ProfilePage madAuctionInstance={madAuctionInstance} madAuctionContract={madAuctionContract} />
                }
              />
              <Route
                path="auction"
                element={
                  <AuctionPage
                    handleActivePage={handleActivePage}
                    madAuctionContract={madAuctionContract}
                    madAuctionInstance={madAuctionInstance}
                  />
                }
              />
              <Route
                path="/radicalmarket"
                element={
                  <Navigate replace to="/" />
                  // <RadicalMarketPage
                  //   title="RADICAL MARKET"
                  //   handleActivePage={handleActivePage}
                  //   estateContract={estateContract}
                  //   parcelContract={parcelContract}
                  //   estateInstance={estateInstance}
                  //   parcelInstance={parcelInstance}
                  //   cvContract={cvContract}
                  //   radicalMarketContract={radicalMarketContract}
                  //   radicalMarketInstance={radicalMarketInstance}
                  // />
                }
              />
              <Route
                path="/radicalmarket/:metaverseid/:assetid"
                element={
                  <Navigate replace to="/" />
                  // <RadicalMarketDetails
                  //   radicalMarketContract={radicalMarketContract}
                  //   radicalMarketInstance={radicalMarketInstance}
                  // />
                }
              />
            </>
          </Routes>
          {/* <Footer activePage={activePage} /> */}
        </div>
        {/* </ScrollToTopWrapper> */}
        <WalletSelect
          open={openLoginModal}
          handleLoginType={(providerType: string) => {
            dispatch(setLoginType(providerType));
          }}
          onClose={() => {
            if (!account) {
              dispatch(setLoginType(""));
            }
            dispatch(setOpenLoginModal(false));
          }}
        />
        <RootModal />
        <NotificationModal />
      </ModalProvider>
    </IntlProvider>
  );
}

export default App;
