import { FormattedMessage } from "react-intl";

import { ReactComponent as CheckIcon } from "../../../assets/check.svg";

const BidSuccessContent = (props: any) => {
  const handleClick = () => {
    if (props?.onClickAction) {
      props?.onClickAction();
    }
    props?.onClose();
  };
  return (
    <div className="w-full flex flex-col bg-gray-600 border border-gray-500 rounded-lg overflow-hidden">
      <div className="w-full bg-gray-500 border-b border-gray-600 flex items-center h-[66px] px-4 flex-none">
        <div className="font-nexa h-full flex w-full justify-between items-center text-base font-bold text-white">
          <FormattedMessage id="Submitting your bid" />
          <div className="font-nexa ml-2 text-green-200 cursor-pointer" onClick={handleClick}>
            <FormattedMessage id="Details" />
          </div>
        </div>
      </div>
      <div className="w-full h-[66px] items-center px-4 flex-none">
        <div className="font-nexa h-full flex w-full justify-between items-center text-base font-normal text-white">
          <FormattedMessage id="Block confirmations completed" />
          <div className="ml-2 text-green-200">
            <CheckIcon className="w-[20px] fill-green-200" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidSuccessContent;
