import React, { useCallback } from "react";
import { ReactComponent as SearchIcon } from "../assets/search.svg";

interface SearchInputProps {
  value: string;
  onSearch: (e: string) => void;
  isZH?: boolean;
}

const Search = ({ value, isZH, onSearch }: SearchInputProps) => {
  const handleChange = useCallback(
    (e: any) => {
      onSearch(e.target.value);
    },
    [onSearch]
  );

  return (
    <div className="relative h-11 pr-5">
      <SearchIcon className="absolute left-0 top-0  mt-3" />
      <input
        className="bg-transparent h-full font-nexa text-xl pl-8 focus:outline-none text-gray-200 border-0 border-b w-full"
        type="search"
        name="search"
        value={value}
        placeholder={isZH ? "搜索地块或庄园" : "Search parcel or estate"}
        onChange={handleChange}
      />
    </div>
  );
};

export default React.memo(Search);
