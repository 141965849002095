import { useEffect, useState } from "react";
import { setMetaverse } from "store/auctionSlice";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "components/ui/Dropdown";
import { FormattedMessage } from "react-intl";
import { RootState } from "store";

const AuctionHeader = () => {
  const [showMetaverseSelect, handleShowMetaverseSelect] = useState(false);

  const { metaverse } = useSelector((state: RootState) => state.auction);

  const dispatch = useDispatch();

  useEffect(() => {
    let eventListener: any = null;
    if (showMetaverseSelect) {
      // @ts-ignore
      eventListener = hideMenu.bind(this);
      document.addEventListener("click", eventListener);
    }

    return () => {
      if (eventListener) {
        document.removeEventListener("click", eventListener);
      }
    };
  }, [showMetaverseSelect]);

  const hideMenu = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const dropdownComponents = document.getElementsByClassName("dropdown-component");
      for (let i = 0; i < dropdownComponents.length; i++) {
        if (dropdownComponents[i].contains(event.target)) {
          return;
        }
      }
    }
    handleShowMetaverseSelect(false);
  };

  const selectMetaverse = (metaverseId: number) => {
    dispatch(setMetaverse(metaverseId));
    handleShowMetaverseSelect(false);
  };

  const dropdownItems = ["Decentraland", "CryptoVoxels"];

  return (
    <>
      <div className="w-full flex flex-col gap-4 justify-center h-[200px] px-10 bg-gray-600 min-h-[200px] hidden md:flex">
        <div className="w-full mx-auto max-w-7xl flex items-center gap-4">
          <div className="text-2xl font-bold text-white">
            <FormattedMessage id="Advertisement" />{" "}
            <span className="text-2xl font-bold text-yellow-300">
              <FormattedMessage id="Bundling Auction" />{" "}
            </span>{" "}
            in
          </div>
          <div className="flex flex-col">
            <Dropdown
              dropdownItems={dropdownItems}
              onSelectionChanged={selectMetaverse}
              buttonClassName={`rounded-lg w-[200px] h-[54px] px-4 bg-gray-500 flex justify-between items-center select-none z-20 cursor-pointer`}
              dropdownClassName={`select-none overflow-hidden bg-gray-500 rounded-lg text-white mt-2`}
              dropdownButtonClassName={`p-4 hover:bg-gray-300 cursor-pointer`}
              initialSelectedItemIdx={metaverse}
              zeroHeightDropdown={true}
            />
          </div>
        </div>
        <div className="w-full mx-auto max-w-7xl flex items-center">
          <div className="text-xs text-mad-white font-thin">
            <FormattedMessage id="highestBidDescription" />
          </div>
        </div>
      </div>
      <div className="w-full p-4 flex flex-col md:hidden">
        <div className="text-lg font-bold text-white">
          Advertisement <span className="text-lg font-bold text-yellow-300">Bundling Auction </span> in
        </div>
        <Dropdown
          dropdownItems={dropdownItems}
          onSelectionChanged={selectMetaverse}
          buttonClassName={`rounded-lg w-[200px] h-[54px] mt-3 px-4 bg-gray-500 flex justify-between items-center select-none z-30 cursor-pointer`}
          dropdownClassName={`select-none overflow-hidden bg-gray-500 rounded-lg text-white mt-2`}
          dropdownButtonClassName={`p-4 hover:bg-gray-300 cursor-pointer`}
          dropdownZIndexClassName="z-20"
          zeroHeightDropdown={true}
        />
      </div>
    </>
  );
};

export default AuctionHeader;
