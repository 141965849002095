import { Contract } from "ethers";
import { useState, memo } from "react";
import { addNotify } from "store/notificationMiddleware";
import { ReactComponent as DescriptionIcon } from "../../assets/description.svg";

interface AdminClaimProps {
  madAuctionInstance: Contract | null;
}

function AdminClaim(props: AdminClaimProps) {
  const { madAuctionInstance } = props;

  const [claimingTx, handleClaimingTx] = useState(false);
  const [aucId, handleAucId] = useState("");

  const claimFunds = async () => {
    if (claimingTx || aucId === "") {
      return;
    }

    handleClaimingTx(true);

    if (!madAuctionInstance) {
      addNotify({
        title: "Claim Failed",
        message: "Error instantiating MAD auction instance. Please refresh your page.",
        type: "error"
      });
      handleClaimingTx(false);
      return;
    }

    // Claim funds
    await madAuctionInstance
      .claim(aucId)
      .then(async (tx: any) => {
        await tx.wait(1).then(() => {
          handleAucId("");
          addNotify({
            title: "Claim Successful",
            message: "The auction funds have been successfully claimed."
          });
          handleClaimingTx(false);
        });
      })
      .catch((err: any) => {
        addNotify({
          title: "Claim Failed",
          message: err.message ? err.message : err,
          type: "error"
        });
        handleClaimingTx(false);
      });
  };

  return (
    <div className="flex-1 max-h-[300px] md:max-h-[420px] md:min-w-[calc(50%-.75rem)] flex flex-col rounded-lg overflow-hidden border border-gray-500 md:max-w-[calc(50%-.75rem)]">
      <div className="w-full h-[58px] bg-gray-500 border-b border-gray-600 px-4 flex items-center flex-none">
        <div className="flex text-white text-base font-bold items-center">
          <DescriptionIcon className="h-6 mr-2" />
          Claim
        </div>
      </div>
      <div className="flex-1 bg-gray-600 p-4 font-nexa text-white w-full flex flex-col styled-overflow overflow-auto">
        <div className="flex flex-col md:flex-row">
          <div className="my-auto mr-auto">Auction ID:</div>
          <div className="bg-gray-600 text-gray-200 border rounded-md border-gray-300 h-10 flex items-center pl-2 md:w-56 mt-2 md:mt-0">
            <input
              className="bg-transparent h-full rounded-md text-white placeholder-gray-200 placeholder-opacity-100 bg-gray-600 w-full pl-2 focus:outline-none"
              type="text"
              value={aucId}
              onChange={(e: any) => {
                handleAucId(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          className={`mt-4 py-2 rounded ${
            claimingTx ? "bg-yellow-200" : "bg-yellow-300"
          } hover:bg-yellow-200 cursor-pointer flex justify-center items-center font-bold text-gray-300 select-none`}
          onClick={claimFunds}
        >
          {claimingTx ? "Claiming Funds..." : "Claim Funds"}
        </div>
      </div>
    </div>
  );
}

export default memo(AdminClaim);
