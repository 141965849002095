export const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const MODAL_TYPES = {
  auction_bid: "AUCTION_BID",
  radical_bid: "RADICAL_BID",
  claim_refund: "CLAIM_REFUND",
  extend_lease: "EXTEND_LEASE",
  set_builder: "SET_BUILDER",
  ad_details: "AD_DETAILS",
  upload_zip: "UPLOAD_ZIP",
  bid_success: "BID_SUCCESS"
};

export const MODAL_ANIMATIONS = {
  fade: "fade",
  unfold: "unfold",
  pulse: "pulse",
  spin: "spin",
  runner: "runner"
};

export const NOTIFICATION_TYPES = {
  bidding: "BIDDING",
  bid_success: "BID_SUCCESS"
};
