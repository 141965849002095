import { createSlice } from "@reduxjs/toolkit";

export interface AuctionState {
  metaverse: number;
  auctionData: Map<number, Auction> | null | undefined;
  previousAuctionData: Map<number, Auction> | undefined;
  auctionBids: Map<number, BidEvent[]> | null | undefined;
  auctionPriceHistory: Map<number, AuctionHistoryBid[] | undefined> | undefined;
  activeMetaverseParcels: DCLLand[] | CVParcel[] | null | undefined;
  dclParcels: DCLLand[] | null | undefined;
  cvParcels: CVParcel[] | null | undefined;
  selectedCoord: number[];
  selectedLatLng: number[];
  currentBidEth: string;
  bidAndFundsClaimed: {
    bidEvents: BidEvent[] | undefined;
    fundsClaimedEvents: FundsClaimedEvent[];
  };
}

const auctionSlice = createSlice({
  name: "auction",
  initialState: {
    metaverse: 1,
    auctionData: undefined,
    previousAuctionData: undefined,
    auctionBids: undefined,
    auctionPriceHistory: undefined,
    activeMetaverseParcels: undefined,
    dclParcels: undefined,
    cvParcels: undefined,
    selectedCoord: [0, 0],
    selectedLatLng: [0, 0],
    currentBidEth: "",
    bidAndFundsClaimed: {
      bidEvents: undefined,
      fundsClaimedEvents: []
    }
  } as AuctionState,
  reducers: {
    setMetaverse(state, action) {
      state.metaverse = action.payload;
      state.activeMetaverseParcels = state.metaverse ? state.cvParcels : state.dclParcels;
    },
    setAuctionData(state, action) {
      state.auctionData = action.payload;
    },
    setPreviousAuctionData(state, action) {
      state.previousAuctionData = action.payload;
    },
    setAuctionBids(state, action) {
      state.auctionBids = action.payload;
    },
    setAuctionPriceHistory(state, action) {
      state.auctionPriceHistory = action.payload;
    },
    setDclParcels(state, action) {
      state.dclParcels = action.payload;
      state.activeMetaverseParcels = state.metaverse ? state.cvParcels : state.dclParcels;
    },
    setCvParcels(state, action) {
      state.cvParcels = action.payload;
      state.activeMetaverseParcels = state.metaverse ? state.cvParcels : state.dclParcels;
    },
    setSelectedCoord(state, action) {
      state.selectedCoord = action.payload;
    },
    setSelectedLatLng(state, action) {
      state.selectedLatLng = action.payload;
    },
    setCurrentBidEth(state, action) {
      state.currentBidEth = action.payload;
    },
    setBidAndFundsClaimed(state, action) {
      state.bidAndFundsClaimed = action.payload;
    }
  }
});

export const {
  setMetaverse,
  setAuctionData,
  setPreviousAuctionData,
  setAuctionBids,
  setAuctionPriceHistory,
  setDclParcels,
  setCvParcels,
  setSelectedCoord,
  setSelectedLatLng,
  setCurrentBidEth,
  setBidAndFundsClaimed
} = auctionSlice.actions;

export const auctionReducer = auctionSlice.reducer;
