import { memo, useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeNotification, setInitialize } from "store/alertSlice";
import { RootState } from "store";
import { NOTIFICATION_TYPES } from "helpers/constants";
import SubmittingContent from "./contents/SubmittingContent";
import BidSuccessContent from "./contents/BidSuccessContent";
import CommonToastContent from "./contents/CommonToastContent";

const NotificationContents = {
  [NOTIFICATION_TYPES.bidding]: SubmittingContent,
  [NOTIFICATION_TYPES.bid_success]: BidSuccessContent
};

function NotificationItem(props: any) {
  const { data, onClose } = props;
  const [isRemoving, setIsRemoving] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const handleClose = () => {
    onClose(data?.id);
  };

  const handleRemove = () => {
    setIsRemoving(true);
  };

  useEffect(() => {
    setIsNew(true);

    setTimeout(() => {
      setIsNew(false);
    }, 800);
  }, []);

  useEffect(() => {
    if (data?.duration) {
      setTimeout(() => {
        setIsRemoving(true);
      }, data.duration);
    }
  }, [data?.duration]);

  useEffect(() => {
    if (isRemoving) {
      setTimeout(() => {
        setIsRemoving(false);
        handleClose();
      }, 700);
    }
  }, [isRemoving]);

  const customStyle = useMemo(() => {
    const styles = "custom-notification active";

    return `${styles} ${isNew ? "show" : ""} ${isRemoving ? "hide" : ""}`;
  }, [isNew, isRemoving]);

  if (data?.content) {
    const Content = NotificationContents[data?.content] ? NotificationContents[data?.content] : null;

    return (
      <div className={customStyle}>{Content ? <Content {...data?.contentProps} onClose={handleRemove} /> : null}</div>
    );
  }

  return (
    <div className={customStyle}>
      <CommonToastContent {...data?.contentProps} onClose={handleRemove} />
    </div>
  );
}

const Notification = memo(NotificationItem);

const NotificationModal = () => {
  const dispatch = useDispatch();
  const { contentNotifications, commonNotifications, horizontal, vertical } = useSelector(
    (state: RootState) => state.toast
  );

  useEffect(() => {
    dispatch(setInitialize());
  }, []);

  const topPosition = useMemo(() => {
    if (vertical === "top") {
      return "top-[76px]";
    }
    return "bottom-[20px]";
  }, [vertical]);

  const rightPosition = useMemo(() => {
    if (horizontal === "left") {
      return "left-[12px]";
    }
    return "right-[12px]";
  }, [horizontal]);

  const handleRemove = useCallback((id: string) => {
    dispatch(removeNotification({ id }));
  }, []);

  const renderNotification = useCallback((item: any) => {
    return <Notification key={`notification_${item?.id}`} data={item} onClose={handleRemove} />;
  }, []);

  return (
    <div className={`w-full max-w-[412px] h-auto fixed z-[999] ${topPosition} ${rightPosition}`}>
      {commonNotifications ? commonNotifications.map(renderNotification) : null}
      {contentNotifications ? contentNotifications.map(renderNotification) : null}
    </div>
  );
};

export default NotificationModal;
