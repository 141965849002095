import DarkForestImage from "assets/darkforest.jpeg";
import JayChouImage from "assets/jaychou.jpeg";
import ApeNFTImage from "assets/apenft.jpeg";
import moleDAOImage from "assets/moleDAO.jpeg";
import Placeholder from "assets/placeholder.jpg";
import EthIcon from "assets/eth_logo.svg";
import AutoCarIcon from "assets/auto_car.svg";
import OrangeDaoIcon from "assets/orange_dao.svg";
import PhantaBearIcon from "assets/phanta_bear.svg";
import FutureCraftIcon from "assets/future_craft.svg";
import BitskiIcon from "assets/Bitski-icon.svg";
import Gavel from "assets/GAVEL_1.gif";
import Skyline from "assets/Skyline.gif";
import LandingDaoLogo from "assets/landing_dao_testitem.svg";
import BuilderDaoLogo from "assets/builder_dao.svg";
import AnalysisDaoLogo from "assets/analysis_dao.svg";
import GRIDS1 from "assets/GRIDS1.svg";
import MADLOGO from "assets/land_background.svg";
import ParcelIcon from "assets/parcel.svg";
import FilterIcon from "assets/bi_filter.svg";
import EstateWhiteIcon from "assets/estate_white.svg";
import Billboard from "assets/Billboard.svg";
import LoginGif from "assets/login.gif";
import EstateIcon from "assets/estate.svg";
import ArrowRightIcon from "assets/arrow_right.svg";
import EthBlackIcon from "assets/eth_black.svg";
import LocationIcon from "assets/location.svg";
import Card from "assets/card.png";
import Property from "assets/property.svg";
import Road from "assets/road.svg";
import District from "assets/district.svg";
import Plaza from "assets/plaza.svg";
import Location from "assets/rmlocation.svg";
import ArrowLeft from "assets/arrow_left.svg";
import GavelExpand from "assets/gavel_expand.svg";
import PriceTag from "assets/price_tag.svg";
import ArrowDownCircle from "assets/arrow_down_circle.svg";

const ex = {
  DarkForestImage,
  JayChouImage,
  ApeNFTImage,
  moleDAOImage,
  Placeholder,
  EthIcon,
  AutoCarIcon,
  OrangeDaoIcon,
  PhantaBearIcon,
  FutureCraftIcon,
  BitskiIcon,
  Gavel,
  Skyline,
  LandingDaoLogo,
  BuilderDaoLogo,
  AnalysisDaoLogo,
  GRIDS1,
  MADLOGO,
  ParcelIcon,
  FilterIcon,
  EstateWhiteIcon,
  Billboard,
  LoginGif,
  EstateIcon,
  ArrowRightIcon,
  EthBlackIcon,
  LocationIcon,
  Card,
  Property,
  Road,
  District,
  Plaza,
  Location,
  ArrowLeft,
  GavelExpand,
  PriceTag,
  ArrowDownCircle
};

export default ex;
