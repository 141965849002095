import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as ArrowDownIcon } from "../../../assets/arrow_down.svg";

const SubmittingContent = () => {
  const [expanded, setExpanded] = useState(true);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={`w-full flex flex-col bg-gray-600 border-gray-500 rounded-lg overflow-hidden ${
        expanded ? "border" : "border-0"
      }`}
    >
      <div className="w-full bg-gray-500 border-b border-gray-600 flex items-center h-[66px] px-4 flex-none">
        <div className="font-nexa h-full flex w-full justify-between items-center text-base font-bold text-white">
          <FormattedMessage id="Submitting your bid" />
          <div className="ml-2 cursor-pointer" onClick={handleToggleExpand}>
            <ArrowDownIcon
              className={`w-[16px] fill-white transition-transform ${expanded ? "rotate-0" : "rotate-180"}`}
            />
          </div>
        </div>
      </div>
      {expanded && (
        <div className="w-full h-[66px] items-center px-4 flex-none">
          <div className="font-nexa h-full flex w-full justify-between items-center text-base font-normal text-white">
            <FormattedMessage id="Waiting for block confirmations..." />
            <div className="animate-spin ml-2 w-[18px] h-[18px] min-w-[18px] rounded-full border-4 border-x-gray-200 border-b-gray-200 border-t-yellow-400" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SubmittingContent;
