import { useEffect, useRef, useState, useContext } from "react";
import Countdown from "react-countdown";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { Contract, ethers } from "ethers";

import { getSignerActiveAction } from "helpers/auth";
import { getCurrentEthPriceInUsd } from "helpers/chainlink";
import { MONTH_NAMES, MODAL_TYPES, NOTIFICATION_TYPES, MODAL_ANIMATIONS } from "helpers/constants";
import { ModalContext } from "context/ModalContext";
import AuctionLocations from "./AuctionLocations";
import { AtlasTile } from "../common/Atlas";
import { AtlasWrapper } from "./AtlasWrapper";
import { ReactComponent as EthLogo } from "../../assets/eth_logo.svg";
import MADColorLogo from "../../assets/logo_sketched.gif";
import { setUserEmail } from "helpers/siwe";
import { setAuctionBids, setAuctionData } from "store/auctionSlice";
import { FormattedMessage } from "react-intl";
import { addNotify } from "store/notificationMiddleware";

interface AuctionHeroSectionProps {
  madAuctionContract: Contract | null;
  madAuctionInstance: Contract | null;
}

const AuctionHeroSection = (props: AuctionHeroSectionProps) => {
  const { showModal, hideModal } = useContext(ModalContext);

  const [tiles, handleTiles] = useState<Record<string, AtlasTile>>();
  const [highestBidUsd, handleHighestBidUsd] = useState(0);
  const [shouldUpdateCoords, handleShouldUpdateCoords] = useState(false);
  const [selectedX, handleSelectedX] = useState(0);
  const [selectedY, handleSelectedY] = useState(0);
  const [zoom, handleZoom] = useState(0);
  const [ethToUsd, handleEthToUsd] = useState(0);

  const { active, account } = useWeb3React();
  const { nonceObj } = useSelector((state: RootState) => state.user);
  const { auctionData, metaverse, selectedCoord, selectedLatLng, activeMetaverseParcels, auctionBids } = useSelector(
    (state: RootState) => state.auction
  );
  const { madAuctionInstance } = props;

  const dispatch = useDispatch();

  const mapRef = useRef(null);

  useEffect(() => {
    (async () => {
      handleEthToUsd(await getCurrentEthPriceInUsd());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (auctionData?.get(metaverse)) {
        handleHighestBidUsd(
          // @ts-ignore
          (await getCurrentEthPriceInUsd()) * (auctionData.get(metaverse).highestBid / 1e18)
        );
      }
    })();
  }, [auctionData, metaverse]);

  useEffect(() => {
    if (mapRef.current) {
      // @ts-ignore
      mapRef.current.invalidateSize();
    }
  }, [metaverse]);

  useEffect(() => {
    // Clear out our current coords (so that the atlas will update)
    handleSelectedX(0);
    handleSelectedY(0);
    handleShouldUpdateCoords(!shouldUpdateCoords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoord]);

  useEffect(() => {
    // Actually update the coords (now that Atlas has been cleared)
    if (selectedX !== selectedCoord[0] || selectedY !== selectedCoord[1]) {
      handleZoom(75);
      handleSelectedX(selectedCoord[0]);
      handleSelectedY(selectedCoord[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateCoords, selectedX, selectedY]);

  useEffect(() => {
    if (metaverse && mapRef.current && (selectedLatLng[0] !== 0 || selectedLatLng[1] !== 0)) {
      // @ts-ignore
      mapRef.current.flyTo(selectedLatLng, 9);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaverse, mapRef.current, selectedLatLng]);

  useEffect(() => {
    (async () => {
      if (auctionData) {
        const component = document.getElementById("cv-map");
        if (component && !mapRef.current) {
          // @ts-ignore
          mapRef.current = L.map("cv-map").setView([0, 0], 4);
          if (mapRef.current) {
            // @ts-ignore
            L.tileLayer(`https://map.cryptovoxels.com/tile?z={z}&x={x}&y={y}`, {
              minZoom: 3,
              maxZoom: 20,
              attribution: "Map data &copy; Cryptovoxels",
              id: "cryptovoxels"
            }).addTo(mapRef.current);
          }
        }
      }

      if (activeMetaverseParcels && mapRef.current) {
        for (const p of activeMetaverseParcels) {
          // @ts-ignore
          if (p.z !== undefined) {
            // Determine if this is a CVParcel
            // @ts-ignore
            L.marker([p.z / 100, p.x / 100]).addTo(mapRef.current);
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionData, activeMetaverseParcels]);

  const submitBid = async (bid: number, email: string) => {
    let ret = { success: false, message: "" };
    if (!auctionData || !auctionData.get(metaverse)) {
      return ret;
    }

    try {
      if (madAuctionInstance) {
        const currBid = await madAuctionInstance.getWithdrawAmount(
          // @ts-ignore
          ethers.BigNumber.from(auctionData.get(metaverse).id),
          account
        );

        await madAuctionInstance
          // @ts-ignore
          .bid(ethers.BigNumber.from(auctionData.get(metaverse).id), ethers.utils.parseUnits(`${0}`, 18), {
            value: ethers.utils.parseUnits(`${bid}`, 18).sub(currBid)
          })
          .then(async (tx: any) => {
            hideModal();
            addNotify({ id: "waiting-bid", content: NOTIFICATION_TYPES.bidding });
            await tx.wait(1).then(async () => {
              ret = { success: true, message: "" };
              try {
                let tmpBids: Map<number, BidEvent[]> | undefined;
                if (auctionBids) {
                  tmpBids = new Map(JSON.parse(JSON.stringify(Array.from(auctionBids))));
                }
                if (!tmpBids) {
                  tmpBids = new Map<number, BidEvent[]>();
                }
                if (!tmpBids.has(metaverse)) {
                  tmpBids.set(metaverse, []);
                }
                // @ts-ignore
                let tmpBidsArr = tmpBids.get(metaverse);
                if (!tmpBidsArr) {
                  tmpBidsArr = [];
                }
                tmpBidsArr.unshift({
                  // @ts-ignore
                  amount: ethers.utils.parseUnits(`${bid}`, 18).toString(),
                  from: account ? account : "",
                  timestamp: Date.now() / 1000
                });
                tmpBids.set(metaverse, tmpBidsArr);
                dispatch(setAuctionBids(tmpBids));

                // Set the current highest bid for the AuctionHeader
                const aucData: Map<number, Auction> = new Map();
                for (const mv of auctionData.keys()) {
                  // @ts-ignore
                  aucData.set(mv, { ...auctionData.get(mv) });
                }
                try {
                  // @ts-ignore
                  aucData.get(metaverse).highestBid = ethers.utils.parseUnits(`${bid}`, 18).toString();
                } catch (err) {
                  console.log(err);
                  // @ts-ignore
                  aucData.get(metaverse).highestBid = ethers.utils.parseUnits(`${bid}`, 18).toString();
                }
                dispatch(setAuctionData(aucData));
              } catch (err: any) {
                console.log(err);
                console.log("Failed to update bid list: " + err.message);
              }
              if (account && email.length > 0) {
                await setUserEmail(dispatch, nonceObj, account, email);
              }
            });
          })
          .catch((e: any) => {
            if (e) {
              const idx = e.toString().indexOf('"message":');
              if (idx < 0) {
                ret = { success: false, message: e.message };
              } else {
                ret = {
                  success: false,
                  message: e.toString().substring(idx + 11, e.toString().indexOf('"', idx + 11))
                };
              }
            } else {
              ret = { success: false, message: "Unexpected error occurred. Please refresh your page and try again." };
            }
          });
      }
    } catch (e: any) {
      let err = e;
      if (err.message) {
        err = err.message;
      }
      err = err.toString().split(" (")[0];
      ret = { success: false, message: err };
    }

    return ret;
  };

  const handleBidClick = () => {
    if (getSignerActiveAction(active, dispatch)) {
      showModal(MODAL_TYPES.auction_bid, { submitBid, animation: MODAL_ANIMATIONS.unfold });
    }
  };

  const renderError = () => {
    return (
      <>
        <div className="flex flex-col gap-4 md:gap-6 items-center md:flex-row md:items-stretch min-h-[320px]">
          <div
            id="map-container"
            className="flex md:flex-1 w-full rounded-lg overflow-hidden h-72 md:h-auto min-h-72 md:min-h-0 bg-gray-600"
          >
            <div className={`w-full ${metaverse ? "h-full opacity-100" : "absolute opacity-0 h-0"}`}>
              <div id="cv-map" className={`relative h-full w-full z-10`} />
            </div>
            {metaverse ? null : (
              <AtlasWrapper
                tiles={tiles}
                selectedX={selectedX}
                selectedY={selectedY}
                zoom={zoom}
                handleTiles={handleTiles}
                // @ts-ignore
                activeMetaverseParcels={activeMetaverseParcels}
              />
            )}
          </div>
          <div className="w-full md:hidden">
            <AuctionLocations />
          </div>
          <div className="rounded-lg w-full md:w-[480px] bg-gray-600 overflow-hidden">
            <div className="rounded-lg flex flex-wrap gap-4 md:gap-0 w-full p-4 bg-gray-500">
              <div className="w-auto mr-auto md:mr-0 md:w-7/12 flex flex-col flex-shrink-0">
                <div className="w-full h-4 text-purple-200 text-base text-left font-bold flex-shrink-0">
                  <FormattedMessage id="Ad Timeframe" />
                </div>
                <div className="w-full h-4 text-left text-base text-white mt-3 flex-shrink-0">--</div>
              </div>
              <div className="w-auto md:w-5/12 flex flex-col flex-shrink-0">
                <div className="w-full h-4 text-purple-200 text-base text-left font-bold flex-shrink-0">
                  <FormattedMessage id="Billboard Coverage" />
                </div>
                <div className="w-full h-4 text-left text-base text-white mt-3 flex-shrink-0">
                  {`${activeMetaverseParcels ? activeMetaverseParcels.length : "--"} ${
                    activeMetaverseParcels ? (activeMetaverseParcels.length !== 1 ? "Lands" : "Land") : "Lands"
                  }`}
                </div>
              </div>
            </div>

            <div className="w-full p-4">
              <div className="flex flex-col m-auto">
                <img src={MADColorLogo} className="h-20 w-20 mx-auto" />
                <div className="text-white font-nexa text-sm text-center mt-2 px-10">
                  <FormattedMessage id="AuctionLoadingError" /> <FormattedMessage id="CheckInternetConnection" />
                </div>
              </div>
              <div
                className="w-full h-[56px] rounded bg-yellow-300 hover:bg-yellow-200 cursor-pointer mt-6 flex justify-center items-center font-bold text-gray-300 select-none"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <FormattedMessage id="Refresh Page" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderSkeleton = () => {
    return (
      <div className="flex flex-col gap-4 md:gap-6 items-center md:flex-row md:items-stretch">
        <div className="animate-pulse flex-1 w-full md:w-auto min-h-[320px] rounded-lg bg-gray-200" />
        <div className="w-full md:hidden">
          <AuctionLocations />
        </div>
        <div className="rounded-lg w-full md:w-[480px] bg-gray-600 overflow-hidden">
          <div className="rounded-lg flex w-full p-4 bg-gray-500">
            <div className="animate-pulse w-7/12 flex flex-col">
              <div className="w-2/3 h-4 bg-white rounded" />
              <div className="w-2/3 h-4 bg-white rounded mt-3" />
            </div>
            <div className="animate-pulse w-5/12 flex flex-col">
              <div className="w-2/3 h-4 bg-white rounded" />
              <div className="w-2/3 h-4 bg-white rounded mt-3" />
            </div>
          </div>

          <div className="w-full animate-pulse p-4">
            <div className="w-1/3 h-4 bg-white rounded" />
            <div className="flex items-center mt-3">
              <div className="w-3 h-6 rounded-full bg-white" />
              <div className="w-[50px] h-3 bg-white rounded ml-3" />
            </div>
            <div className="w-1/3 h-4 bg-white rounded mt-6" />
            <div className="flex items-center mt-3">
              <div className="w-[50px] h-3 bg-white rounded" />
              <div className="w-[50px] h-3 bg-white rounded ml-2" />
            </div>
            <div className="w-full h-[56px] rounded bg-yellow-300 mt-6" />
          </div>
        </div>
      </div>
    );
  };

  let aucData: Auction | null | undefined = null;
  if (auctionData) {
    aucData = auctionData.get(metaverse);
  }
  if (auctionData === null || aucData === undefined) {
    return renderError();
  }
  if (!auctionData || !aucData || activeMetaverseParcels === undefined) {
    return renderSkeleton();
  }

  const countdownRenderer = (input: { days: number; hours: number; minutes: number; seconds: number }) => {
    return (
      <span>
        <>
          {input.days > 0 ? (
            <span>
              <span className="font-bold">{input.days}</span> {input.days > 1 || input.days === 0 ? "days" : "day"}{" "}
            </span>
          ) : null}
          {input.hours > 0 ? (
            <span>
              <span className="font-bold">{input.hours}</span> {input.hours > 1 || input.hours === 0 ? "hours" : "hour"}{" "}
            </span>
          ) : null}
          {input.minutes > 0 ? (
            <span>
              <span className="font-bold">{input.minutes}</span>{" "}
              {input.minutes > 1 || input.minutes === 0 ? "minutes" : "minute"}{" "}
            </span>
          ) : null}
          {input.days === 0 && input.hours === 0 ? (
            <span>
              <span className="font-bold">{input.seconds}</span>{" "}
              {input.seconds > 1 || input.seconds === 0 ? "seconds" : "second"}{" "}
            </span>
          ) : null}
        </>
      </span>
    );
  };

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-6 items-center md:flex-row md:items-stretch">
        <div
          id="map-container"
          className="flex md:flex-1 w-full rounded-lg overflow-hidden h-72 md:h-auto min-h-72 md:min-h-0 bg-gray-600"
        >
          <div className={`w-full ${metaverse ? "h-full opacity-100" : "absolute opacity-0 h-0"}`}>
            <div id="cv-map" className={`relative h-full w-full z-10`} />
          </div>
          {metaverse ? null : (
            <AtlasWrapper
              tiles={tiles}
              selectedX={selectedX}
              selectedY={selectedY}
              zoom={zoom}
              handleTiles={handleTiles}
              // @ts-ignore
              activeMetaverseParcels={activeMetaverseParcels}
            />
          )}
        </div>
        <div className="w-full md:hidden">
          <AuctionLocations />
        </div>
        <div className="rounded-lg w-full md:w-[480px] bg-gray-600 overflow-hidden">
          <div className="rounded-lg flex flex-wrap gap-4 md:gap-0 w-full p-4 bg-gray-500">
            <div className="w-auto mr-auto md:mr-0 md:w-7/12 flex flex-col flex-shrink-0">
              <div className="w-full h-4 text-purple-200 text-base text-left font-bold flex-shrink-0">Ad Timeframe</div>
              <div className="w-full h-4 text-left text-base text-white mt-3 flex-shrink-0">
                {MONTH_NAMES[new Date(aucData.deadline * 1000 + 604800000).getMonth()]}{" "}
                {new Date(aucData.deadline * 1000 + 604800000).getDate()} -{" "}
                {MONTH_NAMES[new Date(aucData.deadline * 1000 + 1814400000).getMonth()]}{" "}
                {new Date(aucData.deadline * 1000 + 1814400000).getDate()}
              </div>
            </div>
            <div className="w-auto md:w-5/12 flex flex-col flex-shrink-0">
              <div className="w-full h-4 text-purple-200 text-base text-left font-bold flex-shrink-0">
                <FormattedMessage id="Billboard Coverage" />
              </div>
              <div className="w-full h-4 text-left text-base text-white mt-3 flex-shrink-0">
                {`${activeMetaverseParcels ? activeMetaverseParcels.length : 0} ${
                  activeMetaverseParcels ? (activeMetaverseParcels.length !== 1 ? "Lands" : "Land") : "Lands"
                }`}
              </div>
            </div>
          </div>

          <div className="w-full p-4">
            {aucData.highestBid > 0 ? (
              <>
                <div className="w-full text-left h-4 text-base text-white font-bold">Current Highest Bid:</div>
                <div className="flex items-center mt-3">
                  <EthLogo className="h-6" />
                  <div className="ml-3 text-gray-100 font-bold my-auto">
                    {(aucData.highestBid / 1e18).toFixed(3)}
                    <span className="text-gray-100 text-xs font-nexa font-light ml-1">{`($${highestBidUsd.toFixed(
                      2
                    )})`}</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="w-full text-left h-4 text-base text-white font-bold">Bid Starting At:</div>
                <div className="flex items-center mt-3">
                  <EthLogo className="h-6" />
                  <div className="ml-3 text-gray-100 font-bold my-auto">
                    {(aucData.increment / 1e18).toFixed(3)}
                    <span className="text-gray-100 text-xs font-nexa font-light ml-1">{`($${(
                      (aucData.increment / 1e18) *
                      ethToUsd
                    ).toFixed(2)})`}</span>
                  </div>
                </div>
              </>
            )}
            <div className={`mt-6 w-full text-left h-4 text-base text-white font-bold`}>Auction Ends In:</div>
            <div className="flex items-center mt-3 text-gray-100">
              <Countdown date={aucData.deadline * 1000} renderer={countdownRenderer} />
            </div>
            <div
              className="w-full h-[56px] rounded bg-yellow-300 hover:bg-yellow-200 cursor-pointer mt-6 flex justify-center items-center font-bold text-gray-300 select-none"
              onClick={handleBidClick}
            >
              Bid Now
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuctionHeroSection;
