import {
  MAD_ADDRESS,
  MAD_AUCTION_GRAPHQL_ENDPOINT,
  MAD_DELEGATION_ADDRESS,
  ZERO_ADDRESS
} from "components/constants/constants";
import { Contract, ethers } from "ethers";
import { getBidUploadStatus } from "./files";

const endpoint = "https://api.thegraph.com/subgraphs/name/decentraland/marketplace";
const radicalmarketendpoint = "https://api.thegraph.com/subgraphs/name/metaverse-advertisement-dao/mad-radical-market";
const liteendoint = "https://api.thegraph.com/subgraphs/name/boyuanx/decentraland-lite";

function generateParcelUpdateOperatorIsMADQuery() {
  const parcelQuery = `{
        lands(where: { updateOperator: "${MAD_ADDRESS}" }) {
            id
            x
            y
          }
    }`;
  return parcelQuery;
}

function generateParcelOffersQuery(metaverse: number, assetId: string) {
  const offerQuery = `{
      bidEvents(where: {metaverse: ${metaverse}, assetId: "${assetId}"}) {
        metaverse
        timestamp
        from
        pricePerDay
        total
      }
    }`;
  return offerQuery;
}

function generateMyLandsQuery(owner: string) {
  const landQuery = `{
      landInfos(where: {owner: "${owner}"}) {
        currentLender
        currentPricePerDay
        currentCollateral
        graceUntil
        pendingLender
        pendingPricePerDay
        pendingCollateral
      }
    }`;
  return landQuery;
}

function generateLandInfoQuery(metaverseId: string, landId: string) {
  const landQuery = `{
      landInfo(id: "${metaverseId}-${landId}") {
        currentLender
        currentPricePerDay
        currentCollateral
        graceUntil
        pendingLender
        pendingPricePerDay
        pendingCollateral
        operators
      }
    }`;
  return landQuery;
}

function generateMyBidsQuery(address: string) {
  const bidQuery = `{
      bidEvents(where: {from: "${address}"}) {
        metaverse
        assetId
        timestamp
        pricePerDay
        total
      }
    }`;
  return bidQuery;
}

// Estate is a collection of parcels
// function generateDecentralandEstateIdQuery(owner: string) {
//   const estateIdQuery = `{
//       nfts(where:{ category: estate, owner: "${owner}"  }) {
//         tokenId
//         name
//       }
//     }
//   `;
//   return estateIdQuery;
// }

// // A parcel is a chunk of land
// function generateDecentralandParcelIdQuery(owner: string) {
//   const parcelIdQuery = `{
//       nfts(where:{ category: parcel, owner: "${owner}"  }) {
//         tokenId
//       }
//     }
//   `;
//   return parcelIdQuery;
// }

// // Get detailed info about an estate
// function generateDecentralandEstateInfoQuery(id: string) {

//   const estateInfoQuery = `{
//       nfts(where:{ tokenId: "${id}"  }) {
//           tokenId
//           name
//           image
//       }
//     }`;

//     return estateInfoQuery;
// }

// Get detailed parcel info
function generateDecentralandParcelInfoQuery(id: string) {
  const parcelInfoQuery = `{
      nfts(where:{ tokenId: "${id}"  }) {
          tokenId
          name
          image
          parcel {
              x
              y
          }
      }
    }`;

  return parcelInfoQuery;
}

// Get the updateOperator value of a parcel
// function generateParcelUpdateOperatorQuery(parcelId: string) {
//   const parcelQuery = `{
//     lands(where: {id: "${parcelId}"}) {
//       updateOperator
//     }
//   }`;

//   return parcelQuery;
// }

// Get the updateOperator value of an estate
// function generateEstateUpdateOperatorQuery(estateId: string) {
//   const estateQuery = `{
//     estates(where: {id: "${estateId}"}) {
//       updateOperator
//     }
//   }`;

//   return estateQuery;
// }

function generateParcelOwnerQuery(tokenId: string) {
  const query = `{
        nfts(where: { tokenId: "${tokenId}" }) {
            owner {
                address
            }
        }
    }`;
  return query;
}

export async function getDecentralandParcels(parcelContract: Contract, owner: string): Promise<any> {
  if (!owner || !parcelContract) {
    return [];
  }

  let ret = null;
  await parcelContract.tokensOf(owner).then(async (res: any) => {
    ret = [];
    if (res) {
      for (const nft of res) {
        const tmpDclParcels: any = await getDecentralandParcelData(nft);
        tmpDclParcels.updateOperator = await getDecentralandParcelUpdateOperator(parcelContract, nft); // True means we have delegation (show Cancel, Claim Profit), false means we don't have delegation (show Lease)
        ret.push(tmpDclParcels);
      }
    }
  });

  return ret;
}

export async function getDecentralandEstates(estateContract: Contract, owner: string): Promise<any> {
  if (!owner || !estateContract) {
    return [];
  }

  let ret = null;
  await estateContract
    .balanceOf(owner)
    .then(async (res: any) => {
      if (!res) {
        return [];
      }

      const ret2 = [];

      res = res.toString();
      for (let idx = 0; idx < res; idx++) {
        const tmpDclEstates = await estateContract.tokenOfOwnerByIndex(owner, idx);
        ret2.push(tmpDclEstates.toString());
      }
      return ret2;
    })
    .then(async (result: any) => {
      ret = [];
      if (result) {
        for (const nft of result) {
          const tmpEstateData: any = await getDecentralandEstateData(nft);
          tmpEstateData.name = (await estateContract.getMetadata(nft)).split(",")[1].slice(1, -1);
          tmpEstateData.updateOperator = await getDecentralandEstateUpdateOperator(
            estateContract,
            tmpEstateData.parcels[0].tokenId
          ); // True means we have delegation (show Cancel, Claim Profit), false means we don't have delegation (show Lease)
          ret.push(tmpEstateData);
        }
      }
    });

  return ret;
}

export async function getDecentralandParcelData(parcelId: string): Promise<any> {
  if (!parcelId) {
    return { type: "parcel", image: "", name: null, parcel: { x: null, y: null }, tokenId: parcelId };
  }

  let ret: any = {};
  await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateDecentralandParcelInfoQuery(parcelId)
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result.data.nfts) {
        result.data.nfts[0].type = "parcel";
        ret = result.data.nfts[0];
      } else {
        ret = { type: "parcel", image: "", name: null, parcel: { x: null, y: null }, tokenId: parcelId };
      }
    });

  if (ret) {
    ret.madPrice = 0.005;
  }

  return ret;
}

export async function getDecentralandEstateData(estateId: string): Promise<{
  type: string;
  image: string;
  name: null;
  parcel: null;
  tokenId: string;
} | null> {
  if (!estateId) {
    return { type: "estate", image: "", name: null, parcel: null, tokenId: estateId };
  }

  let ret = null;
  await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateDecentralandParcelInfoQuery(estateId)
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result.data.nfts) {
        ret = {
          type: "estate",
          tokenId: estateId,
          name: null,
          image: result.data.nfts[0].image,
          parcels: result.data.nfts
        };
      } else {
        ret = { type: "estate", image: "", name: null, parcels: null, tokenId: estateId };
      }
    });

  return ret;
}

export async function getDecentralandParcelOwnerFromTokenId(tokenId: string): Promise<string> {
  const raw = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateParcelOwnerQuery(tokenId)
    })
  });
  const json = await raw.json();
  if (json.data.nfts.length === 0) return "";
  return json.data.nfts[0].owner.address;
}

export async function getDecentralandParcelsWithMADAsUpdateOperator(estateContract: Contract): Promise<DCLLand[]> {
  const raw = await fetch(liteendoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateParcelUpdateOperatorIsMADQuery()
    })
  });
  const json = await raw.json();
  const lands = json.data.lands;

  for (const l in lands) {
    lands[l] = await getDecentralandParcelData(lands[l].id);
  }

  const getDecentralandParcelOwnerFromTokenIdPromises = [];
  const dcl_getEstateTokenIdPromises = [];
  for (const land of lands) {
    const tokenId = land.tokenId;
    const getDecentralandParcelOwnerFromTokenIdPromise = getDecentralandParcelOwnerFromTokenId(tokenId);
    getDecentralandParcelOwnerFromTokenIdPromises.push(getDecentralandParcelOwnerFromTokenIdPromise);
    const dcl_getEstateTokenIdPromise = await estateContract.landIdEstate(tokenId);
    dcl_getEstateTokenIdPromises.push(dcl_getEstateTokenIdPromise);
  }
  const ownerAddresses = await Promise.all(getDecentralandParcelOwnerFromTokenIdPromises);
  let estateIds = await Promise.all(dcl_getEstateTokenIdPromises);
  estateIds = estateIds.map((v) => v.toString());

  const dcl_getEstateUpdateOperatorPromises = [];
  for (const estateId of estateIds) {
    const dcl_getEstateUpdateOperatorPromise = await estateContract.updateOperator(estateId);
    dcl_getEstateUpdateOperatorPromises.push(dcl_getEstateUpdateOperatorPromise);
  }
  const estateUpdateOperators = await Promise.all(dcl_getEstateUpdateOperatorPromises);

  const parcel_owner_array = [];
  for (let i = 0; i < ownerAddresses.length; ++i) {
    if (estateUpdateOperators[i] !== ZERO_ADDRESS && estateUpdateOperators[i] !== MAD_ADDRESS) continue; // Filters out lands that belong in an estate with a different estate operator
    const parcel = lands[i];
    parcel.owner = ownerAddresses[i];
    parcel_owner_array.push(parcel);
  }
  return parcel_owner_array;
}

export async function getDecentralandParcelUpdateOperator(
  parcelContract: Contract,
  parcelId: string
): Promise<any | null> {
  if (!parcelId || !parcelContract) {
    return null;
  }

  let ret = null;
  await parcelContract.updateOperator(parcelId).then((res: any) => {
    if (!res) {
      ret = false;
    } else {
      ret = res === MAD_ADDRESS;
    }
  });

  return ret;
}

export async function getDecentralandEstateUpdateOperator(
  estateContract: Contract,
  estateId: string
): Promise<boolean> {
  if (!estateId || !estateContract) {
    return false;
  }

  let ret = false;
  await estateContract.updateOperator(estateId).then((res: any) => {
    if (!res) {
      ret = false;
    } else {
      ret = res === MAD_ADDRESS;
    }
  });

  return ret;
}

export async function getOffers(metaverseId: number, tokenId: string): Promise<any[]> {
  let ret: any[] = [];
  await fetch(radicalmarketendpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateParcelOffersQuery(metaverseId, tokenId) // metaverse id 0 = DCL Land
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result?.data?.bidEvents) {
        ret = result.data.bidEvents;
        for (const obj of ret) {
          obj.expiry = new Date(obj.timestamp * 1000 + (obj.pricePerDay / obj.total) * 86400000); // obj.timestamp was creation timestamp. We add the amount of days (in ms) to get expiration timestamp.
        }
        ret.sort((a, b) => (a.expiry < b.expiry ? 1 : b.expiry < a.expiry ? -1 : 0));
      }
    });

  return ret;
}

export async function getOpenSeaParcelAsset(tokenId: string): Promise<any> {
  const dclParcelContractAddress = "0xF87E31492Faf9A91B02Ee0dEAAd50d51d56D5d4d";
  const openSeaEndpoint = `https://api.opensea.io/api/v1/asset/${dclParcelContractAddress}/${tokenId}/`;

  let ret = {};
  await fetch(openSeaEndpoint, {
    method: "GET",
    headers: {
      "X-API-KEY": "57c786328078401b8ce8c73b15606078",
      "Content-Type": "application/json"
    }
  })
    .then((res) => res.json())
    .then((result) => {
      ret = result;
    });

  return ret;
}

export async function getMyLands(ownerAddress: string): Promise<Land[]> {
  let ret: Land[] = [];
  await fetch(radicalmarketendpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateMyLandsQuery(ownerAddress)
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result?.data?.landInfos) {
        ret = result.data.landInfos;
      }
    });

  return ret;
}

export async function getLandInfo(metaverseId: string, landId: string): Promise<Land | undefined> {
  let ret: Land | undefined = undefined;
  await fetch(radicalmarketendpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateLandInfoQuery(metaverseId, landId)
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result?.data?.landInfo) {
        ret = result.data.landInfo;
      }
    });

  return ret;
}

export async function getMyBids(ownerAddress: string): Promise<Bid[]> {
  let ret: Bid[] = [];
  await fetch(radicalmarketendpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateMyBidsQuery(ownerAddress)
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result?.data?.bidEvents) {
        ret = result.data.bidEvents;
      }
    });

  return ret;
}

/**
 * Auction Queries and Callable Functions
 */

function generateGetActiveAuctionsQuery(timestamp: number) {
  const query = `{
      auctions(where:{deadline_gt: "${timestamp}", cancelled: false}) {
        cancelled
        birth
        deadline
        highestBid
        highestBidder
        increment
        uri
        metaverse
        id
      }
    }`;
  return query;
}

function generateGetPrevious4AuctionsQuery(timestamp: number) {
  const query = `{
      auctions(where:{deadline_lt: ${timestamp}}, orderDirection: desc, orderBy: deadline, first: 4) {
        cancelled
        birth
        deadline
        highestBid
        highestBidder
        increment
        uri
        metaverse
        id
      }
    }`;
  return query;
}

function generateGetAuctionQuery(auctionId: string | number) {
  const auctionQuery = `{
      auction(id: "${auctionId}") {
        cancelled
        birth
        deadline
        highestBid
        highestBidder
        increment
        id
        uri
        metaverse
      }
    }`;
  return auctionQuery;
}

function generateGetCurrentBidsQuery(auctionId: string | number) {
  const bidsQuery = `{
      bidEvents(where: {auction: "${auctionId}"}, orderBy: amount, orderDirection: desc) {
        from
        amount
        timestamp
      }
    }`;
  return bidsQuery;
}

function generateGetUserBidHistoryQuery(userAddress: string) {
  const bidHistory = `{
      bidEvents(where: {from: "${userAddress}"}) {
        amount
        from
        timestamp
      }
    }`;
  return bidHistory;
}

function generateGetUserWithdrawHistoryQuery(userAddress: string) {
  const withdrawHistory = `{
      withdrawEvents(where: {to: "${userAddress}"}) {
        amount
        to
      }
    }`;
  return withdrawHistory;
}

function generateGetAuctionWithdrawEventsQuery(auctionId: string) {
  const withdrawEvents = `{
      withdrawEvents(where: {auction: "${auctionId}"}) {
        to
        amount
      }
    }`;
  return withdrawEvents;
}

function generateGeneralAuctionInfoQuery() {
  const query = `{
      generalInfos(first: 1)
    }`;
  return query;
}

function generateAuctionPriceHistoryQuery(metaverse: number) {
  const query = `{
      auctions(first: 100, skip: 1, orderBy: deadline, orderDirection: desc, where: {metaverse: ${metaverse}, cancelled: false}) {
        id
        deadline
        highestBid
      }
    }`;
  return query;
}

function generateBidAndFundsClaimedEventQuery(address: string) {
  const query = `{
      bidEvents(where: {from: "${address}"}, orderBy: timestamp, orderDirection: desc) {
        id
        amount
        timestamp
        auction {
          id
          cancelled
          metaverse
          highestBidder
          deadline
          uri
        }
        from
      }
      fundsClaimedEvents(where: {user: "${address}"}) {
        amount
        auction {
          id
          highestBidder
        }
      }
    }`;
  return query;
}

export async function getActiveAuctions(timestamp: number): Promise<Map<number, Auction> | undefined> {
  let ret: Map<number, Auction> | undefined;
  try {
    await fetch(MAD_AUCTION_GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: generateGetActiveAuctionsQuery(timestamp)
      })
    })
      .then((res) => res.json())
      .then(async (result) => {
        if (result?.data?.auctions) {
          const tmpActiveAuctions = new Map<number, Auction>();
          for (const auc of result.data.auctions) {
            if (auc.uri) {
              auc.uri = ethers.utils.toUtf8String(auc.uri);
            }
            tmpActiveAuctions.set(auc.metaverse, auc);
          }
          ret = tmpActiveAuctions;
        }
      });
  } catch (err: any) {
    return ret;
  }

  return ret;
}

export async function getPreviousAuctions(timestamp: number): Promise<Map<number, Auction> | undefined> {
  let ret: Map<number, Auction> | undefined;
  await fetch(MAD_AUCTION_GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateGetPrevious4AuctionsQuery(timestamp)
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result?.data?.auctions) {
        const tmpAucMap = new Map<number, Auction>();
        for (const auc of result.data.auctions) {
          if (!auc.cancelled && !tmpAucMap.has(auc.metaverse)) {
            auc.uploaded = await getBidUploadStatus(auc.id);
            if (auc.uri) {
              auc.uri = ethers.utils.toUtf8String(auc.uri);
            }
            tmpAucMap.set(auc.metaverse, auc);
          }
        }
        ret = tmpAucMap;
      }
    });

  return ret;
}

export async function getAuction(auctionId: string | number): Promise<Auction | undefined> {
  let ret: Auction | undefined;
  await fetch(MAD_AUCTION_GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateGetAuctionQuery(auctionId)
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result?.data?.auction) {
        ret = result.data.auction;
        if (ret) {
          ret.uri = ret?.uri ? ethers.utils.toUtf8String(ret.uri) : "";
        }
      }
    });

  return ret;
}

export async function getCurrentBids(
  auctionMap: Map<number, Auction> | undefined
): Promise<Map<number, BidEvent[] | undefined>> {
  const ret: Map<number, BidEvent[] | undefined> = new Map<number, BidEvent[] | undefined>();

  if (!auctionMap) {
    return ret;
  }

  for (const mv of auctionMap.keys()) {
    try {
      await fetch(MAD_AUCTION_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          // @ts-ignore
          query: generateGetCurrentBidsQuery(auctionMap.get(mv).id)
        })
      })
        .then((res) => res.json())
        .then(async (result) => {
          if (result?.data?.bidEvents) {
            ret.set(mv, result.data.bidEvents);
          }
        });
    } catch (err: any) {
      ret.set(mv, undefined);
    }
  }

  return ret;
}

export async function getUserBidHistory(userAddress: string): Promise<BidEvent[]> {
  let ret: BidEvent[] = [];
  await fetch(MAD_AUCTION_GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateGetUserBidHistoryQuery(userAddress)
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result?.data?.bidEvents) {
        ret = result.data.bidEvents;
      }
    });

  return ret;
}

export async function getUserWithdrawHistory(userAddress: string): Promise<WithdrawEvent[]> {
  let ret: WithdrawEvent[] = [];
  await fetch(MAD_AUCTION_GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateGetUserWithdrawHistoryQuery(userAddress)
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result?.data?.withdrawEvents) {
        ret = result.data.withdrawEvents;
      }
    });

  return ret;
}

export async function getAuctionWithdrawEvents(auctionId: string): Promise<WithdrawEvent[]> {
  let ret: WithdrawEvent[] = [];
  await fetch(MAD_AUCTION_GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateGetAuctionWithdrawEventsQuery(auctionId)
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result?.data?.withdrawEvents) {
        ret = result.data.withdrawEvents;
      }
    });

  return ret;
}

export async function getAuctionWinningPriceHistory(): Promise<GeneralInfo | null> {
  let ret: GeneralInfo | null = null;

  await fetch(MAD_AUCTION_GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateGeneralAuctionInfoQuery()
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result?.data?.generalInfos) {
        ret = result.data.generalInfos;
      }
    });

  return ret;
}

export async function getAuctionPriceHistory(): Promise<Map<number, AuctionHistoryBid[] | undefined>> {
  const ret: Map<number, AuctionHistoryBid[] | undefined> = new Map<number, AuctionHistoryBid[] | undefined>();

  // DCL
  try {
    await fetch(MAD_AUCTION_GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: generateAuctionPriceHistoryQuery(0)
      })
    })
      .then((res) => res.json())
      .then(async (result) => {
        if (result?.data?.auctions) {
          ret.set(0, result.data.auctions);
        }
      });
  } catch (err: any) {
    ret.set(0, undefined);
  }

  // CV
  try {
    await fetch(MAD_AUCTION_GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: generateAuctionPriceHistoryQuery(1)
      })
    })
      .then((res) => res.json())
      .then(async (result) => {
        if (result?.data?.auctions) {
          ret.set(1, result.data.auctions);
        }
      });
  } catch (err: any) {
    ret.set(1, undefined);
  }

  return ret;
}

export const getAllCVParcelsWithMADAsContributor = async (): Promise<CVParcel[] | undefined> => {
  try {
    const raw = await fetch("https://www.cryptovoxels.com/api/parcels.json");
    const json = await raw.json();
    const filtered = json.parcels.filter((parcel: CVParcel) => {
      return (
        parcel.parcel_users != null &&
        parcel.parcel_users.map((v: any) => v.wallet.toUpperCase()).includes(MAD_DELEGATION_ADDRESS.toUpperCase())
      );
    });
    for (const p of filtered) {
      p.x = (p.x1 + p.x2) / 2;
      p.z = (p.z1 + p.z2) / 2;
      p.image = `https://map.cryptovoxels.com/tile/parcel?x=${p.x / 100}&y=${p.z / 100}`;
    }
    return filtered || [];
  } catch (err: any) {
    return undefined;
  }
};

export const getBidAndFundsClaimedEvents = async (
  address: string
): Promise<{
  bidEvents: BidEvent[];
  fundsClaimedEvents: FundsClaimedEvent[];
}> => {
  let bidEvents: BidEvent[] = [];
  let fundsClaimedEvents: FundsClaimedEvent[] = [];

  await fetch(MAD_AUCTION_GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query: generateBidAndFundsClaimedEventQuery(address)
    })
  })
    .then((res) => res.json())
    .then(async (result) => {
      if (result?.data?.bidEvents) {
        bidEvents = result.data.bidEvents;
        for (const be of bidEvents) {
          if (!be.auction) {
            continue;
          }
          if (!be.auction.uri?.landCount) {
            be.auction.uri = { landCount: be.auction.metaverse ? 94 : 10 };
          }
        }
      }

      if (result?.data?.fundsClaimedEvents) {
        fundsClaimedEvents = result.data.fundsClaimedEvents;
      }
    });

  return { bidEvents: bidEvents, fundsClaimedEvents: fundsClaimedEvents };
};
