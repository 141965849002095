import React from "react";
import Parcel from "./Parcel";
import RadicalMarketParcel from "./radicalMarket/RadicalMarketParcel";

interface ParcelGroupProps {
  leaseCallback: (e?: any) => void;
  cancelCallback: (e?: any) => void;
  claimProfitCallback: (e?: any) => void;
  metaverseId: number;
  layerName: string;
  radicalMarket?: boolean;
  sortVal?: number;
  searchContent?: string;
  [key: string]: any;
}

const ParcelGroup = (props: ParcelGroupProps) => {
  const {
    parcels,
    layerName,
    leaseCallback,
    cancelCallback,
    claimProfitCallback,
    radicalMarket,
    sortVal,
    searchContent,
    metaverseId
  } = props;

  const order = (a: any, b: any) => {
    if (sortVal === 1) {
      return a.madPrice < b.madPrice ? -1 : a.madPrice > b.madPrice ? 1 : 0;
    } else if (sortVal === 2) {
      return a.madPrice < b.madPrice ? 1 : a.madPrice > b.madPrice ? -1 : 0;
    } else if (sortVal === 3) {
      return 0;
    }
  };

  const itemCards = parcels
    .sort(order)
    .filter((item: any) => {
      if (searchContent) {
        return (
          item.name?.toLowerCase().includes(searchContent.toLowerCase()) ||
          item.parcel?.x === searchContent ||
          item.parcel?.y === searchContent ||
          (!item.name &&
            `parcel (${item?.parcel ? item.parcel.x : "--"}, ${item?.parcel ? item.parcel.y : "--"})`.includes(
              searchContent.toLowerCase()
            )) ||
          (searchContent.includes(item.parcel?.x) && searchContent.includes(item.parcel?.y))
        );
      }
      return true;
    })
    .map((item: any, i: number) =>
      radicalMarket ? (
        <div
          key={`radical-op-${i}`}
          className="font-commuter w-72 bg-gray-bg rounded-md flex flex-col mt-10 shadow-xl md:mr-6 md:justify-start justify-around border border-gray-400"
        >
          <RadicalMarketParcel
            parcel={item}
            i={i}
            layerName={layerName}
            leaseCallback={leaseCallback}
            cancelCallback={cancelCallback}
            claimProfitCallback={claimProfitCallback}
            metaverseId={metaverseId}
          />
        </div>
      ) : (
        <div
          key={`op-${i}`}
          className="h-100 w-64 mx-6 bg-gray-bg rounded-md flex flex-col mt-10 shadow-xl border border-gray-400"
        >
          <Parcel
            parcel={item}
            i={i}
            layerName={layerName}
            leaseCallback={leaseCallback}
            cancelCallback={cancelCallback}
            claimProfitCallback={claimProfitCallback}
            metaverseId={metaverseId}
          />
        </div>
      )
    );
  return (
    <>
      <div className="z-10 h-full flex flex-col text-gray-text-light bg-gray-bg">
        <div className="w-full flex flex-col flex-grow md:justify-start justify-around bg-gray-bg">
          <div className="w-full bg-gray-bg pb-10">
            <div className="flex flex-row flex-grow md:justify-start justify-around flex-wrap max-w-7xl mx-auto">
              {itemCards}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ParcelGroup);
