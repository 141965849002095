import { memo } from "react";

interface DetailInfoProps {
  label: string;
  value: string | undefined;
  margin: string;
  hasBorder?: boolean;
}

function AdminDetailInfo(props: DetailInfoProps) {
  const { label, value, margin, hasBorder } = props;

  return (
    <div
      className={`w-full flex flex-row flex-wrap justify-between ${
        hasBorder ? "border-b border-gray-400 pb-1" : ""
      } ${margin}`}
    >
      <div className="font-semibold">{label}</div>
      <div>{value}</div>
    </div>
  );
}

export default memo(AdminDetailInfo);
