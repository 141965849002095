import React, { useEffect, useCallback, useState } from "react";
import "../../grid.css";
import PastAds from "./PastAds";
import Logo from "./Logo";
import RadicalMarket from "./RadicalMarket";
// import LandStaking from "./LandStaking";
import LandingChat from "./LandingChat";
import { useWindowSize } from "../../helpers/hooks";

interface HomeProps {
  handleActivePage: (e: string) => void;
  scrollToSection: string;
  handleScrollToSection: (s: string) => void;
  [key: string]: any;
}

const Home = (props: HomeProps) => {
  const [scrollPosition, setScrollPostion] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [wrapperScroll, setWrapperScroll] = useState({});
  const [width, height] = useWindowSize();

  const { handleActivePage, scrollToSection, handleScrollToSection } = props;
  // TODO: let navigate = useNavigate(); // to push an endpoint, call `navigate("/path");`

  useEffect(() => {
    handleActivePage("/home");
  }, [handleActivePage]);

  useEffect(() => {
    if (scrollToSection && scrollToSection !== "") {
      try {
        document.getElementById(scrollToSection)?.scrollIntoView({ behavior: "smooth" });
      } catch (e) {
        // Do nothing
      }
      handleScrollToSection("");
    }
  }, [scrollToSection, handleScrollToSection]);

  const handleDragStart = useCallback((e: any) => {
    e.preventDefault();
  }, []);

  const handleScroll = (e: any) => {
    e.preventDefault();
    const position = e.currentTarget.scrollTop;
    setScrollPostion(position ? position : 0);
  };

  useEffect(() => {
    const parent = document.getElementById("parent");
    parent?.addEventListener("scroll", handleScroll);
    return () => parent?.removeEventListener("scroll", handleScroll);
  }, [width, height]);

  return (
    <>
      <div className="flex-grow lg:flex-grow-0 flex flex-col relative text-gray-600 pt-20">
        <div className="w-full flex-grow lg:flex-grow-0 flex relative text-white justify-center h-0">
          <div className="flex flex-col w-full" onDragStart={handleDragStart} style={wrapperScroll}>
            <div id="banner-container" className="flex flex-col w-full pt-6">
              <Logo scrollPosition={scrollPosition} />
              {/* <LandStaking /> */}
              <div
                id="radical-market-container"
                className="flex flex-col w-full px-6 sm:p-8 md:px-20 md:pt-20 md:pb-24 z-1 bg-gray-600"
              >
                <RadicalMarket />
              </div>
              <div id="batch-auction-container" className="z-10 bg-gray-bg-dark">
                <PastAds />
              </div>
            </div>
            <div className="flex flex-col w-full pt-10 md:pt-20 md:pb-20 bg-gray-bg-dark z-10">
              <LandingChat />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Home);
