import "../../styles/progress.css";

interface ProgressBarProps {
  value: number;
}

function ProgressBar({ value }: ProgressBarProps) {
  return (
    <div className="w-full progress">
      <div
        className={`progress-bar progress-bar-striped progress-bar-animated`}
        style={{ width: `${value}%` }}
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
      ></div>
    </div>
  );
}

export default ProgressBar;
