import { useEffect, useState } from "react";
import Button from "components/ui/Button";
import { FormattedMessage } from "react-intl";
import { ethers } from "ethers";
import { countDecimals } from "helpers/misc";
import { getCurrentEthPriceInUsd } from "helpers/chainlink";
import { addNotify } from "store/notificationMiddleware";

type ClaimRefundContentProps = {
  onCancel: () => void;
  claimRefund: () => Promise<{ success: boolean; message: string }>;
  data: { timestamp: Date; amount: string }[] | undefined;
  [key: string]: any;
};

export function RefundItem(props: { Item: { timestamp: Date; amount: string }; ethToUsd: number; index: number }) {
  const { timestamp, amount } = props.Item;
  return (
    <div key={`refund-${props.index}`} className="my-4">
      <div className="bg-gray-500 rounded-md sm:grid sm:grid-cols-2 px-3 py-2">
        <div className="w-56">
          <p className="font-nexa text-base leading-6 font-bold text-pink-30 mb-2">Bid Time</p>
          <p className="font-nexa text-base leading-6 font-light text-white">
            {`${timestamp.toLocaleDateString()} ${timestamp.getHours() % 12 ? timestamp.getHours() % 12 : 12}:${
              timestamp.getMinutes() < 10 ? `0${timestamp.getMinutes()}` : timestamp.getMinutes()
            } ${timestamp.getHours() >= 12 ? "PM" : "AM"}`}
          </p>
        </div>
        <div className="flex sm:justify-end w-56 pr-8 mt-4 sm:mt-0">
          <div className="w-fit">
            <p className="font-nexa text-base leading-6 font-bold text-pink-30 mb-2">Available Refund</p>
            <p className="font-nexa text-base leading-6 text-white font-semibold">
              {countDecimals(parseFloat(ethers.utils.formatEther(amount))) > 3
                ? parseFloat(ethers.utils.formatEther(amount)).toFixed(3)
                : parseFloat(ethers.utils.formatEther(amount))}
              <span className="text-xs font-light">{` ($${
                props.ethToUsd ? (props.ethToUsd * parseFloat(ethers.utils.formatEther(amount))).toFixed(2) : "--"
              })`}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ClaimRefundContent(props: ClaimRefundContentProps) {
  const { onCancel, claimRefund, data } = props;
  const [ethToUsd, handleEthToUsd] = useState(0);
  const [processingTx, handleProcessingTx] = useState(false);
  const [success, handleSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      handleEthToUsd(await getCurrentEthPriceInUsd());
    })();
  }, []);

  if (!data) {
    return null;
  }

  const claim = async () => {
    handleProcessingTx(true);
    const { success, message } = await claimRefund();
    if (!success) {
      addNotify({ message, type: "error" });
    } else {
      handleSuccess(true);
    }
    handleProcessingTx(false);
  };

  const renderSuccess = () => {
    return (
      <div className="px-6 py-6 sm:py-10 sm:px-14 bg-gray-600 rounded-2xl">
        <div>
          <p className="text-gray-100 font-nexa text-xl leading-7 font-light md:text-2xl md:leading-normal md:font-bold md:mb-8 text-center">
            <FormattedMessage id="Claim Successful" />
          </p>
          <p className="text-sm md:text-xl font-light font-nexa leading-4 md:leading-8 text-gray-100 md:w-95/100 md:w-144 text-center mb-8">
            <span>
              <FormattedMessage id="Your outstanding funds" />
            </span>
          </p>
          <div className="flex justify-center">
            <Button
              text={<FormattedMessage id="Finish" />}
              className="text-center text-gray-500 bg-yellow-400 font-nexa font-bold text-lg w-full md:w-auto hover:bg-yellow-200 px-10 py-2 mb-4 mb-0 rounded"
              borderColor="none"
              onClick={onCancel}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderProcessing = () => {
    return (
      <div className="px-8 py-10 bg-gray-600 rounded-2xl">
        <div className="grid gap-y-8">
          <p className="text-white text-center py-16 px-8">
            <FormattedMessage id="Waiting for block confirmations..." />
          </p>
        </div>
      </div>
    );
  };

  const renderMainContent = () => {
    return (
      <div className="px-6 py-6 sm:py-10 sm:px-14 bg-gray-600 rounded-2xl sm:w-[580px]">
        <div className="grid gap-y-8">
          <p className="font-nexa font-bold text-2xl leading-8 text-gray-100 text-center">
            <FormattedMessage id="Claim Refund" />
          </p>
          <div className="max-h-72 overflow-y-auto dark-overflow">
            <div className="-my-4">
              {data.map((bidItem, index) => (
                <RefundItem key={`refund-item-${index}`} index={index} Item={bidItem} ethToUsd={ethToUsd} />
              ))}
            </div>
          </div>

          <div className="flex flex-col-reverse sm:flex-row sm:justify-evenly">
            <Button
              text={<FormattedMessage id="Cancel" />}
              onClick={onCancel}
              className="text-center text-gray-400 bg-gray-100 hover:bg-white font-nexa font-bold text-tiny sm:text-lg lg:text-xl w-auto px-5 py-1 sm:px-10 sm:py-2 rounded"
            />
            <Button
              text={<FormattedMessage id="Claim All" />}
              onClick={claim}
              className="text-center text-gray-600 bg-yellow-300 hover:bg-yellow-200 font-nexa font-bold text-tiny sm:text-lg lg:text-xl w-auto px-5 py-1 sm:px-10 sm:py-2 mb-4 sm:mb-0 rounded"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="transparent">
      <div className="relative border bg-gray-bg-dark border-gray-300 rounded-2xl">
        {processingTx ? renderProcessing() : success ? renderSuccess() : renderMainContent()}
      </div>
    </div>
  );
}
