import { SVGProps } from "react";

export const WalletConnectIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2553_8958)">
        <path
          d="M8.66797 5.36987C15.1895 -1.01522 25.7629 -1.01522 32.2844 5.36987L33.0693 6.13833C33.3954 6.45758 33.3954 6.97519 33.0693 7.29445L30.3844 9.92318C30.2213 10.0828 29.957 10.0828 29.794 9.92318L28.7139 8.8657C24.1643 4.4113 16.788 4.4113 12.2385 8.8657L11.0818 9.99818C10.9188 10.1578 10.6544 10.1578 10.4914 9.99818L7.8065 7.36944C7.48043 7.05019 7.48043 6.53258 7.8065 6.21332L8.66797 5.36987ZM37.837 10.8064L40.2266 13.146C40.5527 13.4652 40.5527 13.9828 40.2266 14.3021L29.4519 24.8516C29.1258 25.1708 28.5971 25.1708 28.2711 24.8516C28.2711 24.8516 28.2711 24.8516 28.2711 24.8516L20.6238 17.3643C20.5423 17.2845 20.4102 17.2845 20.3286 17.3643C20.3286 17.3643 20.3286 17.3643 20.3286 17.3643L12.6816 24.8516C12.3555 25.1708 11.8268 25.1708 11.5008 24.8516C11.5008 24.8516 11.5008 24.8516 11.5007 24.8516L0.725757 14.3019C0.399682 13.9827 0.399682 13.4651 0.725757 13.1458L3.11532 10.8062C3.4414 10.487 3.97007 10.487 4.29614 10.8062L11.9435 18.2936C12.025 18.3734 12.1572 18.3734 12.2387 18.2936C12.2387 18.2936 12.2387 18.2936 12.2387 18.2936L19.8856 10.8062C20.2117 10.487 20.7404 10.487 21.0665 10.8062C21.0665 10.8062 21.0665 10.8062 21.0665 10.8062L28.7138 18.2936C28.7953 18.3734 28.9275 18.3734 29.009 18.2936L36.6562 10.8064C36.9823 10.4871 37.5109 10.4871 37.837 10.8064Z"
          fill="#3B99FC"
        />
      </g>
      <defs>
        <clipPath id="clip0_2553_8958">
          <rect width="40" height="24.6667" fill="white" transform="translate(0.476074 0.536133)" />
        </clipPath>
      </defs>
    </svg>
  );
};
